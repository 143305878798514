import React, { useContext, useState } from "react";
import {
  Container,
  Typography,
  DialogActions,
  Button,
  DialogContent,
} from "@mui/material";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  roleHeading,
} from "./muiRoleComponentStyles";
import { useDispatch } from "../../redux/store";
import { deleteRole } from "../../redux/slices/RoleSlice";
import useLocales from "../../hooks/useLocales";
import toasterContext from "../../utils/context/toasterContext";
import CircularProgressLoader from "../../utils/CircularProgressLoader";

const DeleteRoleDialog = ({ roleDetail, deleteDialog, handleCloseDialog }) => {
  const { translate } = useLocales();
  const fireToasterContext = useContext(toasterContext);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteRole = () => {
    setLoader(true);
    dispatch(deleteRole(roleDetail.id, handleApiRes));
  };

  const handleApiRes = (data) => {
    setLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Delete Role"
      );
      handleCloseDialog();
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
      handleCloseDialog();
    }
  };

  return (
    <Container>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={deleteDialog}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={roleHeading} variant="roleTableHeadingBold">
            {translate("deleteRole")}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography sx={roleHeading} variant="roleTableCellName">
            {translate("areYouSure")} <b>{roleDetail?.name}</b> ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            sx={{
              borderRadius: "10px",
              background: "black",
              "&:hover": { backgroundColor: "black" },
            }}
            autoFocus
            onClick={handleCloseDialog}
          >
            <Typography sx={roleHeading} variant="roleHeadingBoldButton">
              {translate("cancel")}
            </Typography>
          </Button>
          <Button
            onClick={() => handleDeleteRole()}
            disableRipple
            sx={{
              borderRadius: "10px",
              backgroundColor: "background.bgLayoutB",
              "&:hover": { backgroundColor: "background.bgLayoutB" },
            }}
          >
            <Typography sx={roleHeading} variant="roleHeadingBoldButton">
              {translate("deleteRole")}
            </Typography>
            {loader ? <CircularProgressLoader /> : ""}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Container>
  );
};

export default DeleteRoleDialog;
