import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  InputBase,
  Typography,
  Grid,
  TableCell,
  Box,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  Paper,
  TableRow,
  IconButton,
  Avatar,
} from "@mui/material";
import Loader from "../Loader/Loader";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "../../redux/store";
import CustomPagination from "../../hooks/useTablePagination";
import {
  customStyledTableRow,
  categoryHeading,
  StyledTableRow,
  tableAvatar,
  tableAvatarCell,
  tableContainer,
  tableHeadColor,
  tableIconBox,
  tableIconButton,
  tableLoaderBox,
  tableSearch,
  tableSearchButton,
  tableSearchIcon,
} from "./muiCategoryComponentStyles";
import useLocales from "../../hooks/useLocales";
import { getCategoryList } from "../../redux/slices/CategorySlice";
import CreateCategoryDialog from "./CreateCategoryDialog";
import EditCategoryDialog from "./EditCategoryDialog";

const Index = () => {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const inputRef = useRef("");

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredList, setFilteredList] = useState([]);
  const [categoryDetail, setCategoryDetail] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  let { categoryList } = useSelector((state) => state.categoryReducer);

  useEffect(() => {
    dispatch(getCategoryList(handleApiRes));
  }, []);

  useEffect(() => {
    //if search has some value in it
    const searchValue = inputRef?.current?.value;
    if (searchValue?.length >= 1) {
      handleSearch(searchValue);
    } else {
      setFilteredList(categoryList);
    }
  }, [categoryList]);

  const handleApiRes = () => {
    setLoading(false);
  };

  const handleOpenDialog = () => {
    setOpenCreateDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenCreateDialog(false);
    setOpenEditDialog(false);
  };
  const handleSearch = (value) => {
    //setting table page number 1
    setPage(0);
    let filterArray = [];
    const toBeSearched = value.toString().toLowerCase();
    categoryList.map((item) => {
      const name = item?.name.toString().toLowerCase();
      const status = item?.active == true ? "active" : "inactive";
      const priority =
        item?.priority == 1 ? "high" : item?.priority == 2 ? "medium" : "low";
      if (name?.includes(toBeSearched)) {
        filterArray.push(item);
      } else if (status?.includes(toBeSearched)) {
        filterArray.push(item);
      } else if (priority?.includes(toBeSearched)) {
        filterArray.push(item);
      }
    });
    setFilteredList(filterArray);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  return (
    <>
      <Grid spacing={2} container>
        {localStorage?.getItem("permissions")?.includes("create output") ? (
          <Grid item lg={9} md={8} sm={8} xs={12}>
            <InputBase
              type="text"
              inputRef={inputRef}
              onChange={(e) => handleSearch(e.target.value)}
              sx={tableSearch}
              //   placeholder={translate("searchOutput")}
              placeholder={translate("searchCategory")}
              inputProps={{ "aria-label": "search" }}
              startAdornment={<SearchIcon sx={tableSearchIcon} />}
            />
          </Grid>
        ) : (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <InputBase
              type="text"
              inputRef={inputRef}
              onChange={(e) => handleSearch(e.target.value)}
              sx={tableSearch}
              //   placeholder={translate("searchOutput")}
              placeholder={translate("searchCategory")}
              inputProps={{ "aria-label": "search" }}
              startAdornment={<SearchIcon sx={tableSearchIcon} />}
            />
          </Grid>
        )}

        {localStorage?.getItem("permissions")?.includes("create output") ? (
          <Grid item lg={3} md={4} sm={4} xs={12}>
            <Button
              onClick={() => handleOpenDialog()}
              disableRipple
              sx={tableSearchButton}
            >
              <Typography
                sx={categoryHeading}
                variant="categoryHeadingBoldButton"
              >
                {translate("createCategory")}
              </Typography>
            </Button>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
      <TableContainer component={Paper} elevation={0} sx={tableContainer}>
        <Table
          sx={{ overflowX: "auto" }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead sx={tableHeadColor}>
            <TableRow>
              <TableCell width={"30%"} sx={tableHeadColor}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={categoryHeading}
                    variant="categoryTableHeadingBold"
                  >
                    {translate("name")}
                  </Typography>
                </Box>
              </TableCell>
              {loading ? null : categoryList.length < 1 ? (
                ""
              ) : (
                <>
                  <TableCell sx={tableHeadColor} align="left">
                    <Typography
                      sx={categoryHeading}
                      variant="categoryTableHeadingBold"
                    >
                      {/* {translate("device")} */}
                      {translate("priority")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    // width={"50%"}
                    sx={tableHeadColor}
                    align="left"
                  >
                    <Typography
                      sx={categoryHeading}
                      variant="categoryTableHeadingBold"
                    >
                      {/* {translate("trigger")} */}
                      {translate("status")}
                    </Typography>
                  </TableCell>
                  {localStorage
                    ?.getItem("permissions")
                    ?.includes("update output" || "delete output") ? (
                    <TableCell sx={tableHeadColor} align="right">
                      <Typography
                        sx={categoryHeading}
                        variant="categoryTableHeadingBold"
                      ></Typography>
                    </TableCell>
                  ) : (
                    ""
                  )}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <Box style={tableLoaderBox}>
                  <Loader />
                </Box>
              </TableRow>
            ) : categoryList.length < 1 ? (
              <TableRow>
                <Box style={tableLoaderBox}>
                  <Typography
                    sx={categoryHeading}
                    variant="categoryTableCellName"
                  >
                    {translate("noCategory")}
                  </Typography>
                </Box>
              </TableRow>
            ) : (
              filteredList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow hover key={row.id} sx={customStyledTableRow}>
                    <TableCell sx={tableAvatarCell} component="th" scope="row">
                      <Avatar sx={tableAvatar}>
                        {row?.name[0].toUpperCase()}
                      </Avatar>
                      <Typography
                        sx={categoryHeading}
                        variant="categoryTableCellName"
                      >
                        {row?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        sx={categoryHeading}
                        variant="categoryTableCellName"
                      >
                        {row?.priority == 1
                          ? translate("high")
                          : row?.priority == 2
                          ? translate("medium")
                          : translate("low")}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        sx={categoryHeading}
                        variant="categoryTableCellName"
                      >
                        {row?.active
                          ? translate("active")
                          : translate("inActive")}
                      </Typography>
                    </TableCell>
                    {localStorage
                      ?.getItem("permissions")
                      ?.includes("update output" || "delete output") ? (
                      <TableCell align="right">
                        <Box sx={tableIconBox}>
                          {localStorage
                            ?.getItem("permissions")
                            ?.includes("update output") ? (
                            <IconButton
                              sx={tableIconButton}
                              onClick={() => {
                                setOpenEditDialog(true);
                                setCategoryDetail(row);
                              }}
                            >
                              <EditIcon sx={{ color: "white" }} />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </Box>
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        filteredList={filteredList}
        setRowsPerPage={setRowsPerPage}
      />
      {openCreateDialog && (
        <CreateCategoryDialog
          //   deviceList={deviceList}
          openCreateDialog={openCreateDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      {/* {openDeleteDialog && (
        <DeleteOutputDialog
          outputDetail={outputDetail}
          openDeleteDialog={openDeleteDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )} */}
      {openEditDialog && (
        <EditCategoryDialog
          categoryDetail={categoryDetail}
          openEditDialog={openEditDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </>
  );
};

export default Index;
