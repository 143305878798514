const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
  lable: {
    color: "red",
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};
export const logsTypographies = {
  "&.MuiTypography-Heading": {
    color: "black",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    // lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-List": {
    display: "flex",
    flexDirection: "column",
    color: "primary.fontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};
export const formControlWidth = {
  width: "100%",
  ".MuiFormLabel-root": {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "15px",
  },
};
export const textFieldLogsFilter = {
  "&.MuiFormControl-root": {
    width: "100%",
    ".MuiFormLabel-root": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "15px",
    },
  },
};

export const textFieldSearchLogsFilter = {
  "&.MuiInputBase-root": {
    border: "1px solid rgba(169, 169, 169, 0.6)",
    borderRadius: 2,
    backgroundColor: " white",
    width: "100%",
    fontFamily: "Poppins",
  },
};

export const logsSearchFieldIcon = {
  "&.MuiSvgIcon-root": {
    color: "rgba(151, 151, 151, 0.68);",
    paddingLeft: "3px",
  },
};

export const logsSearchFilterButton = {
  "&.MuiButton-root": {
    padding: "0",
    mt: "4px",
    width: "100%",
    color: "white",
    height: "55px",
    fontSize: "15px",
    borderRadius: "10px",
    fontFamily: "Poppins",
    backgroundColor: "background.bgLayoutA",
    textTransform: "capitalize",
  },
};

export const logsSearchFilterButtonWhileLoading = {
  mt: "4px",
  width: "100%",
  height: "55px",
  fontSize: "16px",
  fontFamily: "Poppins",
  background: "#979797",
  borderRadius: "10px",
  textTransform: "capitalize",
  "&:hover": { backgroundColor: "#979797" },
  "&:disabled": {
    backgroundColor: "#979797",
  },
};
export const logsSearchFilterButtonWhileNotLoading = {
  mt: "4px",
  width: "100%",
  height: "55px",
  fontSize: "16px",
  fontFamily: "Poppins",
  backgroundColor: "background.bgLayoutA",
  borderRadius: "10px",
  textTransform: "capitalize",
  "&:hover": { backgroundColor: "background.bgLayoutA" },
  "&:disabled": {
    backgroundColor: "#979797",
  },
};

export const logsTableHeadCell = {
  "&.MuiTableCell-root": {
    fontFamily: "Poppins-semibold",
    fontSize: "16px",
  },
};

export const logsTableHeaderCell = {
  "&.MuiTableCell-root": {
    color: "primary.tableFontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    minWidth: 100,
  },
};
export const logsTableBodyCell = {
  "&.MuiTableCell-root": {
    color: "primary.tableFontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    minWidth: 100,
    "&.MuiTableCell-root:hover": {
      backgroundColor: "#DAEAF9",
    },
  },
};

export const logsTableBodyCellWithColor = {
  color: "#7D8EA3",
  fontFamily: "Poppins",
  fontSize: "15px",
  minWidth: 150,
};

export const logTableContainer = {
  "&.MuiTableContainer-root": {
    borderRadius: "10px",
    fontFamily: "Poppins",
    [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
  },
};
export const tableLoaderBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const logsTableBodyRow = {
  "&.MuiTableRow-root": {
    backgroundColor: "white",
  },
};
export const tableAvatarCell = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 1,
};
export const tableAvatar = {
  backgroundColor: "background.bgLayoutA",
  color: "white",
  marginRight: "10px",
};

export const reportHeading = {
  "&.MuiTypography-reportHeadingBoldButton": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-reportTableHeadingBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "20px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-reportTableCell": {
    color: "primary.tableFontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-reportTableCellName": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};
export const tableContainer = {
  overflow: "auto",
  width: "100%",
  marginTop: "35px",
  borderRadius: "10px",
};
export const textfieldStyle = {
  input: {
    color: "primary.fontColor",
    backgroundColor: "background.bgSearchBar",
    borderRadius: "10px",
    fontFamily: "Poppins",
  },
  label: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green",
  // },
  "& .MuiOutlinedInput-root": {
    // marginBottom: "10px",
    backgroundColor: "background.bgSearchBar",
    borderRadius: "10px",
    "& fieldset": {
      fontFamily: "Poppins",
      borderColor: "#EDEDED",
      borderRadius: 3,
    },
    "& label": {
      // fontFamily: "Poppins",
      // borderColor: "#EDEDED",
    },
    "&:hover fieldset": {
      borderColor: "#7D8EA3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7D8EA3",
    },
  },
};
export const textfieldStyleWithMT = {
  marginTop: "8px",
  input: {
    color: "primary.fontColor",
    backgroundColor: "background.bgSearchBar",
    borderRadius: "10px",
    fontFamily: "Poppins",
  },
  label: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green",
  // },
  "& .MuiOutlinedInput-root": {
    // marginBottom: "10px",
    backgroundColor: "background.bgSearchBar",
    borderRadius: "10px",
    "& fieldset": {
      fontFamily: "Poppins",
      borderColor: "#EDEDED",
      borderRadius: 3,
    },
    "& label": {
      // fontFamily: "Poppins",
      // borderColor: "#EDEDED",
    },
    "&:hover fieldset": {
      borderColor: "#7D8EA3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7D8EA3",
    },
  },
};
export const textfieldStyleTest = {
  "&.MuiInputLabel-outlined": {
    transform: "translate(12px, 14px) scale(1) !important",
  },

  "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
    transform: "translate(12px, -6px) scale(0.75) !important",
  },
};

export const tableHeadColor = {
  background: "#0054A4",
};

export const reportsCalendarHeader = {
  "&.MuiTableCell-root": {
    minWidth: 80,
    height: "20px",
    paddingTop: "5px",
    paddingBottom: "5px",
    color: "#5B5B5B",
    fontSize: "12px",
    fontFamily: "Poppins",
  },
  "&.MuiTypography-root": {
    color: "#6D7B88",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};
export const reportsCalendarBodyCell = {
  "&.MuiTableCell-root": {
    paddingTop: 0,
    paddingBottom: 0,
    height: "120px",
    color: "#383838",
    fontSize: "22px",
    cursor: "pointer",
    position: "relative",
    fontFamily: "Poppins",
    backgroundColor: "background.bgTableEvenRow",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    "&.MuiTableCell-root:hover": {
      transform: "scale3d(1.05, 1.05, 1)",
      backgroundColor: "background.bgTableRowHover",
      transition: "transform 0.5s ease-in-out, background 0.75s ease-in-out",
    },
  },
};
export const reportsCalendarBodyCell_Disabled = {
  "&.MuiTableCell-root": {
    paddingTop: 0,
    paddingBottom: 0,
    height: "120px",
    color: "#6D7B88",
    fontSize: "20px",
    position: "relative",
    fontFamily: "Poppins",
    backgroundColor: "background.bgTableOddRow",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
};

export const sidebarDrawer = {
  "& .MuiDrawer-paper": {
    postion: "fixed",
    display: { xs: "none", sm: "none", md: "block" },
    marginTop: "70px",
    // background: "#0054A4",
    border: "none",
    color: "#FFFFFF",
    width: 300,
    boxSizing: "border-box",
    textTransform: "capitalize",
    textDecoration: "none",
    // overflow: "auto",
    [`::-webkit-scrollbar`]: {
      width: 0 /* Remove scrollbar space */,
      background: "transparent" /* Optional: just make scrollbar invisible */,
    },
  },
  "&.MuiListItemText-primary": {
    fontFamily: "Poppins",
    fontSize: "100px !important",
    fontWeight: 400,
    lineHeight: "50px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  "&.MuiTypography-sidebarTypography": {
    color: "white",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "50px",
  },
  "&.MuiTypography-dateHeading": {
    fontFamily: "Poppins-SemiBold",
    color: "primary.fontColor",
    fontSize: "16px",
    lineHeight: "50px",
  },
  "&.MuiTypography-alarmDetailText": {
    fontFamily: "Poppins",
    color: "primary.tableFontColor",
    fontSize: "12px",
  },
  "&.MuiTypography-alarmDetailTextHeading": {
    fontFamily: "Poppins-SemiBold",
    color: "primary.tableFontColor",
    fontSize: "12px",
  },
  "&.MuiList-root": {
    // mt: "5px",
    mb: "70px",
    p: 2,
  },
  // "&.MuiBox-root": { display: "flex" },
};
