import React, { useContext, useEffect, useRef, useState } from "react";
import {
  cancelModalButtonsRoom,
  deleteIconButtonsRoom,
  // deleteModalButtonRoom,
  editIconButtonsRoom,
  roomCreateButton,
  // roomMainBox,
  roomNameAvatar,
  roomSearchField,
  roomSearchFieldIcon,
  roomTableCell,
  roomTableContainer,
  roomTableHead,
  roomTableHeadCell,
} from "./RoomStyle";
import {
  DeleteOutlineOutlined,
  EditOutlined,
  Search,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Grid,
  Table,
  Button,
  Dialog,
  TableRow,
  InputBase,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  TableContainer,
} from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { useDispatch, useSelector } from "../../redux/store";
import CustomPagination from "../../hooks/useTablePagination";
import { getProfilesList } from "../../redux/slices/profileSlice";
import { getRoomsList, deleteRoom } from "../../redux/slices/RoomSlice";
import { getRoomCreationInputList } from "../../redux/slices/InputSlice";

import Loader from "../Loader/Loader";
import useLocales from "../../hooks/useLocales";
import toasterContext from "../../utils/context/toasterContext";

import CircularProgressLoader from "../../utils/CircularProgressLoader";
import { getRoomCreationOutputList } from "../../redux/slices/OutputSlice";
import { useNavigate } from "react-router-dom";
import MuteRoomDialog from "./MuteRoomDialog";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef("");

  const { translate } = useLocales();
  const [page, setPage] = React.useState(0);
  const [loader, setLoader] = useState(true);
  const [filteredList, setFilteredList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openMuteModal, setOpenMuteModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [roomDetail, setRoomDetail] = useState();
  const fireToasterContext = useContext(toasterContext);

  useEffect(() => {
    dispatch(getRoomsList(handleGetRoomListResponse));
    dispatch(getRoomCreationInputList(null));
    dispatch(getRoomCreationOutputList(null));
    dispatch(getProfilesList());
  }, []);

  const { roomsList } = useSelector((state) => state.roomReducer);

  useEffect(() => {
    //if search has some value in it
    const searchValue = inputRef?.current?.value;
    if (searchValue?.length >= 1) {
      handleSearch(searchValue);
    } else {
      setFilteredList(roomsList);
    }
  }, [roomsList]);

  const handleGetRoomListResponse = () => {
    setLoader(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleSearch = (value) => {
    //setting table page number 1
    setPage(0);
    let filterArray = [];
    const toBeSearched = value.toString().toLowerCase();
    roomsList.map((item) => {
      const name = item.name.toString().toLowerCase();
      if (name?.includes(toBeSearched)) {
        filterArray.push(item);
      }
    });
    setFilteredList(filterArray);
  };

  const handleCreateRoomModal = () => {
    navigate("/rooms/create-room");
  };

  const handleEditModal = (data) => {
    navigate("/rooms/edit-room", { state: { selectedRoom: data } });
  };

  const handleDeleteModal = (room) => {
    setRoomDetail(room);
    setOpenDeleteModal(!openDeleteModal);
  };

  const handleDeleteRoomResponse = (data) => {
    setLoader(false);
    setOpenDeleteModal(false);
    if (data?.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Room Deleted"
      );
    }
    dispatch(getRoomCreationInputList(null));
    dispatch(getRoomCreationOutputList(null));
  };

  const handleDeleteRoom = () => {
    setLoader(true);
    dispatch(deleteRoom(roomDetail.id, handleDeleteRoomResponse));
  };
  alert("Hello");

  return (
    <>
      {/* <Box sx={roomMainBox}> */}
      {/* search field grid */}
      <Grid container spacing={2}>
        {localStorage?.getItem("permissions")?.includes("create room") ? (
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <InputBase
              type="text"
              inputRef={inputRef}
              onChange={(e) => handleSearch(e.target.value)}
              sx={roomSearchField}
              placeholder={translate("searchRoom")}
              inputProps={{ "aria-label": "search" }}
              startAdornment={<Search sx={roomSearchFieldIcon} />}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputBase
              type="text"
              inputRef={inputRef}
              onChange={(e) => handleSearch(e.target.value)}
              sx={roomSearchField}
              placeholder={translate("searchRoom")}
              inputProps={{ "aria-label": "search" }}
              startAdornment={<Search sx={roomSearchFieldIcon} />}
            />
          </Grid>
        )}
        {localStorage?.getItem("permissions")?.includes("create room") ? (
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Button
              type="submit"
              variant="outlined"
              onClick={handleCreateRoomModal}
              sx={roomCreateButton}
            >
              {translate("createRoom")}
            </Button>
          </Grid>
        ) : (
          ""
        )}
      </Grid>

      {/* room listing table */}
      <TableContainer sx={roomTableContainer}>
        <Table>
          <TableHead sx={roomTableHead}>
            <TableRow>
              <TableCell sx={roomTableHeadCell}>{translate("name")}</TableCell>
              {loader ? null : roomsList.length < 1 ? (
                ""
              ) : (
                <>
                  <TableCell sx={roomTableHeadCell}>
                    {translate("inputs")}
                  </TableCell>
                  <TableCell sx={roomTableHeadCell}>
                    {translate("outputs")}
                  </TableCell>
                  {localStorage
                    ?.getItem("permissions")
                    ?.includes("update room" || "delete room") ? (
                    <TableCell sx={roomTableHeadCell} align="right"></TableCell>
                  ) : (
                    ""
                  )}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loader ? (
              <TableRow
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loader />
              </TableRow>
            ) : roomsList.length < 1 ? (
              <TableRow>
                <TableRow
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {translate("noRoom")}
                </TableRow>
              </TableRow>
            ) : (
              filteredList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((room, index) => (
                  <TableRow
                    key={room.id}
                    sx={{
                      backgroundColor:
                        index % 2
                          ? "background.bgTableEvenRow"
                          : "background.bgTableOddRow",
                      "&.MuiTableRow-root:hover": {
                        backgroundColor: "background.bgTableRowHover",
                      },
                    }}
                  >
                    <TableCell component="th" scope="row" sx={roomTableCell}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <span>
                          <Avatar sx={roomNameAvatar}>
                            {room?.name.slice(0, 1)}
                          </Avatar>
                        </span>
                        <span>{room?.name}</span>
                        <IconButton
                          onClick={() => {
                            setOpenMuteModal(true), setRoomDetail(room);
                          }}
                        >
                          {room?.suppressed == true ? (
                            <VolumeOffIcon
                              sx={{ color: "background.bgLayoutB" }}
                            />
                          ) : (
                            <VolumeUpIcon />
                          )}
                        </IconButton>
                      </div>
                    </TableCell>

                    <TableCell sx={roomTableCell}>
                      {room.inputConfigurations.map((device) => (
                        <Box
                          key={device.id}
                          sx={{
                            textAlign: "center",
                            margin: "2px",
                            color: "white",
                            fontSize: "12px",
                            cursor: "default",
                            padding: "4px 12px",
                            borderRadius: "50px",
                            fontFamily: "Poppins",
                            display: "inline-block",
                            textTransform: "capitalize",
                            backgroundColor: "background.bgTableChip",
                          }}
                        >
                          {device.name}
                        </Box>
                      ))}
                    </TableCell>

                    <TableCell sx={roomTableCell}>
                      {room.outputConfigurations.map((device) => (
                        <Box
                          key={device.id}
                          sx={{
                            textAlign: "center",
                            margin: "2px",
                            color: "white",
                            fontSize: "12px",
                            cursor: "default",
                            padding: "4px 12px",
                            borderRadius: "50px",
                            fontFamily: "Poppins",
                            display: "inline-block",

                            backgroundColor: "background.bgTableChip",
                          }}
                        >
                          {device.name}
                        </Box>
                      ))}
                    </TableCell>

                    {localStorage
                      ?.getItem("permissions")
                      ?.includes("update room" || "delete room") ? (
                      <TableCell align="right">
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            justifyContent: "flex-end",
                          }}
                        >
                          {localStorage
                            ?.getItem("permissions")
                            ?.includes("update room") ? (
                            <IconButton
                              sx={editIconButtonsRoom}
                              onClick={() => {
                                handleEditModal(room);
                              }}
                            >
                              <EditOutlined />
                            </IconButton>
                          ) : (
                            ""
                          )}
                          {localStorage
                            ?.getItem("permissions")
                            ?.includes("delete room") ? (
                            <IconButton
                              sx={deleteIconButtonsRoom}
                              onClick={() => handleDeleteModal(room)}
                            >
                              <DeleteOutlineOutlined />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </span>
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomPagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        filteredList={filteredList}
        setRowsPerPage={setRowsPerPage}
      />

      {/* {openEditModal && (
        <EditRoom
          room={roomDetail}
          roomCreationInputList={roomCreationInputList}
          roomCreationOutputList={roomCreationOutputList}
          profilesList={profilesList}
          openEditModal={openEditModal}
          handleEditModal={handleEditModal}
        />
      )} */}

      {openMuteModal && (
        <MuteRoomDialog
          roomDetail={roomDetail}
          openMuteModal={openMuteModal}
          setOpenMuteModal={setOpenMuteModal}
        />
      )}

      {openDeleteModal && (
        <Dialog
          sx={{ borderRadius: "10px" }}
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          fullWidth
        >
          <DialogTitle
            sx={{
              backgroundColor: "#0054A4",
              color: "white",
              fontFamily: "Poppins-semibold",
              fontSize: "20px",
            }}
          >
            {translate("deleteRoom")}
          </DialogTitle>

          <DialogContent dividers>
            <Typography
              variant="caption"
              sx={{ fontFamily: "Poppins", fontSize: "14px" }}
            >
              {translate("areYouSure")}
              <b>&quot;{roomDetail.name}&quot;</b>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              sx={cancelModalButtonsRoom}
              onClick={() => setOpenDeleteModal(false)}
            >
              {translate("cancel")}
            </Button>
            <Button
              type="submit"
              onClick={handleDeleteRoom}
              sx={
                loader
                  ? {
                      color: "white",
                      fontFamily: "Poppins-semibold",
                      fontSize: "15px",
                      background: "#979797",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "#979797" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
                  : {
                      color: "white",
                      fontFamily: "Poppins-semibold",
                      fontSize: "15px",
                      background: "#EC313B",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "#EC313B" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
              }
            >
              {translate("deleteRoom")}
              {loader ? <CircularProgressLoader /> : ""}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Index;
