import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axiosConfig";
import { dispatch } from "../store";

// const headers = {
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
//   timezoneutcoffset: new Date().getTimezoneOffset(),
// };
const initialState = {
  allProfileRoomsList: [],
  roomsList: [],
  roomsListForLocks: [],
  qrCode: {},
  roomDetail: {},
};

const slice = createSlice({
  name: "room",
  initialState,
  reducers: {
    getAllProfileRooms(state, action) {
      state.allProfileRoomsList = action.payload.data;
    },
    getRooms(state, action) {
      state.roomsList = action.payload.data;
    },
    getRoomDetail(state, action) {
      state.roomDetail = action.payload.data;
    },
    getRoomsLockList(state, action) {
      state.roomsListForLocks = action.payload.data;
    },
    getQRCode(state, action) {
      state.qrCode = action.payload.data;
    },
    resetRoomDetail(state) {
      state.roomDetail = null;
    },
  },
});

export default slice.reducer;

export function getRoomsList(handleGetRoomListResponse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/roomConfigurations`
        // { headers }
      );
      dispatch(slice.actions.getRooms(response.data));
      if (handleGetRoomListResponse) {
        handleGetRoomListResponse();
      }
    } catch (error) {
      if (handleGetRoomListResponse) {
        handleGetRoomListResponse(error.response.data.message);
      }
      console.log(error);
    }
  };
}

export function getSingleRoomDetail(id) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/roomConfigurations/details?id=${id}`
        // { headers }
      );

      dispatch(slice.actions.getRoomDetail(response.data));
      // if (handleGetRoomDetailResponse) {
      //   handleGetRoomDetailResponse(response.data);
      // }
    } catch (error) {
      // if (handleGetRoomDetailResponse) {
      //   handleGetRoomDetailResponse(error.response.data.message);
      // }
      console.log(error);
    }
  };
}

export function getAllProfileRoomsList(handleGetRoomListResponse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/roomConfigurations/rooms-for-alerts-demo`
        // { headers }
      );
      dispatch(slice.actions.getAllProfileRooms(response.data));
      if (handleGetRoomListResponse) {
        handleGetRoomListResponse();
      }
    } catch (error) {
      if (handleGetRoomListResponse) {
        handleGetRoomListResponse(error.response.data.message);
      }
      console.log(error);
    }
  };
}

export function getRoomsListForLock(handleGetRoomListResponse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/roomConfigurations/rooms-for-lock-creation`
        // { headers }
      );
      dispatch(slice.actions.getRoomsLockList(response.data));
      if (handleGetRoomListResponse) {
        handleGetRoomListResponse();
      }
    } catch (error) {
      if (handleGetRoomListResponse) {
        handleGetRoomListResponse(error.response.data.message);
      }
      console.log(error);
    }
  };
}

export function createRoom(data, handleCreateRoomResponse) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/roomConfigurations`,
        data
        // { headers }
      );
      handleCreateRoomResponse(response.data);
      dispatch(getRoomsList());
    } catch (error) {
      handleCreateRoomResponse(error.response.data.message);
      console.log(error);
    }
  };
}

export function updateRoom(data, handleUpdateRoomResponse) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/roomConfigurations?id=${data.id}`,
        data
        // { headers }
      );
      handleUpdateRoomResponse(response.data);
      dispatch(getRoomsList());
    } catch (error) {
      if (handleUpdateRoomResponse) {
        handleUpdateRoomResponse(error.response.data.message);
      }
      console.log(error);
    }
  };
}

export function deleteRoom(id, handleDeleteRoomResponse) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/roomConfigurations?id=${id}`
        // { headers }
      );
      handleDeleteRoomResponse(response.data);
      dispatch(getRoomsList());
    } catch (error) {
      if (handleDeleteRoomResponse) {
        handleDeleteRoomResponse(error.response.data);
      }
      console.log(error);
    }
  };
}

export function generateQRCode(id, handleResponse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/roomConfigurations/generateqrcode/${id}`
        // { headers }
      );
      if (handleResponse) {
        handleResponse(response.data);
      }
      dispatch(slice.actions.getQRCode(response.data));
    } catch (error) {
      if (handleResponse) {
        handleResponse(error.response.data.message);
      }
      console.log(error);
    }
  };
}

export function muteRoom(id, data, handleResponse) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/roomConfigurations/suppress-unsuppress-room?id=${id}`,
        data
        // { headers }
      );
      handleResponse(response.data);
      dispatch(getRoomsList());
    } catch (error) {
      console.log(error);
      if (handleResponse) {
        handleResponse(error.response.data);
      }
    }
  };
}

export function resetDetail() {
  return async () => {
    dispatch(slice.actions.resetRoomDetail());
  };
}
