import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Layout from "../layouts/index";
import routes from "../layouts/routesList";
import { Box } from "@mui/material";
import HourGlassLoader from "../components/Loader/HourGlassLoader";

const ProtectedRoute = ({ socket, token }) => {
  const location = useLocation();
  const [loader, setLoader] = useState(true);

  const path = location.pathname.split("/")[1].split("-").join("");
  const isSuperAdmin = localStorage.getItem("isSuperAdmin");
  // const token = localStorage.getItem("token"); // determine if authorized, from context or however you're doing it

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  let routePath = [];
  for (let i = 0; i < routes?.length; i++) {
    if (routes[i].children) {
      let temp = routes[i]?.children;
      for (let j = 0; j < temp.length; j++) {
        if (temp[j]?.path) routePath.push(temp[j].path);
      }
    }
    //Normal Routes
    if (routes[i]?.path) routePath.push(routes[i].path);
  }

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return isSuperAdmin && token ? (
    path === "404" ? (
      <Outlet />
    ) : loader ? (
      <Box
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <HourGlassLoader />
      </Box>
    ) : (
      <Layout socket={socket}>
        <Outlet />
      </Layout>
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
