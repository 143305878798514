import React, { useState } from "react";
// import ReportsPdf from "./ReportsPDF";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { saveAs } from "file-saver";
import useLocales from "../../hooks/useLocales";
import { pdf } from "@react-pdf/renderer";
import { logsSearchFilterButton } from "./muiReportComponentStyles";
import NewReportsPdf from "./NewReportsPDF";

const DownloadPDFButton = ({
  reportsData,
  loading,
  roomName,
  filter,
  date,
}) => {
  const { translate } = useLocales();
  const [openBD, setOpenBD] = useState(false);

  const getReformatedDate = (str) => {
    let temp =
      filter == 2
        ? `MonthlyReports `
        : filter == 1
        ? `WeeklyReports `
        : `DailyReports `;
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    let date = str.toLocaleString("en-US", options);
    let output = `${temp}- ${date}`;
    return output;
  };

  // const memoizedPDF = useMemo(
  //   () => (
  //     <PDFDownloadLink
  //       style={{
  //         color: "white",
  //         width: "100%",
  //         height: "100%",
  //         display: "flex",
  //         alignItems: "center",
  //         textDecoration: "none",
  //         justifyContent: "center",
  //       }}
  //       document={
  //         <ReportsPdf
  //           reportsData={reportsData}
  //           roomName={roomName}
  //           filter={filter}
  //         />
  //       }
  //       fileName={getReformatedDate(new Date())}
  //     >
  //       {({ loading }) => (loading ? "Loading..." : translate("export"))}
  //     </PDFDownloadLink>
  //   ),
  //   [reportsData]
  // );

  const generateLetterPdf = async () => {
    setOpenBD(true);
    setTimeout(async () => {
      const blob = await pdf(
        <NewReportsPdf
          reportsData={reportsData}
          roomName={roomName}
          filter={filter}
          date={date}
        />
      ).toBlob();
      setOpenBD(false);
      saveAs(blob, getReformatedDate(new Date()) + ".pdf");
    }, 1000);
  };

  return (
    <>
      {/* <Button
        disableRipple
        disabled={loading}
        disableElevation
        variant="contained"
        sx={logsSearchFilterButton}
      >
        {memoizedPDF}
      </Button> */}
      <Button
        disableRipple
        disabled={loading}
        disableElevation
        variant="contained"
        sx={logsSearchFilterButton}
        onClick={() => generateLetterPdf()}
      >
        {translate("exportToPdf")}
      </Button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default DownloadPDFButton;
