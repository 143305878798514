//GLOBLE COLORS//

const BACKGROUND_LIGHT = "#F4F8FB";
const BACKGROUND_DARK = "#526D82";

//Sidebar Color
const LAYOUT_A_LIGHT = "#0054A4";
const LAYOUT_A_DARK = "#27374D";

//Selected Sidebar Tab Color
const LAYOUT_B_LIGHT = "#EC313B";
const LAYOUT_B_DARK = "#116D6E";

//Selected Sidebar Tab Color
const SIDEBAR_HOVER_LIGHT = "#0F69BE";
const SIDEBAR_HOVER_DARK = "#526D82";

const FONT_COLOR_LIGHT = "#7D8EA3";
const FONT_COLOR_DARK = "#cad5e3";

const TABLE_FONT_COLOR_LIGHT = "#6D7B88";
const TABLE_FONT_COLOR_DARK = "black";

const TABLE_ODD_ROW_LIGHT = "#F5F5F5";
const TABLE_ODD_ROW_DARK = "#9DB2BF";
const TABLE_EVEN_ROW_LIGHT = "white";
const TABLE_EVEN_ROW_DARK = "#DDE6ED";

const TABLE_ROW_HOVER_LIGHT = "#DAEAF9";
const TABLE_ROW_HOVER_DARK = "#92a3ad";

const SEARCH_BAR_BG_LIGHT = "white";
const SEARCH_BAR_BG_DARK = "#526D82";

const TABLE_CHIPS_LIGHT = "#3276b6";
const TABLE_CHIPS_DARK = "#525e70";

//DASHBOARD COLORS//

const DASHBOARD_WELCOME_HEADING_LIGHT = "black";
const DASHBOARD_WELCOME_HEADING_DARK = "#cad5e3";

const DASHBOARD_ALARM_CARD_BG_LIGHT = "white";
const DASHBOARD_ALARM_CARD_BG_DARK = "black";

const DASHBOARD_ROOMS_LOGS_CARD_BG_LIGHT = "white";
const DASHBOARD_ROOMS_LOGS_CARD_BG_DARK = "black";

const DASHBOARD_TIMELINE_BOX_BG_LIGHT = "#e0f0ff";
const DASHBOARD_TIMELINE_BOX_BG_DARK = "#2d3032";

export const lightTheme = {
  palette: {
    mode: "light",
    background: {
      default: BACKGROUND_LIGHT,
      bgLayoutA: LAYOUT_A_LIGHT,
      bgLayoutB: LAYOUT_B_LIGHT,
      bgTableChip: TABLE_CHIPS_LIGHT,
      bgSearchBar: SEARCH_BAR_BG_LIGHT,
      bgTableOddRow: TABLE_ODD_ROW_LIGHT,
      bgSidebarHover: SIDEBAR_HOVER_LIGHT,
      bgTableEvenRow: TABLE_EVEN_ROW_LIGHT,
      bgTableRowHover: TABLE_ROW_HOVER_LIGHT,
      bgTimeline: DASHBOARD_TIMELINE_BOX_BG_LIGHT,
      dashboardAlarmCardBg: DASHBOARD_ALARM_CARD_BG_LIGHT,
      dashboardRoomLogCardBg: DASHBOARD_ROOMS_LOGS_CARD_BG_LIGHT,
      main: "#bf0603",
      test: "green",
    },
    primary: {
      main: "#1976d2",
      fontColor: FONT_COLOR_LIGHT,
      tableFontColor: TABLE_FONT_COLOR_LIGHT,
      dashboardWelcomeHeading: DASHBOARD_WELCOME_HEADING_LIGHT,
      test: "#d40b8d",
    },
  },
};

export const darkTheme = {
  palette: {
    mode: "dark",
    background: {
      default: BACKGROUND_DARK,
      bgLayoutA: LAYOUT_A_DARK,
      bgLayoutB: LAYOUT_B_DARK,
      bgTableChip: TABLE_CHIPS_DARK,
      bgSearchBar: SEARCH_BAR_BG_DARK,
      bgTableOddRow: TABLE_ODD_ROW_DARK,
      bgSidebarHover: SIDEBAR_HOVER_DARK,
      bgTableEvenRow: TABLE_EVEN_ROW_DARK,
      bgTableRowHover: TABLE_ROW_HOVER_DARK,
      bgTimeline: DASHBOARD_TIMELINE_BOX_BG_DARK,
      dashboardAlarmCardBg: DASHBOARD_ALARM_CARD_BG_DARK,
      dashboardRoomLogCardBg: DASHBOARD_ROOMS_LOGS_CARD_BG_DARK,
      main: "purple",
      test: "#f9dcdc",
    },
    primary: {
      main: "#116D6E",
      fontColor: FONT_COLOR_DARK,
      tableFontColor: TABLE_FONT_COLOR_DARK,
      dashboardWelcomeHeading: DASHBOARD_WELCOME_HEADING_DARK,
      test: "#ee5c42",
    },
  },
};
