import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axiosConfig";
import { dispatch } from "../store";

// const headers = {
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
//   timezoneutcoffset: new Date().getTimezoneOffset(),
// };
const initialState = {
  languageList: [],
};

const slice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    getLanguages(state, action) {
      state.languageList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getLanguageList(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/languages`
        // { headers }
      );
      dispatch(slice.actions.getLanguages(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}

export function createLanguage(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/languages`,
        data
        // { headers }
      );
      if (handleApiRes) handleApiRes(response.data);
      dispatch(getLanguageList());
    } catch (error) {
      if (handleApiRes) handleApiRes(error.response.data.message);
      console.log(error);
    }
  };
}
export function updateLanguage(id, data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/languages?id=${id}`,
        data
        // { headers }
      );
      if (handleApiRes) handleApiRes(response.data);
      dispatch(getLanguageList());
    } catch (error) {
      if (handleApiRes) handleApiRes(error.response.data.message);
      console.log(error);
    }
  };
}

export function deleteLanguage(id, handleApiRes) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/languages?id=${id}`
        // { headers }
      );
      if (handleApiRes) handleApiRes(response.data);
      dispatch(getLanguageList());
    } catch (error) {
      if (handleApiRes) handleApiRes(error.response.data.message);
      console.log(error);
    }
  };
}

export function uploadLanguageImage(id, data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/languages/upload-language-image?id=${id}`,
        data
        // { headers }
      );
      if (handleApiRes) handleApiRes(response.data);
      dispatch(getLanguageList());
    } catch (error) {
      if (handleApiRes) handleApiRes(error.response.data.message);
      console.log(error);
    }
  };
}
