import React from "react";
import Sidebar from "./Sidebar";
import { Grid } from "@mui/material";
import { layoutChildrenBox, leftbarStyles } from "../utils/muiComponentStyles";
import Topbar from "./Topbar";

const index = ({ children, socket }) => {
  const classes = leftbarStyles();

  return (
    <div className={classes.root}>
      <Topbar socket={socket} />
      <Sidebar />
      <Grid container sx={layoutChildrenBox}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default index;
