import React, { useEffect, useState } from "react";
// @mui
import { MenuItem, Stack } from "@mui/material";
// hooks
import useLocales from "../hooks/useLocales";
// components
import Image from "../components/Image";
import MenuPopover from "../components/MenuPopover";
import IconButtonAnimate from "../components/IconButtonAnimate";
import { getLanguageList } from "../redux/slices/LanguageSlice";
import { dispatch, useSelector } from "../redux/store";
import { updateUserlanguage } from "../redux/slices/UserSlice";
// import { getDashboardDataList } from "../redux/slices/AlarmSlice";

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const { currentLang, onChangeLang } = useLocales();
  const [open, setOpen] = useState(null);

  const [langList, setLangList] = useState([]);

  let { languageList } = useSelector((state) => state.languageReducer);

  useEffect(() => {
    dispatch(getLanguageList());
  }, []);

  useEffect(() => {
    setLangList(languageList);
  }, [languageList]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (newLang, id) => {
    //updating user language id to receive languages in active language
    const data = {
      languageId: id,
      newLang,
    };
    dispatch(updateUserlanguage(data, handleApiRes));

    //updating user language in local stroage to get alarms in selected language

    handleClose();
  };

  const handleApiRes = (res) => {
    // dispatch(getDashboardDataList());
    if (res.status) {
      let userDetails = JSON.parse(localStorage.getItem("user"));
      userDetails["languageId"] = res.id;
      localStorage.setItem("user", JSON.stringify(userDetails));

      onChangeLang(res.newLang);
      window.location.reload();
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && { bgcolor: "action.selected" }),
        }}
      >
        <Image disabledEffect src={currentLang?.url} alt={currentLang?.name} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.75}>
          {langList.map((option) => (
            <MenuItem
              key={option?.id}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value, option?.id)}
            >
              <Image
                disabledEffect
                alt={"langIcon"}
                src={option?.url}
                sx={{ width: 28, mr: 2 }}
              />

              {option?.name}
            </MenuItem>
          ))}
          {/* {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <Image
                disabledEffect
                alt={option.label}
                src={option.icon}
                sx={{ width: 28, mr: 2 }}
              />

              {option.label}
            </MenuItem>
          ))} */}
        </Stack>
      </MenuPopover>
    </>
  );
}
