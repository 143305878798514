import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axiosConfig";
import { dispatch } from "../store";

// const headers = {
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
//   timezoneutcoffset: new Date().getTimezoneOffset(),
// };
const initialState = {
  usersList: [],
  accessRuleList: [],
};

const slice = createSlice({
  name: "accessRules",
  initialState,
  reducers: {
    getAccessRules(state, action) {
      state.accessRuleList = action.payload.data;
    },
    postUsers(state, action) {
      state.usersList = action.payload("");
    },
  },
});

export default slice.reducer;

export function getAccessRuleList(id, handleApiRes, isUser = false) {
  return async () => {
    try {
      let response;
      if (!isUser) {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/accessrules?type=group&groupId=${id}`
          // { headers }
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/accessrules?type=user&userId=${id}`
          // { headers }
        );
      }
      dispatch(slice.actions.getAccessRules(response.data));
      if (handleApiRes) {
        handleApiRes(response.data);
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error);
      }
    }
  };
}

export function createAccessRule(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/accessrules`,
        data
        // {
        //   headers,
        // }
      );

      if (handleApiRes) {
        handleApiRes(response.data);
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error.response.data.message);
      }
    }
  };
}

export function updateUser(id, data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/users?id=${id}`,
        data
        // {
        //   headers,
        // }
      );
      // dispatch(getUsersList());
      if (handleApiRes) {
        handleApiRes(response.data);
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error.response.data.message);
      }
    }
  };
}
