import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { dispatch } from "../store";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  timezoneutcoffset: new Date().getTimezoneOffset(),
};
const initialState = {
  deviceDetail: [],
  deviceList: [],
  deviceTypeList: [],
  deviceTypeDetail: [],
};

const slice = createSlice({
  name: "deviceList",
  initialState,
  reducers: {
    getDeviceDetail(state, action) {
      state.deviceDetail = action.payload.data;
    },
    getDevices(state, action) {
      state.deviceList = action.payload.data;
    },
    getDeviceTypes(state, action) {
      state.deviceTypeList = action.payload.data;
    },
    getDeviceTypeDetail(state, action) {
      state.deviceTypeDetail = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getDeviceDetailConfig(id) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/deviceConfigurations/details?id=${id}`,
        { headers }
      );
      dispatch(slice.actions.getDeviceDetail(response.data));
      // if (handleApiRes) handleApiRes();
    } catch (error) {
      // if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}
export function getDeviceList(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/deviceConfigurations`,
        { headers }
      );
      dispatch(slice.actions.getDevices(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}
export function getDeviceTypeList(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/deviceTypes`,
        { headers }
      );
      dispatch(slice.actions.getDeviceTypes(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}
export function getDeviceTypeDetail(id, handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/deviceTypeSettings?deviceTypeId=${id}`,
        { headers }
      );
      dispatch(slice.actions.getDeviceTypeDetail(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}

export function createDevice(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/deviceConfigurations`,
        data,
        { headers }
      );
      // console.log(response.data);
      if (handleApiRes) handleApiRes(response.data);
      dispatch(getDeviceList());
    } catch (error) {
      if (handleApiRes) handleApiRes(error);
      console.log(error);
    }
  };
}
export function deleteDevice(id, handleApiRes) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/deviceConfigurations?id=${id}`,
        { headers }
      );
      // console.log("delete", response);
      handleApiRes(response.data);
      dispatch(getDeviceList());
    } catch (error) {
      handleApiRes(error.response.data.message);
      console.log(error);
    }
  };
}

export function updateDevice(id, data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/deviceConfigurations?id=${id}`,
        data,
        { headers }
      );
      handleApiRes(response.data);
      // handleResponse(response.data, id);
      // dispatch(slice.actions.getUserProfile(response.data));
      dispatch(getDeviceList());
    } catch (error) {
      handleApiRes(error);
      console.log(error);
    }
  };
}
