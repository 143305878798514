export const generateAlarmButton = {
  "&.MuiButton-root": {
    backgroundColor: "background.bgLayoutB",
    borderColor: "background.bgLayoutB",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "15px",
    color: "#FFFFFF",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "background.bgLayoutB",
      borderRadius: "10px",
    },
  },
};

export const cardHeader = {
  "&.MuiCardHeader-root": {
    backgroundColor: "background.bgLayoutA",
    ".MuiTypography-root": {
      fontFamily: "Poppins-semibold",
      fontStyle: "normal",
      // fontWeight: 700,
      // fontSize: "20px",
      // textTransform: "capitalize",
      color: "#FFFFFF",
    },
  },
};
export const cardHeaderTypography = {
  fontFamily: "Poppins-semibold",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "20px",
  textTransform: "capitalize",
  color: "#FFFFFF",
};

export const tableRow = {
  "&.MuiTableRow-root": {
    padding: "0px",
    margin: "0px",
    // cursor: "pointer",
    // minWidth: "700px",
    backgroundColor: "background.bgTableEvenRow",
    border: "none",
    "&.MuiTableRow-root:hover": {
      backgroundColor: "#DAEAF9;",
    },
  },
};

export const tableCellName = {
  "&.MuiTableCell-root": {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    color: "primary.tableFontColor",
    width: "30%",
    minWidth: "18.5%",
  },
};

export const tableCellWarningAndDate = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: "16px",
  color: "primary.tableFontColor",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  width: "100%",
};

export const tableCell = {
  "&.MuiTableCell-root": {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    color: "primary.tableFontColor",
  },
};

export const alarmRoomTableCell = {
  "&.MuiTableCell-root": {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "primary.tableFontColor",
  },
};

export const tableCollapseCell = {
  "&.MuiTableCell-root": {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "14px",
    color: "primary.tableFontColor",
    padding: "10px",
  },
};

export const checkBoxLabel = {
  "&.MuiFormControlLabel-root": {
    ".MuiFormControlLabel-label": {
      width: 110,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.675px",
      color: "#7D8EA3",
      display: "flex",
    },
  },
};

export const clearALarmButton = {
  "&.MuiButton-root": {
    backgroundColor: "background.bgLayoutB",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "15px",
    color: "#FFFFFF",
    marginRight: "10px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "background.bgLayoutB",
      borderRadius: "10px",
    },
  },
};

export const procedureAlarmUpdateButton = {
  "&.MuiButton-root": {
    backgroundColor: "background.bgLayoutA",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "15px",
    color: "#FFFFFF",
    textTransform: "capitalize",
  },
  "&:hover": {
    background: "#0054A0",
    borderRadius: "10px",
  },
};
