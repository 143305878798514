import React, { useContext, useState } from "react";
import {
  Button,
  Container,
  Typography,
  DialogActions,
  DialogContent,
} from "@mui/material";
import {
  BootstrapDialog,
  languageHeading,
  dialogCancelButton,
  dialogDeleteButton,
  BootstrapDialogTitle,
} from "./muiLanguageComponentStyles";
import { useDispatch } from "../../redux/store";
import useLocales from "../../hooks/useLocales";
import ToasterContext from "../../utils/context/toasterContext";
import { deleteLanguage } from "../../redux/slices/LanguageSlice";
import CircularProgressLoader from "../../utils/CircularProgressLoader";

const DeleteLanguageDialog = ({
  languageDetail,
  openDeleteDialog,
  handleCloseDialog,
}) => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const [loader, setLoader] = useState(false);
  const fireToasterContext = useContext(ToasterContext);

  const handleDeleteLanguage = () => {
    setLoader(true);
    dispatch(deleteLanguage(languageDetail?.id, handleApiRes));
  };

  const handleApiRes = (data) => {
    setLoader(false);

    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Language Deleted successfully"
      );
      handleCloseDialog();
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  return (
    <Container>
      <BootstrapDialog
        fullWidth
        open={openDeleteDialog}
        onClose={handleCloseDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={languageHeading} variant="languageTableHeadingBold">
            {translate("deleteLanguage")}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography sx={languageHeading} variant="languageTableCellName">
            {translate("areYouSure")} <b>{languageDetail?.name}</b> ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            sx={dialogCancelButton}
            onClick={handleCloseDialog}
          >
            <Typography
              sx={languageHeading}
              variant="languageHeadingBoldButton"
            >
              {translate("cancel")}
            </Typography>
          </Button>
          <Button
            //   disabled={!groupName}
            onClick={() => handleDeleteLanguage()}
            disableRipple
            sx={
              loader
                ? {
                    background: "#979797",
                    borderRadius: "10px",
                    "&:hover": { backgroundColor: "#979797" },
                  }
                : dialogDeleteButton
            }
          >
            <Typography
              sx={languageHeading}
              variant="languageHeadingBoldButton"
            >
              {translate("deleteLanguage")}
            </Typography>
            {loader ? <CircularProgressLoader /> : ""}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Container>
  );
};

export default DeleteLanguageDialog;
