import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axiosConfig";
import { dispatch } from "../store";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  timezoneutcoffset: new Date().getTimezoneOffset(),
};
const initialState = {
  reportList: [],
  allReportList: [],
  allReportListForPDF: [],
  sensorReportList: [],
};

const slice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    getReports(state, action) {
      state.reportList = action.payload.data;
    },
    getSensorForReports(state, action) {
      state.sensorReportList = action.payload.data;
    },
    getReportsList(state, action) {
      state.allReportList = action.payload.data;
    },
    getReportsListForPDF(state, action) {
      state.allReportListForPDF = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getReportList(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/inputConfigurations`,
        { headers }
      );
      dispatch(slice.actions.getReports(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}

export function getSensorListForReport() {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/sensors/events-grouped-by-sensors`,
        { headers }
      );
      dispatch(slice.actions.getSensorForReports(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getAllReportList(
  alarmStatus,
  roomId,
  filter,
  year,
  month,
  date,
  sensorEventId,
  handleApiRes
) {
  return async () => {
    try {
      let period = "";
      filter == 0
        ? (period = "daily")
        : filter == 1
        ? (period = "weekly")
        : filter == 2
        ? (period = "monthly")
        : (period = "");
      let response;
      if (roomId == "All" && sensorEventId == "All") {
        if (filter == 2) {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reports?period=${period}&year=${year}&month=${month}&status=${alarmStatus}`,
            { headers }
          );
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reports?period=${period}&year=${year}&month=${month}&date=${date}&status=${alarmStatus}`,
            { headers }
          );
        }
      } else if (roomId == "All") {
        if (filter == 2) {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reports?sensorEventId=${sensorEventId}&period=${period}&year=${year}&month=${month}&status=${alarmStatus}`,
            { headers }
          );
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reports?sensorEventId=${sensorEventId}&period=${period}&year=${year}&month=${month}&date=${date}&status=${alarmStatus}`,
            { headers }
          );
        }
      } else if (sensorEventId == "All") {
        if (filter == 2) {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reports?roomId=${roomId}&period=${period}&year=${year}&month=${month}&status=${alarmStatus}`,
            { headers }
          );
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reports?roomId=${roomId}&period=${period}&year=${year}&month=${month}&date=${date}&status=${alarmStatus}`,
            { headers }
          );
        }
      } else {
        if (filter == 2) {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reports?roomId=${roomId}&sensorEventId=${sensorEventId}&period=${period}&year=${year}&month=${month}&status=${alarmStatus}`,
            { headers }
          );
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reports?roomId=${roomId}&sensorEventId=${sensorEventId}&period=${period}&year=${year}&month=${month}&date=${date}&status=${alarmStatus}`,
            { headers }
          );
        }
      }
      dispatch(slice.actions.getReportsList(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      console.log(error);
      if (handleApiRes) handleApiRes();
    }
  };
}

// const getAlarmStatusChangeTimeDiff = (startDate, endDate) => {
//   // Step 2: Calculate the Difference
//   const diffInMs = endDate - startDate; // Difference in milliseconds
//   const diffInSeconds = Math.floor(diffInMs / 1000); // Convert to seconds

//   // Step 3: Format the Difference
//   const minutes = Math.floor(diffInSeconds / 60);
//   const seconds = diffInSeconds % 60;

//   // Ensure seconds are two digits
//   const formattedTime = `${minutes}:${seconds.toString().padStart(2, "0")}`;
//   return formattedTime;
// };

// const restructurePdfData = (response) => {
//   let data = [...response.data];

//   let sortedData = data.sort((a, b) => {
//     if (a.roomId === b.roomId) {
//       return new Date(a.resolvedAt) - new Date(b.resolvedAt);
//     }
//     return a.roomId - b.roomId;
//   });

//   // Iterate through sorted data and adjust acceptedAt based on the scenario
//   let lastIndex = 0;

//   for (let i = 1; i < sortedData.length; i++) {
//     const currentRecord = sortedData[i];
//     const lastRecordInGroup = sortedData[lastIndex];

//     // Check if the current record is in the same room and has the same resolvedAt date as the previous
//     if (
//       currentRecord.roomId === lastRecordInGroup.roomId &&
//       currentRecord.resolvedAt === lastRecordInGroup.resolvedAt
//     ) {
//       continue; // Continue if it's part of the same group
//     }

//     // We reached the end of a group; update acceptedAt for all records in this group
//     for (let j = lastIndex; j < i - 1; j++) {
//       sortedData[j].acceptedAt = sortedData[i - 1].acceptedAt;
//       sortedData[j].acceptedBy = sortedData[i - 1].acceptedBy;
//     }

//     // Update the lastIndex to the current position
//     lastIndex = i;
//   }

//   // Handle the final group
//   for (let j = lastIndex; j < sortedData.length - 1; j++) {
//     sortedData[j].acceptedAt = sortedData[sortedData.length - 1].acceptedAt;
//     sortedData[j].acceptedBy = sortedData[sortedData.length - 1].acceptedBy;
//   }

//   sortedData = sortedData.sort((a, b) => a.id - b.id);

//   for (let i = 0; i < sortedData.length; i++) {
//     const createdAt = sortedData[i].get("createdAt");
//     if (sortedData[i].acceptedAt) {
//       sortedData[i].durationUntilAccepted = getAlarmStatusChangeTimeDiff(
//         createdAt,
//         sortedData[i].get("acceptedAt")
//       );
//     }
//     if (sortedData[i].resolvedAt) {
//       sortedData[i].durationUntilResolved = getAlarmStatusChangeTimeDiff(
//         createdAt,
//         sortedData[i].get("resolvedAt")
//       );
//     }
//   }

//   return {
//     status: true,
//     message: "Reports",
//     data: sortedData,
//   };
// };

const calculateTimeDifference = (createdAt, acceptedAt) => {
  // Parse the dates
  const createdDate = new Date(createdAt);
  const acceptedDate = new Date(acceptedAt);

  // Calculate the difference in milliseconds
  const differenceInMs = acceptedDate - createdDate;

  // Convert milliseconds to minutes and seconds
  const totalSeconds = Math.floor(differenceInMs / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  // Format minutes and seconds as mm:ss
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
};

const restructurePdfData = (response) => {
  let data = [...response.data];

  let sortedData = data.sort((a, b) => {
    if (a.roomId === b.roomId) {
      return new Date(a.createdAt) - new Date(b.createdAt);
    }
    return a.roomId - b.roomId;
  });

  // Step 2: Apply the acceptedAt scenario
  let currentRoomId = null;
  let roomGroup = [];

  for (let i = 0; i < sortedData.length; i++) {
    const record = sortedData[i];

    if (record.roomId !== currentRoomId) {
      // New room group
      currentRoomId = record.roomId;
      roomGroup = [];
    }

    roomGroup.push(record);

    if (roomGroup.length > 1) {
      const lastRecord = roomGroup[roomGroup.length - 1];
      const previousRecords = roomGroup.slice(0, -1);

      if (
        new Date(lastRecord.createdAt) <
          new Date(previousRecords[0].acceptedAt) &&
        lastRecord.acceptedAt !== previousRecords[0].acceptedAt
      ) {
        // Update acceptedAt of all previous records in the group
        previousRecords.forEach((prev) => {
          prev.acceptedAt = lastRecord.acceptedAt;
          prev.acceptedBy = lastRecord.acceptedBy;
        });
      }
    }
  }

  // Step 3: Apply the resolvedAt scenario
  currentRoomId = null;
  roomGroup = [];

  for (let i = 0; i < sortedData.length; i++) {
    const record = sortedData[i];

    if (record.roomId !== currentRoomId) {
      // Handle the last room group
      if (roomGroup.length > 1) {
        const lastRecord = roomGroup[roomGroup.length - 1];
        roomGroup.slice(0, -1).forEach((prev) => {
          prev.acceptedAt = lastRecord.acceptedAt;
          prev.acceptedBy = lastRecord.acceptedBy;
        });
      }

      // Start a new room group
      currentRoomId = record.roomId;
      roomGroup = [];
    }

    // If it's the same roomId, check if resolvedAt matches
    if (roomGroup.length > 0 && record.resolvedAt !== roomGroup[0].resolvedAt) {
      const lastRecord = roomGroup[roomGroup.length - 1];
      roomGroup.slice(0, -1).forEach((prev) => {
        prev.acceptedAt = lastRecord.acceptedAt;
        prev.acceptedBy = lastRecord.acceptedBy;
      });
      roomGroup = [];
    }

    roomGroup.push(record);
  }

  // Handle the final room group
  if (roomGroup.length > 1) {
    const lastRecord = roomGroup[roomGroup.length - 1];
    roomGroup.slice(0, -1).forEach((prev) => {
      prev.acceptedAt = lastRecord.acceptedAt;
      prev.acceptedBy = lastRecord.acceptedBy;
    });
  }

  sortedData.forEach((record) => {
    if (record.createdAt && record.acceptedAt) {
      record.durationUntilAccepted = calculateTimeDifference(
        record.createdAt,
        record.acceptedAt
      );
    }
    if (record.createdAt && record.resolvedAt) {
      record.durationUntilResolved = calculateTimeDifference(
        record.createdAt,
        record.resolvedAt
      );
    }
  });

  sortedData = sortedData.sort((a, b) => a.id - b.id);

  return {
    status: true,
    message: "Reports",
    data: sortedData,
  };
};

export function getAllReportListForPDF(
  alarmStatus,
  roomId,
  filter,
  year,
  month,
  date,
  sensorEventId,
  handleApiRes
) {
  return async () => {
    try {
      let period = "";
      filter == 0
        ? (period = "daily")
        : filter == 1
        ? (period = "weekly")
        : filter == 2
        ? (period = "monthly")
        : (period = "");
      let response;

      if (roomId == "All" && sensorEventId == "All") {
        if (filter == 2) {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reportspdf-v2?period=${period}&year=${year}&month=${month}&status=${alarmStatus}`,
            { headers }
          );
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reportspdf-v2?period=${period}&year=${year}&month=${month}&date=${date}&status=${alarmStatus}`,
            { headers }
          );
        }
      } else if (roomId == "All") {
        if (filter == 2) {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reportspdf-v2?sensorEventId=${sensorEventId}&period=${period}&year=${year}&month=${month}&status=${alarmStatus}`,
            { headers }
          );
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reportspdf-v2?sensorEventId=${sensorEventId}&period=${period}&year=${year}&month=${month}&date=${date}&status=${alarmStatus}`,
            { headers }
          );
        }
      } else if (sensorEventId == "All") {
        if (filter == 2) {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reportspdf-v2?roomId=${roomId}&period=${period}&year=${year}&month=${month}&status=${alarmStatus}`,
            { headers }
          );
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reportspdf-v2?roomId=${roomId}&period=${period}&year=${year}&month=${month}&date=${date}&status=${alarmStatus}`,
            { headers }
          );
        }
      } else {
        if (filter == 2) {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reportspdf-v2?roomId=${roomId}&sensorEventId=${sensorEventId}&period=${period}&year=${year}&month=${month}&status=${alarmStatus}`,
            { headers }
          );
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reportspdf-v2?roomId=${roomId}&sensorEventId=${sensorEventId}&period=${period}&year=${year}&month=${month}&date=${date}&status=${alarmStatus}`,
            { headers }
          );
        }
      }

      let restructuredPdfData = restructurePdfData(response.data);
      console.log(restructuredPdfData);
      // dispatch(slice.actions.getReportsListForPDF(response.data));
      dispatch(slice.actions.getReportsListForPDF(restructuredPdfData));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      console.log(error);
      if (handleApiRes) handleApiRes();
    }
  };
}
