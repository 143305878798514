import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import Loadable from "../components/Loader/ComponentLoadable";
import { PageProvider } from "../utils/context/PageContext";

const Alarm = Loadable(lazy(() => import("../pages/Alarm")));
const Log = Loadable(lazy(() => import("../pages/Log")));

// const Room = Loadable(lazy(() => import("../pages/Rooms")));
const AddRoom = Loadable(lazy(() => import("../pages/rooms/CreateRoom")));
const EditRoom = Loadable(lazy(() => import("../pages/rooms/EditRoom")));

const Procedure = Loadable(lazy(() => import("../pages/Procedure")));
// const CreateProcedure = Loadable(
//   lazy(() => import("../pages/procedure/CreateProcedure"))
// );
const EditProcedure = Loadable(
  lazy(() => import("../pages/procedure/EditProcedure"))
);

const Input = Loadable(lazy(() => import("../pages/Inputs")));
const AddInputDevices = Loadable(
  lazy(() => import("../pages/input-devices/AddInput"))
);
const UpdateInputDevice = Loadable(
  lazy(() => import("../pages/input-devices/UpdateInputDevice"))
);

// const Output = Loadable(lazy(() => import("../pages/Outputs")));
// const AddOutputDevices = Loadable(
//   lazy(() => import("../pages/output-devices/AddOutputDevices"))
// );
// const UpdateOutputDevice = Loadable(
//   lazy(() => import("../pages/output-devices/UpdateOutputDevice"))
// );

const Profile = Loadable(lazy(() => import("../pages/Profiles")));
const AddProfile = Loadable(lazy(() => import("../pages/profile/AddProfile")));
const UpdateProfile = Loadable(
  lazy(() => import("../pages/profile/UpdateProfile"))
);

const AllUsers = Loadable(lazy(() => import("../pages/AllUsers")));
const AddUser = Loadable(lazy(() => import("../pages/users/AddUser")));
const UpdateUser = Loadable(
  lazy(() => import("../pages/users/UpdateUserDetails"))
);

const License = Loadable(lazy(() => import("../pages/License")));
// const Template = Loadable(lazy(() => import("../pages/template/Template")));
const Category = Loadable(lazy(() => import("../pages/Category")));
const Device = Loadable(lazy(() => import("../pages/Devices")));
// const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const LoginOTP = Loadable(lazy(() => import("../pages/auth/LoginOTP")));
const Roles = Loadable(lazy(() => import("../pages/Roles")));
const RolesPermissions = Loadable(
  lazy(() => import("../pages/RolesPermissions"))
);
const UserRolesPermissions = Loadable(
  lazy(() => import("../pages/UserRolesPermissions"))
);
const NotMatch = Loadable(lazy(() => import("../pages/NotMatch")));
const NewDashboard = Loadable(lazy(() => import("../pages/NewDashboard")));
const Reports = Loadable(lazy(() => import("../pages/Reports")));
const DemoReady = Loadable(lazy(() => import("../pages/DemoReady")));
const Groups = Loadable(lazy(() => import("../pages/Groups")));
// const AddTemplate = Loadable(
//   lazy(() => import("../pages/template/AddTemplate"))
// );
// const CreateEventTemplate = Loadable(
//   lazy(() => import("../pages/template/CreateEventTemplete"))
// );
// const EditTemplate = Loadable(
//   lazy(() => import("../pages/template/EditTemplate"))
// );
const Language = Loadable(lazy(() => import("../pages/Language")));
const GroupAccessRulesIndex = Loadable(
  lazy(() => import("../components/groups/accessRules/AccessRulesIndex"))
);
const UsersAccessRulesIndex = Loadable(
  lazy(() => import("../components/users/accessRules/AccessRulesIndex"))
);
const Sensor = Loadable(lazy(() => import("../pages/Sensors")));
const CreateSensor = Loadable(
  lazy(() => import("../components/sensor/CreateSensor"))
);
const UpdateSensor = Loadable(
  lazy(() => import("../components/sensor/UpdateSensor"))
);
const AddLanguage = Loadable(
  lazy(() => import("../components/language/AddLanguage"))
);
const UpdateLanguage = Loadable(
  lazy(() => import("../components/language/UpdateLanguage"))
);

const index = ({ socket }) => {
  const permissions = localStorage.getItem("permissions");
  const token = localStorage.getItem("token");
  const permissionsArray =
    permissions?.length > 0 ? permissions.split(",") : [];

  return (
    <Router>
      <PageProvider>
        <Routes>
          <Route
            exact
            path="/"
            element={<ProtectedRoute socket={socket} token={token} />}
          >
            {permissionsArray.includes("show dashboard") && (
              <Route path="/" element={<NewDashboard socket={socket} />} />
            )}
            <Route path="/generate-reports" element={<Reports />} />
            {permissionsArray.includes("show alarms") && (
              <Route path="/overview" element={<Alarm socket={socket} />} />
            )}
            {/* {permissionsArray.includes("show dashboard") && (
            <Route path="/" element={<Dashboard />} />
          )} */}
            {permissionsArray.includes("show logs") && (
              <Route path="/history" element={<Log />} />
            )}
            {permissionsArray.includes("show procedures") && (
              <>
                <Route path="/room-events" element={<Procedure />} />
                {/* <Route
                path="/events/create-event"
                element={<CreateProcedure />}
              /> */}
                <Route
                  path="/room-events/edit-room-event"
                  element={<EditProcedure />}
                />
              </>
            )}
            {/* {permissionsArray.includes("show templates") && (
            <>
              <Route path="/event-templates" element={<Template />} />
              <Route
                path="/event-templates/add-templates"
                element={<AddTemplate />}
              />
              <Route
                path="/event-templates/create-event-template"
                element={<CreateEventTemplate />}
              />
              <Route
                path="/event-templates/edit-event-template"
                element={<EditTemplate />}
              />
            </>
          )} */}
            {permissionsArray.includes("show profiles") && (
              <>
                <Route path="/locations" element={<Profile />} />
                <Route
                  path="/locations/add-location"
                  element={<AddProfile />}
                />
                <Route
                  path="/locations/update-location"
                  element={<UpdateProfile />}
                />
              </>
            )}
            {permissionsArray.includes("show rooms") && (
              <>
                <Route path="/rooms" element={<Alarm socket={socket} />} />
                <Route path="/rooms/create-room" element={<AddRoom />} />
                <Route path="/rooms/edit-room/:id" element={<EditRoom />} />
              </>
            )}
            {permissionsArray.includes("show devices") && (
              <Route path="/devices" element={<Device />} />
            )}
            {permissionsArray.includes("show inputs") && (
              <>
                <Route path="/input-devices" element={<Input />} />
                <Route
                  path="/input-devices/add-input-devices"
                  element={<AddInputDevices />}
                />
                <Route
                  path="/input-devices/edit-input-device"
                  element={<UpdateInputDevice />}
                />
              </>
            )}
            {/* {permissionsArray.includes("show outputs") && (
            <>
              <Route path="/output-devices" element={<Output />} />
              <Route
                path="/output-devices/add-output-devices"
                element={<AddOutputDevices />}
              />
              <Route
                path="/output-devices/edit-output-device"
                element={<UpdateOutputDevice />}
              />
            </>
          )} */}
            {permissionsArray.includes("show categories") && (
              <Route path="/categories" element={<Category />} />
            )}
            {permissionsArray.includes("show license") && (
              <Route path="/license" element={<License />} />
            )}
            <Route path="/groups" element={<Groups />} />
            <Route
              path="/groups/access-rules"
              element={<GroupAccessRulesIndex />}
            />

            {/* <Route path="access-rules" element={<AccessRulesIndex />} /> */}
            <Route path="/languages" element={<Language />} />
            <Route path="/languages/add-language" element={<AddLanguage />} />
            <Route
              path="/languages/update-language"
              element={<UpdateLanguage />}
            />
            <Route path="/testing-&-logging" element={<DemoReady />} />

            <Route path="/all-users" element={<AllUsers />} />
            <Route
              path="/all-users/access-rules"
              element={<UsersAccessRulesIndex />}
            />
            <Route path="/all-users/add-user" element={<AddUser />} />
            <Route
              path="/all-users/update-user-details"
              element={<UpdateUser />}
            />

            {/* Sensor Routes */}
            <Route path="/sensors" element={<Sensor />} />
            <Route path="/sensors/device-type" element={<CreateSensor />} />
            <Route
              path="/sensors/edit-device-type"
              element={<UpdateSensor />}
            />

            <Route path="/roles" element={<Roles />} />
            <Route path="/roles-permissions" element={<RolesPermissions />} />
            <Route path="/user-roles" element={<UserRolesPermissions />} />

            <Route path="/404" element={<NotMatch />} />
            <Route path="*" element={<Navigate replace to="/404" />} />
          </Route>
          <Route exact path="/" element={<PublicRoute />}>
            <Route exact path="/login" element={<Login socket={socket} />} />
            <Route
              exact
              path="/login-otp"
              element={<LoginOTP socket={socket} />}
            />
          </Route>
        </Routes>
      </PageProvider>
    </Router>
  );
};

export default index;
