import React, { createContext, useState, useEffect } from "react";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import axios from "axios";
import { allLangs, defaultLang } from "../../Config";

const I18nContext = createContext();

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  timezoneutcoffset: new Date().getTimezoneOffset(),
};
let userData = JSON.parse(localStorage.getItem("user"));
let userLanguageId = userData?.languageId;

export async function getLanguageList() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_HOST_API_KEY}v1/languages`,
      { headers }
    );

    let languages = response?.data?.data;
    let resources = {};
    for (let i = 0; i < languages.length; i++) {
      for (let key in languages[i].featureTranslations) {
        resources[key] = languages[i].featureTranslations[key];
      }
    }
    return resources;
  } catch (error) {
    console.log(error);
  }
}

export const I18nProvider = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const userPreferredLang = allLangs.find(
      (lang) => lang.languageId === userLanguageId
    );

    getLanguageList().then((resources) => {
      i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          resources,
          lng: userPreferredLang ? userPreferredLang.value : "en",
          fallbackLng: defaultLang.value,
          debug: false,
          ns: ["translations"],
          defaultNS: "translations",
          interpolation: {
            escapeValue: false,
          },
        })
        .then(() => setIsInitialized(true));
    });
  }, []);

  const translateWithContext = (key, options) => {
    return i18n.t(key, options);
  };

  return (
    <I18nContext.Provider value={{ isInitialized, translateWithContext }}>
      {children}
    </I18nContext.Provider>
  );
};

export default I18nContext;
