import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import loginSliceReducer from "./slices/LoginSlice";
import profileSliceReducer from "./slices/profileSlice";
import dashboardSliceReducer from "./slices/dashboardSlice";
import logSliceReducer from "./slices/logSlice";
import userSliceReducer from "./slices/UserSlice";
import roleSliceReducer from "./slices/RoleSlice";
import alarmSliceReducer from "./slices/AlarmSlice";
import inputSliceReducer from "./slices/InputSlice";
import outputSliceReducer from "./slices/OutputSlice";
import procedureSliceReducer from "./slices/procedureSlice";
import languageSliceReducer from "./slices/LanguageSlice";
import roomSliceReducer from "./slices/RoomSlice";
import categorySliceReducer from "./slices/CategorySlice";
import deviceSliceReducer from "./slices/DeviceSlice";
import permissionSliceReducer from "./slices/permissionSlice";
import templateSliceReducer from "./slices/TemplateSlice";
import demoReadySliceReducer from "./slices/DemoReadySlice";
import reportSliceReducer from "./slices/ReportSlice";
import groupSliceReducer from "./slices/GroupSlice";
import accessRuleSliceReducer from "./slices/AccessRuleSlice";
import sensorSliceReducer from "./slices/SensorSlice";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  loginReducer: loginSliceReducer,
  profileReducer: profileSliceReducer,
  dashboardReducer: dashboardSliceReducer,
  logReducer: logSliceReducer,
  userReducer: userSliceReducer,
  roleReducer: roleSliceReducer,
  alarmReducer: alarmSliceReducer,
  procedureReducer: procedureSliceReducer,
  inputReducer: inputSliceReducer,
  outputReducer: outputSliceReducer,
  roomReducer: roomSliceReducer,
  categoryReducer: categorySliceReducer,
  deviceReducer: deviceSliceReducer,
  permissionReducer: permissionSliceReducer,
  templateReducer: templateSliceReducer,
  demoReadyReducer: demoReadySliceReducer,
  reportReducer: reportSliceReducer,
  groupReducer: groupSliceReducer,
  languageReducer: languageSliceReducer,
  accessRuleReducer: accessRuleSliceReducer,
  sensorReducer: sensorSliceReducer,
});

export { rootPersistConfig, rootReducer };
