import React, { useEffect, useState } from "react";
import {
  Button,
  InputBase,
  Typography,
  Grid,
  TableCell,
  Box,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  Paper,
  TableRow,
  IconButton,
  Avatar,
} from "@mui/material";
import {
  DeleteOutlineOutlined,
  EditOutlined,
  Search,
} from "@mui/icons-material";
import Loader from "../Loader/Loader";
import { useDispatch, useSelector } from "../../redux/store";
import CustomPagination from "../../hooks/useTablePagination";
import {
  getDeviceDetailConfig,
  getDeviceList,
  getDeviceTypeList,
} from "../../redux/slices/DeviceSlice";
import {
  customStyledTableRow,
  deviceHeading,
  StyledTableRow,
  tableAvatar,
  tableAvatarCell,
  tableContainer,
  tableHeadColor,
  tableIconBox,
  tableIconButton,
  tableIconButtonWithMargin,
  tableLoaderBox,
  tableSearch,
  tableSearchButton,
  tableSearchIcon,
} from "./muiDeviceComponentStyles";
import CreateDeviceDialog from "./CreateDeviceDialog";
import EditDeviceDialog from "./EditDeviceDialog";
import DeleteDeviceDialog from "./DeleteDeviceDialog";
import useLocales from "../../hooks/useLocales";
import { getRoomsListForLock } from "../../redux/slices/RoomSlice";

const Index = () => {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredList, setFilteredList] = useState([]);
  const [rowDeviceDetail, setRowDeviceDetail] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  let { deviceList } = useSelector((state) => state.deviceReducer);
  let { deviceTypeList } = useSelector((state) => state.deviceReducer);

  useEffect(() => {
    dispatch(getRoomsListForLock());
    dispatch(getDeviceList(handleApiRes));
    dispatch(getDeviceTypeList(handleApiRes));
  }, []);
  //setting filter list(main table iteration list) to deviceList coming from api
  useEffect(() => {
    setFilteredList(deviceList);
  }, [deviceList]);
  //api for specific device detail
  useEffect(() => {
    if (rowDeviceDetail.id) dispatch(getDeviceDetailConfig(rowDeviceDetail.id));
  }, [rowDeviceDetail]);

  const handleApiRes = () => {
    setLoading(false);
  };

  const handleOpenDialog = () => setOpenCreateDialog(true);
  const handleCloseDialog = () => {
    setOpenCreateDialog(false);
    setOpenDeleteDialog(false);
    setOpenEditDialog(false);
  };
  const handleSearch = (value) => {
    //setting table page number 1
    setPage(0);
    let filterArray = [];
    const toBeSearched = value.toString().toLowerCase();
    deviceList.map((item) => {
      const name = item.name.toString().toLowerCase();
      const deviceTypeName = item?.deviceType?.name?.toString().toLowerCase();
      if (name.includes(toBeSearched)) {
        filterArray.push(item);
      } else if (deviceTypeName.includes(toBeSearched)) {
        filterArray.push(item);
      }
    });
    setFilteredList(filterArray);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  return (
    <>
      <Grid spacing={2} container>
        {localStorage.getItem("permissions").includes("create device") ? (
          <Grid item lg={9} md={8} sm={8} xs={12}>
            <InputBase
              onChange={(e) => handleSearch(e.target.value)}
              sx={tableSearch}
              placeholder={translate("searchDevices")}
              inputProps={{ "aria-label": "search" }}
              startAdornment={<Search sx={tableSearchIcon} />}
            />
          </Grid>
        ) : (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <InputBase
              onChange={(e) => handleSearch(e.target.value)}
              sx={tableSearch}
              placeholder={translate("searchDevices")}
              inputProps={{ "aria-label": "search" }}
              startAdornment={<Search sx={tableSearchIcon} />}
            />
          </Grid>
        )}
        {/* <Grid item lg={3} md={4} sm={4} xs={12}>
          <Button
            // onClick={() => handleOpenDialog()}
            disableRipple
            sx={tableSearchButton}
          >
            <Typography sx={deviceHeading} variant="deviceHeadingBoldButton">
              Duplicate{" "}
            </Typography>
          </Button>
        </Grid> */}

        {localStorage.getItem("permissions").includes("create device") ? (
          <Grid item lg={3} md={4} sm={4} xs={12}>
            <Button
              onClick={() => handleOpenDialog()}
              disableRipple
              sx={tableSearchButton}
            >
              <Typography sx={deviceHeading} variant="deviceHeadingBoldButton">
                {translate("createDevice")}
              </Typography>
            </Button>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
      <TableContainer component={Paper} elevation={0} sx={tableContainer}>
        <Table
          sx={{ overflowX: "auto" }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead sx={tableHeadColor}>
            <TableRow>
              <TableCell sx={tableHeadColor}>
                <Typography sx={deviceHeading} variant="deviceTableHeadingBold">
                  {translate("name")}
                </Typography>
              </TableCell>
              {loading ? null : deviceList.length < 1 ? (
                ""
              ) : (
                <>
                  <TableCell sx={tableHeadColor} align="left">
                    <Typography
                      sx={deviceHeading}
                      variant="deviceTableHeadingBold"
                    >
                      {translate("deviceType")}
                    </Typography>
                  </TableCell>
                  {localStorage
                    .getItem("permissions")
                    .includes("update device" || "delete device") ? (
                    <TableCell sx={tableHeadColor} align="right">
                      <Typography
                        sx={deviceHeading}
                        variant="deviceTableHeadingBold"
                      ></Typography>
                    </TableCell>
                  ) : (
                    ""
                  )}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <Box style={tableLoaderBox}>
                  <Loader />
                </Box>
              </TableRow>
            ) : deviceList.length < 1 ? (
              <TableRow>
                <Box style={tableLoaderBox}>
                  {" "}
                  <Typography sx={deviceHeading} variant="deviceTableCellName">
                    {translate("noDeviceAvailable")}
                  </Typography>
                </Box>
              </TableRow>
            ) : (
              filteredList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow hover key={row.id} sx={customStyledTableRow}>
                    <TableCell sx={tableAvatarCell} component="th" scope="row">
                      <Avatar sx={tableAvatar}>
                        {row?.name[0].toUpperCase()}
                      </Avatar>
                      <Typography
                        sx={deviceHeading}
                        variant="deviceTableCellName"
                      >
                        {row?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        sx={deviceHeading}
                        variant="deviceTableCellName"
                      >
                        {row?.deviceType?.name}
                      </Typography>
                    </TableCell>
                    {localStorage
                      .getItem("permissions")
                      .includes("update device" || "delete device") ? (
                      <TableCell align="right">
                        <Box sx={tableIconBox}>
                          {localStorage
                            .getItem("permissions")
                            .includes("update device") ? (
                            <IconButton
                              sx={tableIconButton}
                              onClick={() => {
                                setOpenEditDialog(true);
                                setRowDeviceDetail(row);
                              }}
                            >
                              <EditOutlined sx={{ color: "white" }} />
                            </IconButton>
                          ) : (
                            ""
                          )}
                          {localStorage
                            .getItem("permissions")
                            .includes("delete device") ? (
                            <IconButton
                              sx={tableIconButtonWithMargin}
                              onClick={() => {
                                setOpenDeleteDialog(true);
                                setRowDeviceDetail(row);
                              }}
                            >
                              <DeleteOutlineOutlined sx={{ color: "white" }} />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </Box>
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        filteredList={filteredList}
        setRowsPerPage={setRowsPerPage}
      />
      {openCreateDialog && (
        <CreateDeviceDialog
          rowDeviceDetail={rowDeviceDetail}
          deviceTypeList={deviceTypeList}
          openCreateDialog={openCreateDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      {openDeleteDialog && (
        <DeleteDeviceDialog
          rowDeviceDetail={rowDeviceDetail}
          openDeleteDialog={openDeleteDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      {openEditDialog && (
        <EditDeviceDialog
          rowDeviceDetail={rowDeviceDetail}
          deviceTypeList={deviceTypeList}
          openEditDialog={openEditDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </>
  );
};

export default Index;
