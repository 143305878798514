import React from "react";
import { Close } from "@mui/icons-material";
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

export const roomMainBox = {
  "&.MuiBox-root": {
    // margin: "10px",
    padding: "15px",
  },
};

export const roomSearchField = {
  "&.MuiInputBase-root": {
    width: "100%",
    height: "38px",
    border: "1px solid rgba(169, 169, 169, 0.6)",
    borderRadius: "10px",
    backgroundColor: "background.bgSearchBar",
    fontFamily: "Poppins",
  },
};

export const roomSearchFieldIcon = {
  "&.MuiSvgIcon-root": {
    color: "rgba(151, 151, 151, 0.68);",
    paddingLeft: "3px",
  },
};

export const roomCreateButton = {
  "&.MuiButton-root": {
    backgroundColor: "background.bgLayoutB",
    width: "100%",
    borderColor: "background.bgLayoutB",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "15px",
    color: "#FFFFFF",
    marginRight: "10px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "background.bgLayoutB",
      borderRadius: "10px",
    },
  },
};

export const roomTableContainer = {
  "&.MuiTableContainer-root": {
    borderRadius: "10px",
    fontFamily: "Poppins",
    marginTop: "30px",
  },
};

export const roomTableHead = {
  "&.MuiTableHead-root": {
    backgroundColor: "background.bgLayoutA",
    border: "#EC313B",
  },
};

export const roomTableHeadCell = {
  "&.MuiTableCell-root": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "20px",
    color: "#FFFFFF",
    textTransform: "capitalize",
  },
};

export const roomTableCell = {
  "&.MuiTableCell-root": {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#6D7B88",
  },
};

export const roomNameAvatar = {
  "&.MuiAvatar-root": {
    backgroundColor: "background.bgLayoutA",
    color: "white",
    height: "36px",
    width: "36px",
  },
};

export const editIconButtonsRoom = {
  "&.MuiButtonBase-root": {
    backgroundColor: "background.bgLayoutA",
    color: "white",
    height: "36px",
    width: "36px",
  },
};

export const cancelModalButtonsRoom = {
  "&.MuiButtonBase-root": {
    backgroundColor: "black",
    color: "white",
    height: "36px",
    textTransform: "capitalize",
    fontFamily: "Poppins-semibold",
    fontSize: "15px",
    borderRadius: "10px",
  },
};

export const deleteIconButtonsRoom = {
  "&.MuiButtonBase-root": {
    backgroundColor: "background.bgLayoutB",
    color: "white",
    height: "36px",
    width: "36px",
  },
};

// create room style
export const roomHeading = {
  "&.MuiTypography-roomTableHeadingBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-roomTableCell": {
    color: "white",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-roomTableCellName": {
    color: "white#6D7B88",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-autoCompleteTypo": {
    fontFamily: "Poppins",
    fontSize: "15px",
    color: "#7D8EA3",
    textTransform: "capitalize",
  },
};

export const roomCreateField = {
  "&.MuiFormControl-root": {
    width: "100%",
    marginBottom: "10px",
    "& .MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    "& .MuiInputBase-root": {
      // Add the "&" here to target the nested elements
      fontFamily: "Poppins",
      fontSize: "15px",
      color: "#7D8EA3",
      borderRadius: "10px",
      textTransform: "capitalize",
    },
    "& .Mui-focused fieldset": {
      // Add the "&" here to target the nested elements
      borderColor: "#7D8EA3",
    },
    "& label": { color: "#6D7B88" },
  },
};
export const helperTextStyle = {
  // Add the "&" here to target the nested elements
  "&.MuiTypography-root": {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "lightgrey",
    borderRadius: "10px",
    textTransform: "capitalize",
    m: 0,
    p: 0,
  },
};
export const styledChip = {
  fontFamily: "Poppins",
  fontSize: "14px",
  color: "white",
  backgroundColor: "background.bgTableChip",
  textTransform: "capitalize",
};

export const checkBoxLabel = {
  "&.MuiFormControlLabel-root": {
    ".MuiFormControlLabel-label": {
      fontFamily: "Poppins-semibold",
      fontStyle: "normal",
      fontSize: "16px",
      color: "#6D7B88",
      display: "flex",
    },
  },
};

export const roomTypographies = {
  "&.MuiTypography-inputOutputListHeading": {
    color: "black",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    // lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-inputOutputList": {
    display: "flex",
    flexDirection: "column",
    color: "#7D8EA3",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};
export const roomTypographiesStyle = {
  "&.MuiTypography-inputOutputListHeading": {
    color: "black",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    // lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-inputOutputList": {
    display: "flex",
    flexDirection: "column",
    color: "#7D8EA3",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const roomInputTypographies = {
  "&.MuiTypography-Heading": {
    color: "black",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    // lineHeight: "37.5px",
    textTransform: "capitalize",
  },
};

export const roomTypographiesSelect = {
  "&.MuiTypography-inputOutputListHeading": {
    color: "black",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    // lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-inputOutputList": {
    display: "flex",
    flexDirection: "column",
    color: "#7D8EA3",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const cancelRoomModalButton = {
  "&.MuiButtonBase-root": {
    borderRadius: "10px",
    backgroundColor: "black",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "15px",
    color: "white",
    textTransform: "capitalize",
  },
};

export const createRoomModalButton = {
  "&.MuiButtonBase-root": {
    backgroundColor: "#0054A4",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "15px",
    color: "white",
    borderRadius: "10px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#0054A0",
      borderRadius: "10px",
    },
    "&:disabled": {
      backgroundColor: "#979797;",
    },
  },
};

export const updateRoomModalButton = {
  "&.MuiButtonBase-root": {
    backgroundColor: "#0054A4",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "15px",
    color: "white",
    borderRadius: "10px",
    "&:hover": {
      background: "#0054A0",
      borderRadius: "10px",
    },
    "&:disabled": {
      backgroundColor: "#979797;",
    },
  },
};

export const deleteRoomModalDialogTitle = {
  "&.MuiDialogTitle-root": {
    backgroundColor: "#0054A4",
    color: "white",
    fontFamily: "Poppins-semibold",
    fontSize: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

export const deleteModalButtonRoom = {
  "&.MuiButtonBase-root": {
    backgroundColor: "background.bgLayoutB",
    color: "white",
    height: "36px",
    textTransform: "capitalize",
  },
};

export const qrCodeBoxStyle = { display: "flex", justifyContent: "flex-end" };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  // getContentAnchorEl: null,
  lable: {
    color: "red",
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

export const roomIcon = {
  padding: 10,
  maxWidth: "100%",
  height: "200px",
  objectFit: "contain",
};

export const roomTextfieldStyle = {
  input: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  label: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green",
  // },
  "& .MuiOutlinedInput-root": {
    marginBottom: "10px",
    "& fieldset": {
      fontFamily: "Poppins",
      borderColor: "#EDEDED",
      borderRadius: 3,
    },
    "& label": {
      // fontFamily: "Poppins",
      // borderColor: "#EDEDED",
    },
    "&:hover fieldset": {
      borderColor: "#7D8EA3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7D8EA3",
    },
  },
};

export const roomFormGroupStyle = { display: "flex", flexDirection: "row" };

export const roomCheckBoxLabel = {
  "&.MuiFormControlLabel-root": {
    ".MuiFormControlLabel-label": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.675px",
      color: "primary.fontColor",
      display: "flex",
    },
  },
};

export const roomTextfieldStyleInsideSection = {
  input: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  label: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green",
  // },
  "& .MuiOutlinedInput-root": {
    // marginBottom: "10px",
    "& fieldset": {
      fontFamily: "Poppins",
      borderColor: "#d5d5d5",
      borderRadius: 3,
    },
    "& label": {
      // fontFamily: "Poppins",
      // borderColor: "#EDEDED",
      color: "red",
    },
    "&:hover fieldset": {
      borderColor: "#7D8EA3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7D8EA3",
    },
  },
};

export const BootstrapRoomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function BootstrapRoomDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: "background.bgLayoutA",
        color: "white",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapRoomDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
