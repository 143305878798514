import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Container,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Select,
} from "@mui/material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  categoryHeading,
  categoryTypographies,
  MenuProps,
  textfieldStyle,
  dialogCancelButton,
  dropdownSelect,
} from "./muiCategoryComponentStyles";
import { useDispatch } from "../../redux/store";

import Loader from "../Loader/Loader";
import toasterContext from "../../utils/context/toasterContext";
import useLocales from "../../hooks/useLocales";
import { createCategory } from "../../redux/slices/CategorySlice";

const CreateCategoryDialog = ({
  //   deviceList,
  openCreateDialog,
  handleCloseDialog,
}) => {
  const { translate } = useLocales();
  const [categoryObj, setCategoryObj] = useState({});
  const [loader, setLoader] = useState(false);
  const fireToasterContext = useContext(toasterContext);
  const dispatch = useDispatch();

  useEffect(() => {
    //Cleaning up states each time dialog opens
    setCategoryObj({});
  }, [openCreateDialog]);

  const handleStateChange = (event) => {
    event.preventDefault();
    setCategoryObj({
      ...categoryObj,
      [event.target.name]: event.target.value,
    });
  };

  const { name, priority } = categoryObj;

  const handleCreateOutput = () => {
    setLoader(true);
    const newData = {
      name: name,
      priority: priority,
    };
    dispatch(createCategory(newData, handleApiRes));
  };

  const handleApiRes = (data) => {
    setLoader(false);

    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Category successfully created"
      );
      handleCloseDialog();
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  return (
    <Container>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openCreateDialog}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={categoryHeading} variant="categoryTableHeadingBold">
            {/* {translate("createOutput")} */}
            {translate("createCategory")}
          </Typography>
        </BootstrapDialogTitle>
        <ValidatorForm style={{ width: "100%" }} onSubmit={handleCreateOutput}>
          <DialogContent dividers>
            <TextValidator
              sx={textfieldStyle}
              fullWidth
              id="outlined-basic"
              name="name"
              label={translate("name")}
              variant="outlined"
              type="text"
              value={name}
              onChange={(e) => handleStateChange(e)}
              InputLabelProps={{
                style: { color: "#6D7B88" },
              }}
              validators={["required"]}
              errorMessages={["this field is required"]}
            />
            <FormControl sx={{ width: "100%" }} variant="standard">
              <InputLabel id="mutiple-select-label">
                {/* {translate("device")} */}
                {translate("priority")}
              </InputLabel>
              <Select
                labelId="mutiple-select-label"
                name="priority"
                sx={dropdownSelect}
                value={priority || ""}
                onChange={(e) => handleStateChange(e)}
                MenuProps={MenuProps}
              >
                {["High", "Medium", "Low"]?.map((option, index) => (
                  <MenuItem key={index} value={index + 1}>
                    <Typography sx={categoryTypographies} variant="List">
                      {option}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              disableRipple
              sx={dialogCancelButton}
              autoFocus
              onClick={handleCloseDialog}
            >
              <Typography
                sx={categoryHeading}
                variant="categoryHeadingBoldButton"
              >
                {translate("cancel")}
              </Typography>
            </Button>
            <Button
              disabled={!name || !priority}
              disableRipple
              type="submit"
              sx={{
                backgroundColor: "background.bgLayoutA",
                borderRadius: "10px",
                "&:hover": { backgroundColor: "background.bgLayoutA" },
                "&:disabled": {
                  backgroundColor: "#979797",
                },
              }}
            >
              <Typography
                sx={categoryHeading}
                variant="categoryHeadingBoldButton"
              >
                {/* {loader ? <Loader /> : translate("createOutput")} */}
                {loader ? <Loader /> : translate("createCategory")}
              </Typography>
            </Button>
          </DialogActions>
        </ValidatorForm>
      </BootstrapDialog>
    </Container>
  );
};

export default CreateCategoryDialog;
