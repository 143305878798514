import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../redux/store";
import { allLangs } from "../Config";

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const { languageList } = useSelector((state) => state.languageReducer);

  const langStorage = localStorage.getItem("i18nextLng");
  const [currentLang, setCurrentLang] = useState("");

  useEffect(() => {
    // Ensure that the React DOM is ready
    const newLang = languageList.find((_lang) => _lang.value === langStorage);

    setCurrentLang(newLang);
  }, [languageList, langStorage]);

  const handleChangeLanguage = (newLang) => {
    i18n.changeLanguage(newLang);
  };

  // Return null or default values until the React DOM is ready
  if (!currentLang) {
    return {
      onChangeLang: handleChangeLanguage,
      translate: (text, options) => translate(text, options),
      currentLang: null, // or another default value
      allLangs,
    };
  }

  // Return the actual values once the React DOM is ready
  return {
    onChangeLang: handleChangeLanguage,
    translate: (text, options) => translate(text, options),
    currentLang,
    allLangs,
  };
}
