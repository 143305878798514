import React, { useContext, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  // Fab,
  Grid,
  Paper,
  Table,
  Avatar,
  Button,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  InputBase,
  Typography,
  TableContainer,
} from "@mui/material";
import Loader from "../Loader/Loader";
import { DeleteOutline, EditOutlined, Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "../../redux/store";
import { getUsersList } from "../../redux/slices/UserSlice";
import CustomPagination from "../../hooks/useTablePagination";
import {
  userHeading,
  tableLoaderBox,
  // tableIconButtonAdd,
} from "./muiUserComponentStyles";

import useLocales from "../../hooks/useLocales";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteUserDialog from "./DeleteUserDialog";
import { PageContext } from "../../utils/context/PageContext";
// import SellOutlinedIcon from "@mui/icons-material/SellOutlined";

const StyledTableRow = styled(TableRow)((props) => ({
  "&:nth-of-type(odd)": {
    background: props.theme.palette.background.bgTableOddRow,
  },
  "&:nth-of-type(even)": {
    background: props.theme.palette.background.bgTableEvenRow,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.MuiTableRow-root:hover": {
    background: props.theme.palette.background.bgTableRowHover,
  },
}));

const Index = () => {
  const navigate = useNavigate();

  const { page, setPage, updateActiveRoute } = useContext(PageContext);
  const location = useLocation();

  const inputRef = useRef("");
  const { translate } = useLocales();

  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedDeleteUser, setSelectedDeleteUser] = useState(false);

  const [filteredList, setFilteredList] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsersList(handleApiRes));
    updateActiveRoute(location.pathname);
  }, [location]);

  let { usersList } = useSelector((state) => state.userReducer);
  const handleApiRes = () => {
    setLoading(false);
  };

  useEffect(() => {
    //if search has some value in it
    const searchValue = inputRef?.current?.value;
    if (searchValue?.length >= 1) {
      handleSearch(searchValue);
    } else {
      setFilteredList(usersList);
    }
  }, [usersList]);

  const handleEditUser = (data) => {
    navigate("/all-users/update-user-details", {
      state: { selectedUser: data },
    });
  };

  // const handleAccessRules = (row) => {
  //   navigate("/all-users/access-rules", {
  //     state: { selectedUser: row },
  //   });
  // };

  const handleSearch = (value) => {
    //setting table page number 1
    setPage(0);
    let filterArray = [];
    const toBeSearched = value.toString().toLowerCase();
    usersList.map((item) => {
      const firstName = item.firstName?.toString().toLowerCase();
      const lastName = item.lastName?.toString().toLowerCase();
      if (
        firstName?.includes(toBeSearched) ||
        lastName?.includes(toBeSearched)
      ) {
        filterArray.push(item);
      }
    });
    setFilteredList(filterArray);
  };

  const handleChangePage = (event, newPage) => {
    // setPage(newPage - 1);
    // localStorage.setItem("page", JSON.stringify(newPage - 1));
    setPage(newPage - 1);
  };

  const handleDeleteUser = (row) => {
    setSelectedDeleteUser(row);
    setOpenDeleteModal(true);
  };

  const handleCloseDialog = () => {
    setSelectedDeleteUser(null);
    setOpenDeleteModal(false);
  };

  return (
    <>
      <Grid spacing={2} container>
        <Grid item lg={9} md={8} sm={8} xs={12}>
          <InputBase
            type="text"
            inputRef={inputRef}
            onChange={(e) => handleSearch(e.target.value)}
            sx={{
              width: "100%",
              height: "38px",
              border: "1px solid rgba(169, 169, 169, 0.6)",
              borderRadius: 2,
              backgroundColor: "background.bgSearchBar",
              fontFamily: "Poppins",
            }}
            placeholder={translate("searchUser")}
            inputProps={{ "aria-label": "search" }}
            startAdornment={
              <Search
                sx={{
                  color: "rgba(151, 151, 151, 0.68);",
                  paddingLeft: "3px",
                }}
              />
            }
          />
        </Grid>
        <Grid item lg={3} md={4} sm={4} xs={12}>
          <Button
            onClick={() => navigate("/all-users/add-user")}
            disableRipple
            sx={{
              backgroundColor: "background.bgLayoutB",
              width: "100%",
              borderRadius: "10px",
              "&:hover": { backgroundColor: "background.bgLayoutB" },
            }}
          >
            <Typography sx={userHeading} variant="userHeadingBoldButton">
              {translate("createUser")}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          marginTop: "30px",
          borderRadius: "10px",
          [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ backgroundColor: "background.bgLayoutA" }}>
            <TableRow>
              <TableCell
                sx={{ backgroundColor: "background.bgLayoutA", width: "50%" }}
              >
                <Typography sx={userHeading} variant="userTableHeadingBold">
                  {translate("name")}
                </Typography>
              </TableCell>
              {loading ? null : usersList?.length < 1 ? (
                ""
              ) : (
                <>
                  <TableCell sx={{ backgroundColor: "background.bgLayoutA" }}>
                    <Typography sx={userHeading} variant="userTableHeadingBold">
                      {translate("roles")}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ backgroundColor: "background.bgLayoutA" }}
                    align="right"
                  >
                    <Typography sx={userHeading} variant="userTableHeadingBold">
                      {translate("status")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    width={"50%"}
                    sx={{ backgroundColor: "background.bgLayoutA" }}
                    align="right"
                  >
                    <Typography
                      sx={userHeading}
                      variant="userTableHeadingBold"
                    ></Typography>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <Box style={tableLoaderBox}>
                  <Loader />
                </Box>
              </TableRow>
            ) : usersList?.length < 1 ? (
              <TableRow>
                <Box style={tableLoaderBox}>
                  <Typography sx={userHeading} variant="userTableCellName">
                    {translate("noUserAvailable")}
                  </Typography>
                </Box>
              </TableRow>
            ) : (
              filteredList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow
                    hover
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{
                        padding: "18px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: "background.bgLayoutA",
                            color: "white",
                            marginRight: "10px",
                          }}
                        >
                          {row?.firstName[0].toUpperCase()}
                        </Avatar>
                        <Typography
                          sx={userHeading}
                          variant="userTableCellName"
                        >
                          {row?.firstName} {row?.lastName}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ width: "50%" }}>
                      {row?.roles.map((role, idx) => (
                        <Box
                          key={idx}
                          sx={{
                            margin: "2px",
                            color: "white",
                            fontSize: "12px",
                            cursor: "default",
                            padding: "4px 12px",
                            borderRadius: "50px",
                            fontFamily: "Poppins",
                            display: "inline-block",
                            textTransform: "capitalize",
                            backgroundColor: "background.bgTableChip",
                          }}
                        >
                          {role.name}
                        </Box>
                      ))}
                    </TableCell>
                    <TableCell align="right">
                      <Typography sx={userHeading} variant="userTableCellName">
                        {row?.active == true ? "Active" : "InActive"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Box sx={{ display: "flex", justifyContent: "end" }}>
                        {/* <IconButton
                          sx={tableIconButtonAdd}
                          onClick={() => {
                            handleAccessRules(row);
                          }}
                        >
                          <SellOutlinedIcon sx={{ color: "white" }} />
                        </IconButton> */}
                        <IconButton
                          sx={{
                            ml: 1,
                            backgroundColor: "background.bgLayoutA",
                            "&:hover": {
                              backgroundColor: "background.bgLayoutA",
                            },
                          }}
                          onClick={() => {
                            handleEditUser(row);
                          }}
                        >
                          <EditOutlined sx={{ color: "white" }} />
                        </IconButton>
                        <IconButton
                          sx={{
                            ml: 1,
                            backgroundColor: "background.bgLayoutB",
                            "&:hover": {
                              backgroundColor: "background.bgLayoutB",
                            },
                          }}
                          onClick={() => {
                            handleDeleteUser(row);
                          }}
                        >
                          <DeleteOutline sx={{ color: "white" }} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        filteredList={filteredList}
        setRowsPerPage={setRowsPerPage}
      />
      {openDeleteModal && (
        <DeleteUserDialog
          userDetail={selectedDeleteUser}
          userDialog={openDeleteModal}
          handleCloseDialog={handleCloseDialog}
          filteredList={filteredList}
          setFilteredList={setFilteredList}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
        />
      )}
    </>
  );
};

export default Index;
