import React from "react";
import PropTypes from "prop-types";
import { DialogTitle, Dialog, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const ITEM_HEIGHT = 58;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  // getContentAnchorEl: null,
  lable: {
    color: "red",
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, backgroundColor: "background.bgLayoutA" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const autoCompleteStyle = {
  "&.MuiAutocomplete-root ": {
    ".MuiFormLabel-root": {
      fontFamily: "Poppins-SemiBold",
    },
    ".MuiFormControl-root": {
      //   border: "2px solid red",
    },
    "&:hover fieldset": {
      borderColor: "#bcbfc1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#bcbfc1",
    },
  },
};

export const demoReadySubmitButton = {
  "&.MuiButton-root": {
    width: "100%",
    maxWidth: 200,
    height: "55px",
    backgroundColor: "background.bgLayoutB",
    border: "background.bgLayoutA",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "15px",
    color: "#FFFFFF",
    textTransform: "capitalize",
    "&:hover": {
      background: "background.bgLayoutB",
      borderRadius: "10px",
    },
    "&:disabled": {
      backgroundColor: "#979797;",
    },
  },
};

export const demoReadyCancelButton = {
  "&.MuiButton-root": {
    width: "100%",
    maxWidth: 200,
    height: "55px",
    backgroundColor: "black",
    border: "black",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "15px",
    color: "#FFFFFF",
    textTransform: "capitalize",
    "&:hover": {
      background: "black",
      borderRadius: "10px",
    },
    "&:disabled": {
      backgroundColor: "#979797;",
    },
  },
};

export const demoReadyDialogButton = {
  width: "100%",
  height: "55px",
  color: "white",
  borderRadius: "10px",
  textTransform: "capitalize",
  fontFamily: "Poppins-SemiBold",
  backgroundColor: "background.bgLayoutB",
  "&:hover": { backgroundColor: "background.bgLayoutB" },
};

export const demoReadyDeleteLogButton = {
  width: "100%",
  maxWidth: 200,
  height: "45px",
  color: "white",
  borderRadius: "10px",
  textTransform: "capitalize",
  fontFamily: "Poppins-SemiBold",
  backgroundColor: "background.bgLayoutB",
  "&:hover": { backgroundColor: "background.bgLayoutB" },
};

export const demoReadyGridTableContainer = {
  "&.MuiTableContainer-root": {
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    fontFamily: "Poppins",
    // marginTop: "30px",
  },
};
export const demoReadyTableContainer = {
  "&.MuiTableContainer-root": {
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    fontFamily: "Poppins",
    marginTop: "30px",
  },
};

export const logTableContainer = {
  "&.MuiTableContainer-root": {
    borderRadius: "10px",
    fontFamily: "Poppins",
    [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
    // minWidth: 550,
  },
};

export const logsTableHeadCell = {
  "&.MuiTableCell-root": {
    fontFamily: "Poppins-semibold",
    fontSize: "16px",
  },
};

export const logsTableBodyCell = {
  "&.MuiTableCell-root": {
    fontFamily: "Poppins",
    fontSize: "15px",
    minWidth: 200,
    color: "primary.tableFontColor",
  },
};

export const tableLoaderBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const demoReadyTableHead = {
  "&.MuiTableHead-root": {
    border: "#EC313B",
    backgroundColor: "background.bgLayoutA",
  },
};
export const demoReadyListTypography = {
  "&.MuiTypography-root": {
    color: "primary.FONT_COLOR_LIGHT",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-Heading": {
    color: "black",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const demoReadyTableHeadCell = {
  "&.MuiTableCell-root": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "15px",
    color: "#FFFFFF",
    textTransform: "capitalize",
  },
};

export const demoReadyTableCell = {
  "&.MuiTableCell-root": {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#6D7B88",
  },
};
export const textfieldStyle = {
  width: "100%",
  input: {
    color: "color.FONT_COLOR_LIGHT",
    borderRadius: "10px",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  label: {
    color: "color.FONT_COLOR_LIGHT",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green",
  // },
  "& .MuiOutlinedInput-root": {
    // marginBottom: "10px",
    color: "color.FONT_COLOR_LIGHT",
    backgroundColor: "background.bgSearchBar",
    borderRadius: "10px",
    "& fieldset": {
      fontFamily: "Poppins",
      borderColor: "#EDEDED",
      borderRadius: 3,
    },
    "& label": {
      // fontFamily: "Poppins",
      // borderColor: "#EDEDED",
    },
    "&:hover fieldset": {
      borderColor: "#7D8EA3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7D8EA3",
    },
  },
};

export const listingBox = {
  width: "100%",
  mt: 3,
  backgroundColor: "background.bgTableOddRow",
  borderRadius: 2,
  padding: 2,
};

export const roleTypography = {
  "&.MuiTypography-inputOutputListHeading": {
    color: "black",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    // lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-inputOutputList": {
    color: "primary.fontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const demoReadyTypography = {
  "&.MuiTypography-demoReadyHeadingBoldButton": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-demoReadyTableHeadingBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "20px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-demoReadyTableCellName": {
    color: "primary.tableFontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const dialogCancelButton = {
  background: "black",
  borderRadius: "10px",
  "&:hover": { backgroundColor: "black" },
};
export const dialogDeleteButton = {
  backgroundColor: "background.bgLayoutB",
  borderRadius: "10px",
  "&:hover": { backgroundColor: "background.bgLayoutB" },
};
