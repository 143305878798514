import React, { useState, useEffect, useRef, useContext } from "react";
import {
  // Avatar,
  Box,
  // Card,
  // CardContent,
  // CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Grid,
  InputBase,
  Button,
} from "@mui/material";
// import { cardHeader, cardHeaderTypography } from "./AlarmStyle";
import {
  getAlarmsList,
  getAlarmsProcedureList,
} from "../../redux/slices/AlarmSlice";
import { useDispatch, useSelector } from "../../redux/store";
import Loader from "../Loader/Loader";
import CustomPagination from "../../hooks/useTablePagination";
import CreateAlarmDialog from "./CreateAlarmDialog";
// language
import useLocales from "../../hooks/useLocales";
import {
  MenuProps,
  alarmTypographies,
  textfield,
} from "./muiAlarmComponentStyles";
import AlarmRow from "./AlarmRow";
import {
  roomCreateButton,
  roomSearchField,
  roomSearchFieldIcon,
  roomTableContainer,
  roomTableHead,
  roomTableHeadCell,
} from "../room/RoomStyle";
import { ArrowDownward, ArrowUpward, Search } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { PageContext } from "../../utils/context/PageContext";

const sortOptions = [
  {
    label: "Room Up",
    value: "roomUp",
    icon: <ArrowUpward sx={{ color: "primary.fontColor", fontSize: 18 }} />,
  },
  {
    label: "Room Down",
    value: "roomDown",
    icon: <ArrowDownward sx={{ color: "primary.fontColor", fontSize: 18 }} />,
  },
  {
    label: "Date Up",
    value: "dateUp",
    icon: <ArrowUpward sx={{ color: "primary.fontColor", fontSize: 18 }} />,
  },
  {
    label: "Date Down",
    value: "dateDown",
    icon: <ArrowDownward sx={{ color: "primary.fontColor", fontSize: 18 }} />,
  },
  {
    label: "Severity Up",
    value: "severityUp",
    icon: <ArrowUpward sx={{ color: "primary.fontColor", fontSize: 18 }} />,
  },
  {
    label: "Severity Down",
    value: "severityDown",
    icon: <ArrowDownward sx={{ color: "primary.fontColor", fontSize: 18 }} />,
  },
];

const Index = ({ socket }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const inputRef = useRef("");
  const { page, setPage, updateActiveRoute } = useContext(PageContext);
  const location = useLocation();

  const [loader, setLoader] = useState(true);
  const [sortedBy, setSortedBy] = useState("roomUp");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [alarmObjList, setAlarmObjList] = useState(null);
  const [detailAlarmID, setDetailAlarmID] = useState(null);
  const [openProcedureAlarm, setOpenProcedureAlarm] = useState(null);
  // let userDetails = JSON.parse(localStorage.getItem("user"));
  const [alarmsProcedureData, setAlarmsProcedureData] = React.useState([]);
  const [openAlarmsProcedureLoader, setOpenAlarmsProcedureLoader] =
    React.useState(true);
  // const [openDialog, setOpenDialog] = React.useState(false);

  const handleAlarmResponse = () => {
    setLoader(false);
    if (detailAlarmID !== null) dispatch(getAlarmsProcedureList(detailAlarmID));
  };

  useEffect(() => {
    dispatch(getAlarmsList(handleAlarmResponse));
    updateActiveRoute(location.pathname);
  }, [location]);

  const { alarmsList } = useSelector((state) => state.alarmReducer);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  useEffect(() => {
    setAlarmObjList(alarmsList);
    if (detailAlarmID !== null) {
      dispatch(
        getAlarmsProcedureList(detailAlarmID, handleGetAlarmsProcedureResponse)
      );
    }
  }, [alarmsList]);

  const handleSortBy = (e) => {
    const selectedSort = e.target.value;

    setSortedBy(selectedSort);

    let sortedList = [...alarmObjList];

    const compareRoomNames = (roomA, roomB) => {
      const getRoomNumber = (room) => {
        // Extract the number from the room name (assumes "Room X" format)
        const match = room?.name.match(/\d+/);
        return match ? parseInt(match[0], 10) : 0;
      };

      const roomNumberA = getRoomNumber(roomA);
      const roomNumberB = getRoomNumber(roomB);

      return roomNumberA - roomNumberB;
    };

    // Determine sorting logic based on selected option
    switch (selectedSort) {
      case "roomUp":
        sortedList.sort((a, b) => compareRoomNames(a, b)); // Ascending order by room name
        break;
      case "roomDown":
        sortedList.sort((a, b) => compareRoomNames(b, a)); // Descending order by room name
        break;
      case "dateUp":
        sortedList.sort(
          (a, b) =>
            new Date(a.alarm && a.alarm.createdAt) -
            new Date(b.alarm && b.alarm.createdAt)
        ); // Ascending order by date
        break;
      case "dateDown":
        sortedList.sort(
          (a, b) =>
            new Date(b.alarm && b.alarm.createdAt) -
            new Date(a.alarm && a.alarm.createdAt)
        ); // Descending order by date
        break;
      case "severityUp":
        sortedList.sort(
          (a, b) =>
            ((a.alarm && a?.alarm.severity) || 0) -
            ((b.alarm && b?.alarm.severity) || 0)
        ); // Ascending order by severity
        break;
      case "severityDown":
        sortedList.sort(
          (a, b) =>
            ((b.alarm && b?.alarm.severity) || 0) -
            ((a.alarm && a?.alarm.severity) || 0)
        ); // Descending order by severity
        break;
      default:
        sortedList = alarmsList;
    }

    // Always prioritize rooms with active alarms at the top
    sortedList = sortedList.sort((room1, room2) => {
      const isAlarmRaised1 = room1?.alarm?.status === "Active";
      const isAlarmRaised2 = room2?.alarm?.status === "Active";

      if (isAlarmRaised1 && !isAlarmRaised2) {
        return -1; // room1 comes first
      } else if (!isAlarmRaised1 && isAlarmRaised2) {
        return 1; // room2 comes first
      } else {
        return 0; // keep the same order if neither or both have active alarms
      }
    });

    setAlarmObjList(sortedList);
  };

  const handleGetAlarmsProcedureResponse = (res) => {
    setOpenAlarmsProcedureLoader(false);
    if (res.procedureConfigurations) {
      setAlarmsProcedureData(res.procedureConfigurations);
    }
  };

  const handleSearch = (value) => {
    //setting table page number 1
    setPage(0);
    let filterArray = [];
    const toBeSearched = value.toString().toLowerCase();
    alarmsList.map((item) => {
      const name = item.name.toString().toLowerCase();
      if (name?.includes(toBeSearched)) {
        filterArray.push(item);
      }
    });
    setAlarmObjList(filterArray);
  };

  const handleCreateRoomModal = () => {
    navigate("/rooms/create-room");
  };

  return (
    <>
      {false && (
        <CreateAlarmDialog
          openDialog={false}
          handleCloseDialog={() => {}}
          socket={socket}
        />
      )}
      <Box style={{ margin: "5px" }}>
        {/* For generate Alarm Button */}
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "30px",
          }}
        >
          {localStorage.getItem("permissions").includes("generate alarm") ? (
            <Button
              type="submit"
              variant="outlined"
              onClick={handleOpenDialog}
              sx={generateAlarmButton}
            >
              {translate("generateAlarm")}
            </Button>
          ) : (
            ""
          )}
        </Box> */}
        {/* <Card style={{ borderRadius: "10px" }}>
            <CardHeader
              sx={cardHeader}
              title={
                <>
                  <Box
                    sx={{
                      background: "red",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={cardHeaderTypography}>
                      {translate("rooms")}
                    </Typography>
                    <Typography sx={roomTableHeadCell}>
                      {translate("inputs")}
                    </Typography>
                    <Typography sx={roomTableHeadCell}>
                      {translate("outputs")}
                    </Typography>
                  </Box>
                </>
              }
              action={
                <Box sx={{ minWidth: 120 }}>
                  <FormControl sx={textfield} fullWidth>
                    <InputLabel>{translate("sortBy")}</InputLabel>
                    <Select
                      // name="roomId"
                      value={sortedBy || ""}
                      MenuProps={MenuProps}
                      sx={{ width: "100%" }}
                      label={translate("sortBy")}
                      InputLabelProps={{
                        sx: { color: "primary.fontColor" },
                        shrink: true,
                      }}
                      inputProps={{
                        sx: { fontFamily: "Poppins", color: "primary.fontColor" },
                      }}
                      onChange={(e) => handleSortBy(e)}
                    >
                      {["date", "severity"].map((e, index) => (
                        <MenuItem key={index} value={e}>
                          <Typography
                            sx={alarmTypographies}
                            variant="inputOutputList"
                            key={index}
                          >
                            {translate(e)}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              }
            /> */}
        {/* <CardContent style={{ marginTop: "30px" }}>
            <Card
              sx={{
                border: "1px solid #E9E9E9",
                borderRadius: "20px",
              }}
            > */}
        {/* search field grid */}
        <Grid container spacing={2}>
          {localStorage?.getItem("permissions")?.includes("create room") ? (
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <InputBase
                type="text"
                inputRef={inputRef}
                onChange={(e) => handleSearch(e.target.value)}
                sx={roomSearchField}
                placeholder={translate("searchRoom")}
                inputProps={{ "aria-label": "search" }}
                startAdornment={<Search sx={roomSearchFieldIcon} />}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <InputBase
                type="text"
                inputRef={inputRef}
                onChange={(e) => handleSearch(e.target.value)}
                sx={roomSearchField}
                placeholder={translate("searchRoom")}
                inputProps={{ "aria-label": "search" }}
                startAdornment={<Search sx={roomSearchFieldIcon} />}
              />
            </Grid>
          )}
          {/* <Grid item xs={12} sm={12} md={3} lg={3}>
            <Button
              type="submit"
              variant="outlined"
              // onClick={handleCreateRoomModal}
              sx={roomCreateButton}
            >
              Duplilcate{" "}
            </Button>
          </Grid> */}

          {localStorage?.getItem("permissions")?.includes("create room") ? (
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Button
                type="submit"
                variant="outlined"
                onClick={handleCreateRoomModal}
                sx={roomCreateButton}
              >
                {translate("createRoom")}
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <TableContainer sx={roomTableContainer} component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead sx={roomTableHead}>
              <TableRow>
                <TableCell sx={roomTableHeadCell}>
                  {translate("name")}
                </TableCell>
                {loader ? null : alarmObjList.length < 1 ? (
                  ""
                ) : (
                  <>
                    <TableCell sx={roomTableHeadCell}>
                      {translate("inputs")}
                    </TableCell>
                    {/* <TableCell sx={roomTableHeadCell}>
                      {translate("outputs")}
                    </TableCell> */}

                    <TableCell sx={roomTableHeadCell}>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl
                          sx={{
                            ...textfield,
                            ".Mui-Focus": {
                              color: "white",
                            },
                          }}
                          fullWidth
                        >
                          <InputLabel>{translate("sortBy")}</InputLabel>
                          <Select
                            // name="roomId"
                            value={sortedBy || ""}
                            MenuProps={MenuProps}
                            sx={{
                              width: "100%",
                              "&.Mui-focused": {
                                color: "white",
                              },
                            }}
                            label={translate("sortBy")}
                            InputLabelProps={{
                              // sx: { color: "primary.fontColor" },
                              shrink: true,
                            }}
                            inputProps={{
                              sx: {
                                fontFamily: "Poppins",
                                display: "flex",
                                flexDirection: "row",
                              },
                            }}
                            onChange={(e) => handleSortBy(e)}
                          >
                            {sortOptions.map((e, index) => (
                              <MenuItem
                                key={index}
                                value={e.value}
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                <Typography
                                  sx={alarmTypographies}
                                  variant="inputOutputList"
                                  key={index}
                                >
                                  {translate(e.value)}
                                </Typography>
                                {e.icon}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                backgroundColor: "background.bgTableOddRow",
                border: "none !important",
              }}
            >
              {loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader />
                </div>
              ) : alarmObjList.length < 1 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Poppins",
                  }}
                >
                  {translate("noRoom")}
                </div>
              ) : (
                (rowsPerPage > 0
                  ? alarmObjList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : alarmObjList
                ).map((alarm, index) => (
                  // <Row
                  //   key={index}
                  //   alarm={alarm}
                  //   sx={{
                  //     backgroundColor: index % 2 ? "white" : "#f5f5f5",
                  //   }}
                  // />
                  <AlarmRow
                    key={index}
                    index={index}
                    socket={socket}
                    alarm={alarm}
                    setDetailAlarmID={setDetailAlarmID}
                    openProcedureAlarm={openProcedureAlarm}
                    setOpenProcedureAlarm={setOpenProcedureAlarm}
                    alarmsProcedureData={alarmsProcedureData}
                    setAlarmsProcedureData={setAlarmsProcedureData}
                    openAlarmsProcedureLoader={openAlarmsProcedureLoader}
                    setOpenAlarmsProcedureLoader={setOpenAlarmsProcedureLoader}
                    handleGetAlarmsProcedureResponse={
                      handleGetAlarmsProcedureResponse
                    }
                    sx={{
                      backgroundColor: index % 2 ? "white" : "#f5f5f5",
                    }}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* </Card> */}
        <CustomPagination
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          filteredList={alarmObjList || []}
          setRowsPerPage={setRowsPerPage}
        />
        {/* </CardContent>
        </Card> */}
      </Box>
    </>
  );
};

export default Index;
