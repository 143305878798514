import {
  Container,
  Typography,
  DialogActions,
  Button,
  DialogContent,
} from "@mui/material";
import React, { useContext, useState } from "react";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  cancelButton,
  procedureHeading,
} from "./muiProcedureComponentStyles";
import { useDispatch } from "../../redux/store";
import { deleteProcedure } from "../../redux/slices/procedureSlice";
import useLocales from "../../hooks/useLocales";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
import toasterContext from "../../utils/context/toasterContext";

const DeleteProcedureDialog = ({
  procedureDetail,
  deleteDialog,
  handleCloseDialog,
}) => {
  const { translate } = useLocales();
  const fireToasterContext = useContext(toasterContext);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteProcedure = () => {
    setLoader(true);
    dispatch(deleteProcedure(procedureDetail.id, handleApiRes));
  };

  const handleApiRes = (data) => {
    setLoader(false);
    if (data.status) {
      handleCloseDialog();
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "Event Deleted Successfully"
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data.response.data.message
          ? data.response.data.message
          : "Something went wrong"
      );
      handleCloseDialog();
    }
  };

  return (
    <Container>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={deleteDialog}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={procedureHeading} variant="procedureTableHeadingBold">
            {translate("deleteProcedure")}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography sx={procedureHeading} variant="procedureTableCellName">
            {translate(`areYouSure`)} {procedureDetail?.name}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            sx={cancelButton}
            autoFocus
            onClick={handleCloseDialog}
          >
            <Typography
              sx={procedureHeading}
              variant="procedureTableHeadingBold_Button"
            >
              {translate("cancel")}
            </Typography>
          </Button>
          <Button
            onClick={() => handleDeleteProcedure()}
            disableRipple
            sx={
              loader
                ? {
                    background: "#979797",
                    borderRadius: "10px",
                    "&:hover": { backgroundColor: "#979797" },
                  }
                : {
                    background: "#EC313B",
                    borderRadius: "10px",
                    "&:hover": { backgroundColor: "#EC313B" },
                  }
            }
          >
            <Typography
              sx={procedureHeading}
              variant="procedureTableHeadingBold_Button"
            >
              {translate("deleteProcedure")}
            </Typography>
            {loader ? <CircularProgressLoader /> : ""}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Container>
  );
};

export default DeleteProcedureDialog;
