import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  roleHeading,
  roleTypography,
  textfield,
} from "./muiRoleComponentStyles";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControlLabel,
  Paper,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "../../redux/store";
import { updateRole } from "../../redux/slices/RoleSlice";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import useLocales from "../../hooks/useLocales";
import toasterContext from "../../utils/context/toasterContext";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
// import { getProfilesList } from "../../redux/slices/profileSlice";
import { getUsersList } from "../../redux/slices/UserSlice";
import { styledChip } from "../room/RoomStyle";
import { textfieldStyle } from "../input/muiInputComponentStyles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, backgroundColor: "background.bgLayoutA" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const EditDialog = ({ roleDetail, editDialog, handleCloseDialog }) => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const fireToasterContext = useContext(toasterContext);
  const [loader, setLoader] = useState(false);
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  // const [profileIds, setProfileIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [roleObj, setRoleObj] = useState({
    roleId: null,
    name: "",
    description: "",
  });

  // const { profilesList } = useSelector((state) => state.profileReducer);
  const { usersList } = useSelector((state) => state.userReducer);

  // const isAllProfiles =
  //   profilesList.length > 0 &&
  //   profileIds.length === Object.keys(profilesList).length;

  useEffect(() => {
    // dispatch(getProfilesList());
    dispatch(getUsersList());
  }, []);

  useEffect(() => {
    //Cleaning up states each time dialog opens

    setRoleObj({
      roleId: roleDetail?.id ? roleDetail?.id : null,
      name: roleDetail?.name ? roleDetail?.name : "",
      description: roleDetail?.description ? roleDetail?.description : "",
    });
    // setProfileIds(roleDetail?.profiles?.map((profile) => profile.id));
    setUserIds(roleDetail?.users?.filter((user) => user.id));
  }, [roleDetail]);

  const handleUpdateRole = () => {
    let finalUserId = userIds.map((el) => el.id);
    setLoader(true);
    const finalObject = {
      ...roleObj,
      // profileIds: profileIds,
      userIds: finalUserId,
    };
    dispatch(updateRole(finalObject, handleApiRes));
  };

  const handleApiRes = (data) => {
    setLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Role Updated"
      );
      handleCloseDialog();
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleToggleSelectAlluser = () => {
    setSelectAllUsers((prev) => {
      if (!prev) setUserIds([...usersList]);
      else setUserIds([]);
      return !prev;
    });
  };

  const handleChangeRoleData = (event) => {
    let roleObjTemp = { ...roleObj };
    roleObjTemp[event.target.name] = event.target.value;
    setRoleObj(roleObjTemp);
  };

  // const handleSelectProfiles = (event) => {
  //   const value = event.target.value;
  //   if (value[value.length - 1] === "allProfiles") {
  //     setProfileIds(
  //       profileIds.length === profilesList.length
  //         ? []
  //         : profilesList.map((i) => i.id)
  //     );
  //     return;
  //   }
  //   setProfileIds(value);
  // };

  return (
    <Container>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={editDialog}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={roleHeading} variant="roleTableHeadingBold">
            {translate("editRole")}
          </Typography>
        </BootstrapDialogTitle>
        <ValidatorForm
          style={{ width: "100%" }}
          onSubmit={handleUpdateRole}
          // onError={(errors) => console.log(errors)}
        >
          <DialogContent dividers>
            <TextValidator
              sx={textfield}
              fullWidth
              id="outlined-basic"
              label={translate("name")}
              name="name"
              variant="outlined"
              type="text"
              value={roleObj?.name}
              onChange={handleChangeRoleData}
            />

            {/* roles grid array */}
            {/* <FormControl sx={{ width: "100%" }} variant="standard">
              <InputLabel shrink={false} id="mutiple-select-label">
                {translate("profiles")}
              </InputLabel>
              <Select
                labelId="mutiple-select-label"
                multiple
                sx={{
                  mb: "10px",
                  borderRadius: 3,
                  color: "#9B9B9B",
                  textAlign: "left",
                  ".MuiSvgIcon-root ": {
                    fill: "#9B9B9B",
                  },
                  ":before": { borderBottomColor: "#9B9B9B", opacity: "48%" },
                  ":after": { borderBottomColor: "#9B9B9B", opacity: "48%" },
                  "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before":
                    {
                      borderBottom: `2px solid #9B9B9B`,
                      opacity: "48%",
                    },
                }}
                value={profileIds}
                onChange={handleSelectProfiles}
                renderValue={() => {}}
                MenuProps={MenuProps}
              >
                <MenuItem
                  value="allProfiles"
                  sx={{
                    background: isAllProfiles ? "rgba(25, 118, 210, 0.08)" : "",
                  }}
                >
                  <ListItemIcon>
                    <Checkbox checked={isAllProfiles} />
                  </ListItemIcon>
                  <Typography sx={profileTypography} variant="inputOutputList">
                    {translate("selectAll")}
                  </Typography>
                </MenuItem>
                {profilesList.map((option) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    <ListItemIcon>
                      <Checkbox checked={profileIds.indexOf(option.id) > -1} />
                    </ListItemIcon>
                    <Typography
                      sx={profileTypography}
                      variant="inputOutputList"
                    >
                      {option?.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box
              sx={{
                backgroundColor: "background.bgTableOddRow",
                borderRadius: 2,
                padding: 1,
                mb: 1,
              }}
            >
              <Typography
                sx={profileTypography}
                variant="inputOutputListHeading"
              >
                {translate("selectedProfiles")}
              </Typography>
              {profilesList
                .filter((i) => profileIds.includes(i.id))
                .map((item, index) => (
                  <Typography
                    sx={profileTypography}
                    variant="inputOutputList"
                    key={index}
                  >
                    {item.name}
                  </Typography>
                ))}
            </Box> */}

            {/* users select field */}
            <Autocomplete
              multiple
              disableCloseOnSelect
              id="groups"
              limitTags={15}
              sx={textfieldStyle}
              options={usersList}
              value={userIds}
              onChange={(_e, value, reason) => {
                if (reason === "clear" || reason === "removeOption")
                  setSelectAllUsers(false);
                if (
                  reason === "selectOption" &&
                  value.length === usersList.length
                )
                  setSelectAllUsers(true);
                setUserIds(value);
              }}
              getOptionLabel={(option) =>
                `${option?.firstName} ${option?.lastName}`
              }
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography sx={roleTypography} variant="inputOutputList">
                    {`${option?.firstName} ${option?.lastName}`}
                  </Typography>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={translate("users")}
                  InputLabelProps={{
                    style: { color: "#6D7B88" },
                  }}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={`${option?.firstName} ${option?.lastName}`}
                    key={option.id}
                    {...getTagProps({ index })}
                    sx={styledChip}
                  />
                ))
              }
              PaperComponent={(paperProps) => {
                const { children, ...restPaperProps } = paperProps;
                return (
                  <>
                    {usersList.length < 1 ? (
                      <Typography sx={roleTypography} variant="inputOutputList">
                        No user Found
                      </Typography>
                    ) : (
                      <Paper {...restPaperProps}>
                        <Box
                          onMouseDown={(e) => e.preventDefault()} // prevent blur
                          pl={1.5}
                          py={0.5}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              e.preventDefault(); // prevent blur
                              handleToggleSelectAlluser();
                            }}
                            sx={{
                              color: "#6D7B88",
                            }}
                            label={translate("selectAll")}
                            control={
                              <Checkbox
                                id="select-all-checkbox"
                                checked={selectAllUsers}
                                sx={{
                                  color: "#6D7B88", // Change the color of the Checkbox when unchecked
                                  "&.Mui-checked": {
                                    color: "#6D7B88", // Change the color of the Checkbox when checked
                                  },
                                }}
                              />
                            }
                          />
                        </Box>
                        <Divider />
                        {children}
                      </Paper>
                    )}
                  </>
                );
              }}
            />

            <TextValidator
              sx={textfield}
              fullWidth
              id="outlined-basic"
              label={translate("description")}
              name="description"
              variant="outlined"
              type="text"
              multiline
              rows={4}
              value={roleObj?.description}
              onChange={handleChangeRoleData}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disableRipple
              sx={{
                "&.MuiButtonBase-root": {
                  backgroundColor: "black",
                  fontFamily: "Poppins-semibold",
                  fontSize: "15px",
                  color: "white",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                },
                "&:hover": { backgroundColor: "black" },
              }}
              autoFocus
              onClick={handleCloseDialog}
            >
              <Typography sx={roleHeading} variant="roleHeadingBoldButton">
                {translate("cancel")}
              </Typography>
            </Button>
            <Button
              disabled={!roleObj.name}
              disableRipple
              type="submit"
              sx={
                loader
                  ? {
                      background: "#979797",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "#979797" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
                  : {
                      backgroundColor: "background.bgLayoutA",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "background.bgLayoutA" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
              }
            >
              <Typography sx={roleHeading} variant="roleHeadingBoldButton">
                {translate("saveRole")}
              </Typography>
              {loader ? <CircularProgressLoader /> : ""}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </BootstrapDialog>
    </Container>
  );
};

export default EditDialog;
