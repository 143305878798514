import React, { useContext, useState } from "react";
import {
  Container,
  Typography,
  DialogActions,
  Button,
  DialogContent,
  TextField,
} from "@mui/material";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  demoReadyTypography,
  dialogCancelButton,
  dialogDeleteButton,
  textfieldStyle,
} from "./DemoReadyStyle";
import { useDispatch } from "../../redux/store";
import useLocales from "../../hooks/useLocales";
import toasterContext from "../../utils/context/toasterContext";
import {
  deleteGeneralLogs,
  getLogsListForDashboard,
} from "../../redux/slices/logSlice";
import CircularProgressLoader from "../../utils/CircularProgressLoader";

const DeleteLogsDialog = ({ openDeleteDialog, handleCloseDialog }) => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const [loader, setLoader] = useState(false);
  const [dateObj, setDateObj] = useState({});
  const fireToasterContext = useContext(toasterContext);

  const handleDeleteOutput = () => {
    setLoader(true);

    dispatch(
      deleteGeneralLogs(
        dateReformat(new Date(dateObj?.startDate)),
        dateReformat(new Date(dateObj?.endDate)),
        handleApiRes
      )
    );
  };

  const dateReformat = (date) => {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    return month + "/" + day + "/" + year;
  };

  const dateTimeApiformat = (dateStr) => {
    const [yyyy, mm, dd] = dateStr.split(/[/:\-T]/);
    const newDT = `${yyyy}-${mm}-${dd}`;
    return newDT;
  };

  const handleDateTimeData = (event) => {
    let tempdateTimeObj = { ...dateObj };
    tempdateTimeObj[event.target.name] = event.target.value;
    setDateObj(tempdateTimeObj);
  };

  const handleApiRes = (data) => {
    setLoader(false);

    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Logs Deleted successfully"
      );
      handleTableRecordDispatcher();
      handleCloseDialog();
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
      handleCloseDialog();
    }
  };

  const handleTableRecordDispatcher = () => {
    const today = dateTimeApiformat(new Date().toJSON());
    const temp = new Date(new Date());
    temp.setDate(temp.getDate() - 30);
    const yesterday = dateTimeApiformat(temp.toJSON());
    dispatch(
      getLogsListForDashboard(
        yesterday + " " + new Date().getHours() + ":" + new Date().getMinutes(),
        today + " " + new Date().getHours() + ":" + new Date().getMinutes(),
        () => {}
      )
    );
  };

  return (
    <Container>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDeleteDialog}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography
            sx={demoReadyTypography}
            variant="demoReadyTableHeadingBold"
          >
            {translate("deleteLogs")}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent sx={{ marginTop: "5px" }}>
          <TextField
            type="text"
            id="startDate"
            // variant="standard"
            name="startDate"
            sx={{ ...textfieldStyle, mt: 1 }}
            label={translate("startDate")}
            value={dateObj.startDate || ""}
            onChange={handleDateTimeData}
            onFocus={() => {
              document.getElementById("startDate").type = "date";
            }}
            onBlur={() => {
              document.getElementById("startDate").type = "text";
            }}
          />
          <TextField
            sx={{ ...textfieldStyle, mt: 1 }}
            id="endDate"
            label={translate("endDate")}
            // variant="standard"
            name="endDate"
            type="text"
            value={dateObj.endDate || ""}
            onChange={handleDateTimeData}
            onFocus={() => {
              document.getElementById("endDate").type = "date";
            }}
            onBlur={() => {
              document.getElementById("endDate").type = "text";
            }}
          />
          {/* <Typography sx={demoReadyTypography} variant="demoReadyTableCellName">
            {translate("areYouSure")}?
          </Typography> */}
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            sx={dialogCancelButton}
            autoFocus
            onClick={handleCloseDialog}
          >
            <Typography
              sx={demoReadyTypography}
              variant="demoReadyHeadingBoldButton"
            >
              {translate("cancel")}
            </Typography>
          </Button>
          <Button
            onClick={() => handleDeleteOutput()}
            disableRipple
            sx={
              loader
                ? {
                    background: "#979797",
                    borderRadius: "10px",
                    "&:hover": { backgroundColor: "#979797" },
                  }
                : dialogDeleteButton
            }
          >
            <Typography
              sx={demoReadyTypography}
              variant="demoReadyHeadingBoldButton"
            >
              {translate("deleteLogs")}
            </Typography>
            {loader ? <CircularProgressLoader /> : ""}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Container>
  );
};

export default DeleteLogsDialog;
