import React, { useContext, useEffect, useState } from "react";
import { textfieldStyle } from "../input/muiInputComponentStyles";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  groupHeading,
  BootstrapDialog,
  formControlWidth,
  BootstrapDialogTitle,
  dialogCancelButton,
} from "./muiGroupsComponentStyles";
import { useDispatch } from "../../redux/store";
import useLocales from "../../hooks/useLocales";
import { updateGroup } from "../../redux/slices/GroupSlice";
import ToasterContext from "../../utils/context/toasterContext";
import CircularProgressLoader from "../../utils/CircularProgressLoader";
import { styledChip } from "../room/RoomStyle";

const EditGroupDialog = ({
  usersList,
  groupDetail,
  profilesList,
  openEditDialog,
  handleCloseDialog,
}) => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const [loader, setLoader] = useState(false);
  const [groupName, setGroupName] = useState("");
  const fireToasterContext = useContext(ToasterContext);
  const [selectAllUser, setSelectAllUser] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectAllLocations, setSelectAllLocations] = useState(false);
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);

  useEffect(() => {
    setLoader(false);
    setGroupName(groupDetail?.name);
    let tempUserIds = groupDetail?.members.filter((item) => item?.id);
    setSelectedUserIds(tempUserIds);
    let tempLocationIds = groupDetail?.profileConfigurations.filter(
      (item) => item?.id
    );
    setSelectedLocationIds(tempLocationIds);
  }, []);

  const handleToggleSelectAllUsers = () => {
    setSelectAllUser((prev) => {
      if (!prev) setSelectedUserIds([...usersList]);
      else setSelectedUserIds([]);
      return !prev;
    });
  };

  const handleToggleSelectAllLocations = () => {
    setSelectAllLocations((prev) => {
      if (!prev) setSelectedLocationIds([...profilesList]);
      else setSelectAllLocations([]);
      return !prev;
    });
  };

  const handleCreateGroup = () => {
    setLoader(true);
    let finalUserIds = selectedUserIds.map((el) => el.id);
    let finalProfileIds = selectedLocationIds.map((el) => el.id);
    let data = {
      name: groupName,
      userIds: finalUserIds,
      profileIds: finalProfileIds,
    };
    dispatch(updateGroup(groupDetail?.id, data, handleApiRes));
  };

  const handleApiRes = (data) => {
    setLoader(false);

    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Group updated successfully"
      );
      handleCloseDialog();
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  return (
    <Container>
      <BootstrapDialog
        fullWidth
        open={openEditDialog}
        onClose={handleCloseDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={groupHeading} variant="groupTableHeadingBold">
            {translate("editGroup")}
          </Typography>
        </BootstrapDialogTitle>
        <ValidatorForm style={formControlWidth} onSubmit={handleCreateGroup}>
          <DialogContent dividers>
            <TextValidator
              sx={textfieldStyle}
              fullWidth
              name="name"
              label={translate("name")}
              variant="outlined"
              type="text"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              InputLabelProps={{
                style: { color: "#6D7B88" },
              }}
              validators={["required"]}
              errorMessages={["this field is required"]}
            />

            {/* select Users dropdown */}

            <Autocomplete
              disableCloseOnSelect
              noOptionsText="No options available"
              id="autocomplete_with_select_all"
              multiple
              options={usersList}
              getOptionLabel={(option) =>
                `${option?.firstName} ${option?.lastName}`
              }
              selectOnFocus
              freeSolo={false}
              value={selectedUserIds}
              onChange={(_e, value, reason) => {
                if (reason === "clear" || reason === "removeOption")
                  setSelectAllUser(false);
                if (
                  reason === "selectOption" &&
                  value.length === usersList.length
                )
                  setSelectAllUser(true);
                setSelectedUserIds(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Users" // Use your actual translation or label here
                  sx={textfieldStyle}
                  InputLabelProps={{
                    style: { color: "#6D7B88" },
                  }}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={`${option?.firstName} ${option?.lastName}`}
                    key={option.id}
                    {...getTagProps({ index })}
                    sx={styledChip}
                  />
                ))
              }
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      color: "#7D8EA3",
                      textTransform: "capitalize",
                    }}
                  >
                    {`${option?.firstName} ${option?.lastName}`}
                  </Typography>
                </li>
              )}
              PaperComponent={(paperProps) => {
                const { children, ...restPaperProps } = paperProps;
                return (
                  <>
                    {usersList.length < 1 ? (
                      <Typography
                        // sx={roomTypographies}
                        variant="inputOutputList"
                      >
                        No user Found
                      </Typography>
                    ) : (
                      <Paper {...restPaperProps}>
                        <Box
                          onMouseDown={(e) => e.preventDefault()} // prevent blur
                          pl={1.5}
                          py={0.5}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              e.preventDefault(); // prevent blur
                              handleToggleSelectAllUsers();
                            }}
                            sx={{
                              color: "#6D7B88",
                            }}
                            label="Select All" // Use your actual translation or label here
                            control={
                              <Checkbox
                                id="select-all-checkbox"
                                checked={selectAllUser}
                                sx={{
                                  color: "#6D7B88", // Change the color of the Checkbox when unchecked
                                  "&.Mui-checked": {
                                    color: "#6D7B88", // Change the color of the Checkbox when checked
                                  },
                                }}
                              />
                            }
                          />
                        </Box>
                        <Divider />
                        {children}
                      </Paper>
                    )}
                  </>
                );
              }}
            />

            {/* select Locations dropdown */}
            <Autocomplete
              disableCloseOnSelect
              noOptionsText="No options available"
              id="autocomplete_with_select_all"
              multiple
              options={profilesList}
              getOptionLabel={(option) => option.name}
              selectOnFocus
              freeSolo={false}
              value={selectedLocationIds}
              onChange={(_e, value, reason) => {
                if (reason === "clear" || reason === "removeOption")
                  setSelectAllLocations(false);
                if (
                  reason === "selectOption" &&
                  value.length === profilesList.length
                )
                  setSelectAllLocations(true);
                setSelectedLocationIds(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translate("profiles")}
                  sx={textfieldStyle}
                  InputLabelProps={{
                    style: { color: "#6D7B88" },
                  }}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.name}
                    key={option.id}
                    {...getTagProps({ index })}
                    sx={styledChip}
                  />
                ))
              }
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      color: "#7D8EA3",
                      textTransform: "capitalize",
                    }}
                  >
                    {option.name}
                  </Typography>
                </li>
              )}
              PaperComponent={(paperProps) => {
                const { children, ...restPaperProps } = paperProps;
                return (
                  <>
                    {usersList.length < 1 ? (
                      <Typography
                        // sx={roomTypographies}
                        variant="inputOutputList"
                      >
                        No Loction Found
                      </Typography>
                    ) : (
                      <Paper {...restPaperProps}>
                        <Box
                          onMouseDown={(e) => e.preventDefault()} // prevent blur
                          pl={1.5}
                          py={0.5}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              e.preventDefault(); // prevent blur
                              handleToggleSelectAllLocations();
                            }}
                            sx={{
                              color: "#6D7B88",
                            }}
                            label={translate("selectAll")}
                            control={
                              <Checkbox
                                id="select-all-checkbox"
                                checked={selectAllLocations}
                                sx={{
                                  color: "#6D7B88", // Change the color of the Checkbox when unchecked
                                  "&.Mui-checked": {
                                    color: "#6D7B88", // Change the color of the Checkbox when checked
                                  },
                                }}
                              />
                            }
                          />
                        </Box>
                        <Divider />

                        {children}
                      </Paper>
                    )}
                  </>
                );
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disableRipple
              sx={dialogCancelButton}
              autoFocus
              onClick={handleCloseDialog}
            >
              <Typography sx={groupHeading} variant="groupHeadingBoldButton">
                {translate("cancel")}
              </Typography>
            </Button>
            <Button
              disabled={!groupName}
              disableRipple
              type="submit"
              sx={
                loader
                  ? {
                      background: "#979797",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "#979797" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
                  : {
                      backgroundColor: "background.bgLayoutA",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "background.bgLayoutA" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
              }
            >
              <Typography sx={groupHeading} variant="groupHeadingBoldButton">
                {translate("saveGroup")}
              </Typography>
              {loader ? <CircularProgressLoader /> : ""}
            </Button>
          </DialogActions>
        </ValidatorForm>
      </BootstrapDialog>
    </Container>
  );
};

export default EditGroupDialog;
