import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axiosConfig";
import { dispatch } from "../store";
// import { getAlarmsList } from "./AlarmSlice";

// const headers = {
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
//   timezoneutcoffset: new Date().getTimezoneOffset(),
// };
const initialState = {
  demoReadyList: [],
};

const slice = createSlice({
  name: "demoReady",
  initialState,
  reducers: {
    getDemoReadies(state, action) {
      state.demoReadyList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getDemoReadyList(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/demo`,
        data
        // { headers }
      );
      dispatch(slice.actions.getDemoReadies(response.data));

      if (handleApiRes) handleApiRes(response.data);
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}
