import React, { useContext, useEffect, useRef, useState } from "react";
import useLocales from "../../hooks/useLocales";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Grid,
  Paper,
  Table,
  Button,
  Avatar,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  InputBase,
  IconButton,
  Typography,
  TableContainer,
} from "@mui/material";
import {
  tableAvatar,
  tableSearch,
  groupHeading,
  tableContainer,
  tableHeadColor,
  tableLoaderBox,
  tableSearchIcon,
  tableAvatarCell,
  tableSearchButton,
  customStyledTableRow,
  tableIconButton,
  tableIconButtonWithMargin,
  tableIconBox,
  StyledTableRow,
  // tableIconButtonAdd,
} from "./muiGroupsComponentStyles";
import Loader from "../Loader/Loader";
// import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import EditGroupDialog from "./EditGroupDialog";
import DeleteGroupDialog from "./DeleteGroupDialog";
import CreateGroupDialog from "./CreateGroupDialog";
import { getUsersList } from "../../redux/slices/UserSlice";
import { useDispatch, useSelector } from "../../redux/store";
import { getGroupList } from "../../redux/slices/GroupSlice";
import CustomPagination from "../../hooks/useTablePagination";
// import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { getProfilesList } from "../../redux/slices/profileSlice";
import { PageContext } from "../../utils/context/PageContext";
import { useLocation } from "react-router-dom";

const Index = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { page, setPage, updateActiveRoute } = useContext(PageContext);
  const location = useLocation();
  const inputRef = useRef("");

  const { translate } = useLocales();
  // const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [groupDetail, setGroupDetail] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredList, setFilteredList] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  let { usersList } = useSelector((state) => state.userReducer);
  let { groupList } = useSelector((state) => state.groupReducer);
  const { profilesList } = useSelector((state) => state.profileReducer);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
    dispatch(getUsersList());
    dispatch(getProfilesList());
    dispatch(getGroupList(handleApiRes));
    updateActiveRoute(location.pathname);
  }, [location]);

  useEffect(() => {
    //if search has some value in it
    const searchValue = inputRef?.current?.value;
    if (searchValue?.length >= 1) {
      handleSearch(searchValue);
    } else {
      setFilteredList(groupList);
    }
  }, [groupList]);

  const handleOpenDialog = () => setOpenCreateDialog(true);
  const handleCloseDialog = () => {
    setOpenCreateDialog(false);
    setOpenDeleteDialog(false);
    setOpenEditDialog(false);
  };
  // const handleAccessRules = (row) => {
  //   navigate("/groups/access-rules", {
  //     state: { selectedGroup: row },
  //   });
  // };

  const handleSearch = (value) => {
    //setting table page number 1
    setPage(0);
    let filterArray = [];
    const toBeSearched = value.toString().toLowerCase();
    groupList.map((item) => {
      const name = item.name.toString().toLowerCase();
      if (name?.includes(toBeSearched)) {
        filterArray.push(item);
      }
    });
    setFilteredList(filterArray);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleApiRes = () => {
    setLoading(false);
  };

  return (
    <>
      <Grid spacing={2} container>
        {localStorage.getItem("permissions")?.includes("create input") ? (
          <Grid item lg={9} md={8} sm={8} xs={12}>
            <InputBase
              type="text"
              inputRef={inputRef}
              onChange={(e) => handleSearch(e.target.value)}
              sx={tableSearch}
              placeholder={translate("searchInputs")}
              inputProps={{ "aria-label": "search" }}
              startAdornment={<SearchIcon sx={tableSearchIcon} />}
            />
          </Grid>
        ) : (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <InputBase
              type="text"
              inputRef={inputRef}
              onChange={(e) => handleSearch(e.target.value)}
              sx={tableSearch}
              placeholder={translate("searchInputs")}
              inputProps={{ "aria-label": "search" }}
              startAdornment={<SearchIcon sx={tableSearchIcon} />}
            />
          </Grid>
        )}
        {/* <Grid item lg={3} md={4} sm={4} xs={12}>
          <Button
            // onClick={() => handleOpenDialog()}
            disableRipple
            sx={tableSearchButton}
          >
            <Typography sx={groupHeading} variant="groupHeadingBoldButton">
              Duplicate{" "}
            </Typography>
          </Button>
        </Grid> */}

        {localStorage?.getItem("permissions")?.includes("create input") ? (
          <Grid item lg={3} md={4} sm={4} xs={12}>
            <Button
              onClick={() => handleOpenDialog()}
              disableRipple
              sx={tableSearchButton}
            >
              <Typography sx={groupHeading} variant="groupHeadingBoldButton">
                {translate("createGroup")}
              </Typography>
            </Button>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
      <TableContainer component={Paper} elevation={0} sx={tableContainer}>
        <Table sx={{ overflowX: "auto" }} stickyHeader>
          <TableHead sx={tableHeadColor}>
            <TableRow>
              <TableCell sx={tableHeadColor}>
                <Typography sx={groupHeading} variant="groupTableHeadingBold">
                  {translate("name")}
                </Typography>
              </TableCell>
              {loading ? null : groupList?.length < 1 ? (
                ""
              ) : (
                <>
                  {/* <TableCell sx={tableHeadColor} align="left">
                    <Typography
                      sx={groupHeading}
                      variant="groupTableHeadingBold"
                    >
                      {translate("devices")}
                    </Typography>
                  </TableCell> */}
                  <TableCell sx={tableHeadColor} align="left">
                    <Typography
                      sx={groupHeading}
                      variant="groupTableHeadingBold"
                    >
                      {translate("members")}
                    </Typography>
                  </TableCell>
                  {localStorage
                    ?.getItem("permissions")
                    ?.includes("update input" || "delete input") ? (
                    <TableCell sx={tableHeadColor} align="right">
                      <Typography
                        sx={groupHeading}
                        variant="groupTableHeadingBold"
                      ></Typography>
                    </TableCell>
                  ) : (
                    ""
                  )}
                </>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <Box style={tableLoaderBox}>
                  <Loader />
                </Box>
              </TableRow>
            ) : groupList?.length < 1 ? (
              <TableRow>
                <Box style={tableLoaderBox}>
                  <Typography sx={groupHeading} variant="groupTableCellName">
                    {translate("no Group Available")}
                  </Typography>
                </Box>
              </TableRow>
            ) : (
              filteredList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow hover key={row.id} sx={customStyledTableRow}>
                    <TableCell width={"40%"}>
                      <Box sx={tableAvatarCell}>
                        <Avatar sx={tableAvatar}>
                          {row?.name[0].toUpperCase()}
                        </Avatar>
                        <Typography
                          sx={groupHeading}
                          variant="groupTableCellName"
                        >
                          {row?.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      {/* <Typography
                        sx={groupHeading}
                        variant="groupTableCellName"
                      >
                        {row?.members?.length}
                        
                      </Typography> */}
                      {row?.members?.map((item, idx) => (
                        <Box
                          key={idx}
                          sx={{
                            margin: "2px",
                            color: "white",
                            fontSize: "12px",
                            cursor: "default",
                            padding: "4px 12px",
                            borderRadius: "50px",
                            fontFamily: "Poppins",
                            display: "inline-block",
                            textTransform: "capitalize",
                            backgroundColor: "background.bgTableChip",
                          }}
                        >
                          {`${item?.firstName}`}
                        </Box>
                      ))}
                    </TableCell>
                    {localStorage
                      ?.getItem("permissions")
                      ?.includes("update device" || "delete device") ? (
                      <TableCell align="right">
                        <Box sx={tableIconBox}>
                          {/* <IconButton
                            sx={tableIconButtonAdd}
                            onClick={() => {
                              handleAccessRules(row);
                            }}
                          >
                            <SellOutlinedIcon sx={{ color: "white" }} />
                          </IconButton> */}
                          <IconButton
                            sx={tableIconButton}
                            onClick={() => {
                              setOpenEditDialog(true);
                              setGroupDetail(row);
                            }}
                          >
                            <EditIcon sx={{ color: "white" }} />
                          </IconButton>

                          {localStorage
                            ?.getItem("permissions")
                            ?.includes("delete input") ? (
                            <IconButton
                              sx={tableIconButtonWithMargin}
                              onClick={() => {
                                setOpenDeleteDialog(true);
                                setGroupDetail(row);
                              }}
                            >
                              <DeleteOutlineIcon sx={{ color: "white" }} />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </Box>
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        filteredList={filteredList}
        setRowsPerPage={setRowsPerPage}
      />
      {openCreateDialog && (
        <CreateGroupDialog
          usersList={usersList}
          profilesList={profilesList}
          openCreateDialog={openCreateDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      {openEditDialog && (
        <EditGroupDialog
          usersList={usersList}
          groupDetail={groupDetail}
          profilesList={profilesList}
          openEditDialog={openEditDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      {openDeleteDialog && (
        <DeleteGroupDialog
          groupDetail={groupDetail}
          openDeleteDialog={openDeleteDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </>
  );
};

export default Index;
