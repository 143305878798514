import { Dialog, IconButton, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  // getContentAnchorEl: null,
  lable: {
    color: "red",
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, backgroundColor: "background.bgLayoutA" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const StyledTableRow = styled(TableRow)((props) => ({
  "&:nth-of-type(odd)": {
    background: props.theme.palette.background.bgTableOddRow,
  },
  "&:nth-of-type(even)": {
    background: props.theme.palette.background.bgTableEvenRow,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.MuiTableRow-root:hover": {
    background: props.theme.palette.background.bgTableRowHover,
  },
}));

export const categoryHeading = {
  "&.MuiTypography-categoryHeadingBoldButton": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-categoryTableHeadingBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "20px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-categoryTableCell": {
    color: "white",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-categoryTableCellName": {
    color: "primary.tableFontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const textfieldStyle = {
  input: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  label: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green",
  // },
  "& .MuiOutlinedInput-root": {
    marginBottom: "10px",
    "& fieldset": {
      fontFamily: "Poppins",
      borderColor: "#EDEDED",
      borderRadius: 3,
    },
    "& label": {
      // fontFamily: "Poppins",
      // borderColor: "#EDEDED",
    },
    "&:hover fieldset": {
      borderColor: "#7D8EA3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7D8EA3",
    },
  },
};
export const categoryTypographies = {
  "&.MuiTypography-Heading": {
    color: "black",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-List": {
    display: "flex",
    flexDirection: "column",
    color: "primary.fontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const paginationStackStyles = {
  display: "flex",
  justifyContent: "right",
  alignItems: "center",
  marginTop: "20px",
};

export const paginationRowStyles = {
  ".MuiSvgIcon-root ": {
    fill: "#9B9B9B",
  },
  ":before": { borderBottomColor: "#9B9B9B", opacity: "0%" },
  ":after": { borderBottomColor: "#9B9B9B", opacity: "0%" },
  "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
    borderBottom: `2px solid #9B9B9B`,
    opacity: "0%",
  },
};

export const tableSearch = {
  width: "100%",
  height: "38px",
  border: "1px solid rgba(169, 169, 169, 0.6)",
  borderRadius: "10px",
  backgroundColor: "background.bgSearchBar",
  fontFamily: "Poppins",
};
export const tableSearchIcon = {
  color: "rgba(151, 151, 151, 0.68);",
  paddingLeft: "3px",
};
export const tableSearchButton = {
  backgroundColor: "background.bgLayoutB",
  borderRadius: "10px",
  width: "100%",
  "&:hover": { backgroundColor: "background.bgLayoutB" },
};
export const tableContainer = {
  marginTop: "30px",
  borderRadius: "10px",
  [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
};
export const tableHeadColor = {
  backgroundColor: "background.bgLayoutA",
};
export const tableLoaderBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const tableAvatarCell = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};
export const tableAvatar = {
  marginRight: "10px",
  color: "white",
  backgroundColor: "background.bgLayoutA",
};
export const customStyledTableRow = {
  "&:last-child td, &:last-child th": { border: 0 },
};
export const tableIconBox = {
  display: "flex",
  justifyContent: "end",
};
export const tableIconButton = {
  backgroundColor: "background.bgLayoutA",
  "&:hover": { backgroundColor: "background.bgLayoutA" },
};
export const tableIconButtonWithMargin = {
  marginLeft: "10px",
  backgroundColor: "background.bgLayoutB",
  "&:hover": { backgroundColor: "background.bgLayoutB" },
};

export const dropdownSelect = {
  mb: "10px",
  // width: "500px",
  minWidth: "250px",
  borderRadius: 3,
  color: "#9B9B9B",
  textAlign: "left",
  ".MuiSvgIcon-root ": {
    fill: "#9B9B9B",
  },
  ":before": { borderBottomColor: "#9B9B9B", opacity: "48%" },
  ":after": { borderBottomColor: "#9B9B9B", opacity: "48%" },
  "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
    borderBottom: `2px solid #9B9B9B`,
    opacity: "48%",
  },
};

export const dropdownSelectListBox = {
  backgroundColor: "background.bgTableOddRow",
  borderRadius: 2,
  padding: 2,
};
export const dialogCancelButton = {
  background: "black",
  borderRadius: "10px",
  "&:hover": { backgroundColor: "black" },
};
export const dialogDeleteButton = {
  backgroundColor: "background.bgLayoutB",
  borderRadius: "10px",
  "&:hover": { backgroundColor: "background.bgLayoutB" },
};
export const dialogSubmitButton = {
  backgroundColor: "background.bgLayoutA",
  "&:hover": { backgroundColor: "background.bgLayoutA" },
  "&:disabled": {
    backgroundColor: "#979797",
  },
};
export const formControlWidth = {
  width: "100%",
};
