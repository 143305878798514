import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// config
import { defaultLang } from "../Config";

// import enLocales from "./en";
// import nbLocales from "./nb";
// import nlLocales from "./nl";

import axios from "axios";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  timezoneutcoffset: new Date().getTimezoneOffset(),
};
async function getLanguageList() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_HOST_API_KEY}v1/languages`,
      { headers }
    );

    // console.log(response?.data?.data);

    let languages = response?.data?.data;
    let resources = {};
    for (let i = 0; i < languages.length; i++) {
      for (let key in languages[i].featureTranslations) {
        resources[key] = languages[i].featureTranslations[key];
      }
    }
    // console.log(resources);
    return resources;
  } catch (error) {
    console.log(error);
  }
}

// ----------------------------------------------------------------------

getLanguageList().then((response) => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: response,
      lng: localStorage.getItem("i18nextLng") || defaultLang.value,
      fallbackLng: defaultLang.value,
      debug: false,
      ns: ["translations"],
      defaultNS: "translations",
      interpolation: {
        escapeValue: false,
      },
    });
});

export default i18n;
