import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import useLocales from "../hooks/useLocales";
import {
  MenuProps,
  textfieldStyle,
  topbarHeading,
} from "../utils/muiComponentStyles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        background: "#0054A4",
        color: "white",
        fontWeight: "bold",
        fontFamily: "Poppins-semibold",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const busyModeTimeArray = [
  { time: "5 minutes", value: 5 },
  { time: "10 minutes", value: 10 },
  { time: "15 minutes", value: 15 },
  { time: "20 minutes", value: 20 },
  { time: "30 minutes", value: 30 },
  { time: "40 minutes", value: 40 },
  { time: "50 minutes", value: 50 },
  { time: "1 hour", value: 60 },
];

const BusyModeModal = ({
  openBusyModeModal,
  handleChangeBusyMode,
  setOpenBusyModeModal,
}) => {
  const { translate } = useLocales();
  const [busyModeTime, setBusyModeTime] = useState();

  useEffect(() => {
    //Cleaning up states each time dialog opens
    setBusyModeTime("");
  }, [openBusyModeModal]);

  const handleSubmitBusyMode = () => {
    handleChangeBusyMode(busyModeTime);
  };

  const handleBusyModeChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value)) setBusyModeTime(event.target.value);
  };

  return (
    <Container>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={openBusyModeModal}
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setOpenBusyModeModal(false)}
        >
          <Typography sx={topbarHeading} variant="busyModeModalHeadingBold">
            {translate("busyMode")}
          </Typography>
        </BootstrapDialogTitle>
        <ValidatorForm
          style={{ width: "100%" }}
          onSubmit={handleSubmitBusyMode}
          // onError={(errors) => console.log(errors)}
        >
          <DialogContent dividers>
            <FormControl sx={textfieldStyle} fullWidth>
              <InputLabel id="select-busy-mode-time-ids">
                {translate("busyModeTime")}
              </InputLabel>
              <Select
                label={translate("busyModeTime")}
                required
                labelId="select-busy-mode-time-ids"
                id="select-busy-mode-time"
                value={busyModeTime || ""}
                name="busyModeTime"
                MenuProps={MenuProps}
                onChange={(event) => handleBusyModeChange(event)}
              >
                {busyModeTimeArray.map((time, index) => (
                  <MenuItem key={index} value={time.value}>
                    <Typography sx={topbarHeading} variant="busyModeModalList">
                      {time.time}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              disableRipple
              sx={{
                color: "white",
                borderRadius: "10px",
                background: "black",
                "&:hover": { backgroundColor: "black" },
              }}
              autoFocus
              onClick={() => setOpenBusyModeModal(false)}
            >
              <Typography
                sx={topbarHeading}
                variant="busyModeModalHeadingBold_Button"
              >
                {translate("cancel")}
              </Typography>
            </Button>
            <Button
              type="submit"
              sx={{
                background: "#0054A4",
                borderRadius: "10px",
                color: "white",
                "&:hover": { backgroundColor: "#0054A4" },
                "&:disabled": {
                  backgroundColor: "#979797",
                },
              }}
            >
              <Typography
                sx={topbarHeading}
                variant="busyModeModalHeadingBold_Button"
              >
                {translate("submit")}
              </Typography>
            </Button>
          </DialogActions>
        </ValidatorForm>
      </BootstrapDialog>
    </Container>
  );
};

export default BusyModeModal;
