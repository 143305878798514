import { createTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const leftbarStyles = makeStyles({
  drawer: {
    width: "265px",
  },
  drawerPaper: {
    width: "265px",
    color: "white",
    backgroundColor: "red",
  },
  root: {
    display: "flex",
    marginTop: "70px",
    // background: "#F4F8FB",
  },
});

const topbarHeight = "70px";
export const theme = createTheme({
  typography: {
    sidebarLogoHeading: {
      fontFamily: "Poppins",
      color: "white",
      fontSize: "15px",
      fontWeight: 400,
      lineHeight: "50px",
    },
    sidebarHeading: {
      fontFamily: "Poppins",
      color: "white",
      fontSize: "15px",
      fontWeight: 400,
      lineHeight: "50px",
    },
    topbarHeading: {
      fontFamily: "Poppins",
      height: "138px",
      width: "160px",
      left: "98px",
      top: "21.01px",
      borderRadius: "0px",
    },
    button: {
      // fontStyle: "italic",
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  // getContentAnchorEl: null,
  lable: {
    color: "red",
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

//Second Styles - Component Style method
export const layoutChildrenBox = {
  display: "flex",
  overflow: "hidden",
  justifyContent: "center",
  width: "100%",
  paddingTop: "30px",
  paddingBottom: "25px",
  paddingRight: "100px",
  paddingLeft: "100px",
  [`@media screen and (max-width: 1920px)`]: {
    paddingRight: "30px",
    paddingLeft: "30px",
  },
};

export const appbarTabs = {
  "&:hover": {
    color: "red",
  },
};

export const appbar = {
  "&.MuiAppBar-root": {
    backgroundColor: "background.bgLayoutA",
    height: topbarHeight,
    justifyContent: "center",
  },
};

export const sidebarDrawer = {
  "& .MuiDrawer-paper": {
    postion: "fixed",
    display: { xs: "none", sm: "none", md: "block" },
    marginTop: topbarHeight,
    backgroundColor: "background.bgLayoutA",
    border: "none",
    color: "#FFFFFF",
    width: 255,
    boxSizing: "border-box",
    textTransform: "capitalize",
    textDecoration: "none",
    overflow: "auto",
    [`::-webkit-scrollbar`]: {
      width: 0 /* Remove scrollbar space */,
      background: "transparent" /* Optional: just make scrollbar invisible */,
    },
  },
  "&.MuiListItemText-root": {
    fontFamily: "Poppins",
    fontSize: "100px !important",
    fontWeight: 400,
    lineHeight: "50px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  "&.MuiTypography-sidebarTypography": {
    color: "white",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "50px",
  },
  "&.MuiList-root": {
    mt: "15px",
    mb: "70px",
  },
  "&.MuiBox-root": { display: "flex" },
};

export const sidebarListItemButton = {
  "&.MuiListItemButton-root": {
    textDecoration: "none",
    borderRadius: "10px",
    width: 200,
    maxHeight: 50,
    padding: 1,
    margin: "2px",
    marginLeft: "10px",
    marginRight: "10px",
    color: "white",
    fontFamily: "Poppins",
  },
  "&:hover": {
    backgroundColor: "background.bgSidebarHover",
    borderRadius: "10px",
    transition: "background 600ms linear",
    // margin: "2px"
  },
  // "&.MuiListItemButton-root:hover": {
  //   background: "#0F69BE",
  //   borderRadius: "10px",
  //   marginLeft: "10px",
  //   marginRight: "10px",
  //   // margin: "2px"
  // },
  "&.active": {
    fontWeight: 1000,
    backgroundColor: "background.bgLayoutB",
    transition: "background 600ms linear",
    fontFamily: "Poppins-SemiBold",
  },
};
export const sidebarListItemButton_Roles = {
  "&.MuiListItemButton-root": {
    textDecoration: "none",
    borderRadius: "10px",
    width: 200,
    maxHeight: 50,
    padding: 1,
    margin: "2px",
    marginLeft: "10px",
    marginRight: "10px",
    color: "white",
    fontFamily: "Poppins",
  },
  "&:hover": {
    backgroundColor: "background.bgSidebarHover",
    borderRadius: "10px",
    transition: "background 600ms linear",
    // margin: "2px"
  },
  // "&.MuiListItemButton-root:hover": {
  //   background: "#0F69BE",
  //   borderRadius: "10px",
  //   marginLeft: "10px",
  //   marginRight: "10px",
  //   // margin: "2px"
  // },
  // "&.active": {
  //   fontWeight: 1000,
  //   background: "#EC313B",
  //   transition: "background 600ms linear",
  //   fontFamily: "Poppins-SemiBold",
  // },
};

export const sidebarDrawerResponsive = {
  "& .MuiDrawer-paper": {
    postion: "fixed",
    background: "#0054A4",
    border: "none",
    color: "#FFFFFF",
    boxSizing: "border-box",
    textTransform: "capitalize",
    textDecoration: "none",
    overflow: "auto",
    [`::-webkit-scrollbar`]: {
      width: 0 /* Remove scrollbar space */,
      background: "transparent" /* Optional: just make scrollbar invisible */,
    },
  },
  "&.MuiListItemText-root": {
    fontFamily: "Poppins",
    fontSize: "100px !important",
    fontWeight: 400,
    lineHeight: "50px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  "&.MuiBox-root": { margin: "10px" },
};

export const topbarLogo = {
  "&.MuiBox-root": {
    textAlign: "center",
    fontSize: "18px",
    // background: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "30px",
    // [`@media screen and (max-width: 900px)`]: {
    //   height: "49px",
    //   width: "160px",
    //   // marginRight: "30px",
    // },
    // [`@media screen and (max-width: 500px)`]: {
    //   height: "35px",
    //   width: "100px",
    //   marginRight: "10px",
    // },
  },
};

export const drawerResponsiveButton = {
  "&.MuiIconButton-root": {
    color: "white",
    marginRight: "10px",
    [`@media screen and (max-width: 500px)`]: {
      marginRight: "0px",
    },
  },
};

export const topbarHeading = {
  "&.MuiBox-root": {
    display: "flex",
    height: "38px",
    width: "420px",
    borderRadius: "0px",
    [`@media screen and (max-width: 900px)`]: {
      display: { xs: "none", sm: "none" },
    },
  },
  "&.MuiTypography-topHeading": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    marginLeft: "20px",
    fontSize: "25px",
    lineHeight: "37.5px",
    textTransform: "uppercase",
  },
  "&.MuiTypography-topHeadingSmall": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    marginLeft: "20px",
    fontSize: "22px",
    lineHeight: "37.5px",
    textTransform: "uppercase",
  },
  "&.MuiTypography-topHeadingVerySmall": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    marginLeft: "20px",
    fontSize: "16px",
    lineHeight: "37.5px",
    textTransform: "uppercase",
  },
  "&.MuiTypography-busyModeHeading": {
    color: "white",
    fontFamily: "Poppins",
    fontSize: "12px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-busyModeModalHeadingBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "20px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-busyModeModalHeadingBold_Button": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-busyModeModalList": {
    color: "#7D8EA3",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const topbarDropdownSelect = {
  "&.MuiSelect-select": {
    color: "white",
    ".MuiSvgIcon-root ": {
      fill: "white ",
    },
    "&:before": {
      borderBottom: "2px solid white",
    },
    "&:after": {
      borderBottom: "2px solid white",
    },
    "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
      // hover
      borderBottom: `2px solid purple`,
    },
  },
};

export const mainLayoutBox = {
  "&.MuiBox-root": {
    display: "block",
    background: "#F4F8FB",
    height: "auto",
    [`@media screen and (max-width: 900px)`]: {
      height: "auto",
    },
  },
};

export const dashboardHeading = {
  "&.MuiTypography-dashboardHeading": {
    color: "primary.dashboardWelcomeHeading",
    fontFamily: "Poppins",
    fontSize: "25px",
    lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-dashboardHeadingBold": {
    color: "primary.dashboardWelcomeHeading",
    fontFamily: "Poppins-Bold",
    marginLeft: "10px",
    fontSize: "25px",
    lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-dashboardInnerHeading": {
    color: "#0054A4",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-dashboardTableHeadingBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

//Topbar
export const topbarMainBox = {
  "&.MuiBox-root": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
};
export const topbarBackButton = { color: "white", marginLeft: "30px" };
export const topbarSecondaryBox = {
  "&.MuiBox-root": {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
};
export const topbarSecondaryBoxFormControl = {
  m: 1,
  minWidth: { xs: 50, sm: 120, md: 120, lg: 320 },
  border: "none",
  marginBottom: "20px",
};
export const topbarSecondaryBoxSelect = {
  color: "white",
  textAlign: "left",
  ".MuiSvgIcon-root ": {
    fill: "white ",
  },
  "&:before": {
    borderBottom: "2px solid white",
  },
  "&:after": {
    borderBottom: "2px solid white",
  },
  "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
    borderBottom: `2px solid grey`,
  },
};
export const miniScrollBarStyles = {
  [`::-webkit-scrollbar`]: {
    width: "10px",
    height: "10px",
    backgroundColor: "#F5F5F5",
  },
  [`::-webkit-scrollbar-track `]: {
    [`-webkit-box-shadow`]: "inset 0 0 6px rgba(0,0,0,0.3)",
    borderRadius: "10px",
    backgroundColor: "#F5F5F5",
  },
  [`::-webkit-scrollbar-thumb`]: {
    borderRadius: "10px",
    [`-webkit-box-shadow`]: "inset 0 0 6px rgba(0,0,0,.3)",
    backgroundColor: "#c5c5c5",
  },
};
export const topbarSecondaryBoxTooltipIconButton = {
  justifyContent: "flex-end",
};
export const topbarSecondaryBoxTooltipAvatar = {
  width: 32,
  height: 32,
  color: "white",
};

//ResponsiveSidebar
export const responsiveSidebarListBg = { background: "#0054A4" };
export const responsiveSidebarListItemIcon = {
  color: "white",
  minWidth: "35px",
};

//Sidebar SubMenu
export const sidebarSubMenuListItemIcon = {
  color: "white",
  minWidth: "35px",
};
export const sidebarSubMenuListItemIconWithPadding = {
  color: "white",
  minWidth: "35px",
  pb: "2px",
};
export const sidebarSubMenuTypography = {
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
};

export const textfieldStyle = {
  width: { xs: "200px", sm: "260px", md: "300px" },
  input: {
    color: "#7D8EA3",
    borderRadius: "10px",
    background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  label: {
    color: "#7D8EA3",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green",
  // },
  "& .MuiOutlinedInput-root": {
    // marginBottom: "10px",
    color: "#7D8EA3",
    background: "white",
    borderRadius: "10px",
    "& fieldset": {
      fontFamily: "Poppins",
      borderColor: "#EDEDED",
      borderRadius: 3,
    },
    "& label": {
      // fontFamily: "Poppins",
      // borderColor: "#EDEDED",
    },
    "&:hover fieldset": {
      borderColor: "#7D8EA3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7D8EA3",
    },
  },
};

export const themeIconButton = {
  display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
  marginRight: { lg: "10px", md: "0px" },
};

export const styledNotes = {
  width: "100%",
  input: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  label: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green",
  // },
  "& .MuiOutlinedInput-root": {
    marginBottom: "10px",
    "& fieldset": {
      fontFamily: "Poppins",
      borderColor: "#EDEDED",
      borderRadius: 3,
    },
    "& label": {
      // fontFamily: "Poppins",
      // borderColor: "#EDEDED",
      color: "red",
    },
    "&:hover fieldset": {
      borderColor: "#7D8EA3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7D8EA3",
    },
  },
};
