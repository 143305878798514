import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axiosConfig";
import { dispatch } from "../store";

// const headers = {
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
//   timezoneutcoffset: new Date().getTimezoneOffset(),
// };
const initialState = {
  outputList: [],
  roomCreationOutputList: [],
};

const slice = createSlice({
  name: "outputs",
  initialState,
  reducers: {
    getOutputs(state, action) {
      state.outputList = action.payload.data;
    },
    getRoomCreationOutputs(state, action) {
      state.roomCreationOutputList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getOutputList(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/outputConfigurations`
        // { headers }
      );
      dispatch(slice.actions.getOutputs(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      console.log(error);
      if (handleApiRes) handleApiRes();
    }
  };
}

export function getRoomCreationOutputList(roomId = null, handleApiRes) {
  return async () => {
    try {
      let response;
      if (roomId != null) {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/outputConfigurations/${roomId}`
          // { headers }
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/outputConfigurations`
          // { headers }
        );
      }
      dispatch(slice.actions.getRoomCreationOutputs(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}

export function createOutput(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/outputConfigurations`,
        data
        // { headers }
      );
      if (handleApiRes) handleApiRes(response.data);
      dispatch(getOutputList());
    } catch (error) {
      if (handleApiRes) handleApiRes(error.response.data.message);
      console.log(error);
    }
  };
}

export function deleteOutput(id, handleApiRes) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/outputConfigurations?id=${id}`
        // { headers }
      );
      handleApiRes(response.data);
      dispatch(getOutputList());
    } catch (error) {
      console.log(error);
      handleApiRes(error.response.data.message);
    }
  };
}

export function updateOutput(id, data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/outputConfigurations?id=${id}`,
        data
        // { headers }
      );
      handleApiRes(response.data);
      // handleResponse(response.data, id);
      // dispatch(slice.actions.getUserProfile(response.data));
      dispatch(getOutputList());
    } catch (error) {
      console.log(error);
      handleApiRes(error.response.data.message);
    }
  };
}

export function updateOutputStatus(id, status, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/outputConfigurations/update-status?id=${id}&active=${status}`,
        {}
        // { headers }
      );
      handleApiRes(response.data);
      dispatch(getOutputList());
    } catch (error) {
      handleApiRes(error);
      console.log(error.response.data.message);
    }
  };
}
