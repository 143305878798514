import React from "react";
/* eslint-disable */
import Dashboard from "../pages/Dashboard"; //Site crashes if disable
import Alarm from "../pages/Alarm";
import Room from "../pages/Rooms";
import Log from "../pages/Log";
import Procedure from "../pages/Procedure";
import Input from "../pages/Inputs";
// import Output from "../pages/Outputs";
import Profile from "../pages/Profiles";
// import License from "../pages/License";
// import Template from "../pages/template/Template";
import Category from "../pages/Category";
import Device from "../pages/Devices";
import Groups from "../pages/Groups";

//Icons
// import AlarmIcon from "@mui/icons-material/Alarm";
import {
  GridView,
  // NotificationsNone,
  BedOutlined,
  PermIdentity,
  Laptop,
  // CreditCard,
  // Login,
  // LogoutOutlined,
  ArrowUpward,
  ArrowDownward,
  Circle,
  Sensors,
} from "@mui/icons-material";
import Roles from "../pages/Roles";
import Reports from "../pages/Reports";
import AllUsers from "../pages/AllUsers";
import Language from "../pages/Language";
import DemoReady from "../pages/DemoReady";
import LanguageIcon from "@mui/icons-material/Language";
import RolesPermissions from "../pages/RolesPermissions";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import UserRolesPermissions from "../pages/UserRolesPermissions";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

const permissions = localStorage.getItem("permissions");
const isSuperAdmin = localStorage.getItem("isSuperAdmin");
const permissionsArray = permissions?.length > 0 ? permissions.split(",") : [];

const Routes = [
  {
    path: "/",
    sidebarName: "dashboard",
    component: Room,
    icon: <GridView />,
  },
  // {
  //   path: "/overview",
  //   sidebarName: "overview",
  //   component: Alarm,
  //   icon: <GridView />,
  // },
  // {
  //   path: "/",
  //   sidebarName: "dashboard",
  //   component: Dashboard,
  //   icon: <GridView />,
  // },
  // {
  //   path: "/alerts",
  //   sidebarName: "alerts",
  //   component: Alarm,
  //   icon: <AlarmIcon />,
  // },
  //new dashboard dummy component//
  {
    path: "/rooms",
    sidebarName: "rooms",
    component: Alarm,
    icon: <BedOutlined />,
  },
  // {
  //   path: "/room-events",
  //   sidebarName: "roomEvents",
  //   component: Procedure,
  //   icon: <BedOutlined />,
  // },
  // {
  //   path: "/event-templates",
  //   sidebarName: "templates",
  //   component: Template,
  //   icon: <PermIdentity />,
  // },
  {
    path: "/sensors",
    sidebarName: "sensors",
    component: Procedure,
    icon: <Sensors />,
  },
  {
    sidebarName: "devices",
    component: Device,
    icon: <Laptop />,
    iconClosed: <ArrowUpward />,
    iconOpened: <ArrowDownward />,
    children: [
      // {
      //   path: "/devices",
      //   sidebarName: "devicesList",
      //   components: Roles,
      //   icon: <Circle sx={{ height: 12, width: 12 }} />,
      // },
      {
        path: "/input-devices",
        sidebarName: "inputs",
        component: Input,
        icon: <Circle sx={{ height: 12, width: 12 }} />,
      },
      // {
      //   path: "/output-devices",
      //   sidebarName: "outputs",
      //   component: Output,
      //   icon: <Circle sx={{ height: 12, width: 12 }} />,
      // },
      {
        path: "/categories",
        sidebarName: "categories",
        component: Category,
        icon: <Circle sx={{ height: 12, width: 12 }} />,
      },
    ],
  },
  {
    sidebarName: "reports",
    component: Device,
    icon: <DescriptionOutlinedIcon />,
    iconClosed: <ArrowUpward />,
    iconOpened: <ArrowDownward />,
    children: [
      // {
      //   path: "/history",
      //   sidebarName: "logs",
      //   component: Log,
      //   icon: <Circle sx={{ height: 12, width: 12 }} />,
      // },
      {
        path: "/generate-reports",
        sidebarName: "generateReport",
        component: Reports,
        icon: <Circle sx={{ height: 12, width: 12 }} />,
      },
    ],
  },
  {
    path: "/testing-&-logging",
    sidebarName: "testing & logging",
    component: DemoReady,
    icon: <ReceiptLongIcon />,
  },
  {
    path: "/locations",
    sidebarName: "profiles",
    component: Profile,
    icon: <PermIdentity />,
  },
  {
    path: "/groups",
    sidebarName: "groups",
    components: Groups,
    icon: <GroupAddOutlinedIcon />,
  },
  // {
  //   path: "/license",
  //   sidebarName: "license",
  //   component: License,
  //   icon: <CreditCard />,
  // },
  {
    sidebarName: "roles",
    icon: <GridView />,
    iconClosed: <ArrowUpward />,
    iconOpened: <ArrowDownward />,
    children: [
      {
        path: "/roles",
        sidebarName: "rolesList",
        components: Roles,
        icon: <Circle sx={{ height: 12, width: 12 }} />,
      },
      {
        path: "/roles-permissions",
        sidebarName: "rolePermissions",
        components: RolesPermissions,
        icon: <Circle sx={{ height: 12, width: 12 }} />,
      },
      {
        path: "/user-roles",
        sidebarName: "userRoles",
        components: UserRolesPermissions,
        icon: <Circle sx={{ height: 12, width: 12 }} />,
      },
    ],
  },
  {
    path: "/all-users",
    sidebarName: "users",
    components: AllUsers,
    icon: <PermIdentity />,
  },
  {
    path: "/languages",
    sidebarName: "languages",
    components: Language,
    icon: <LanguageIcon />,
  },
];

let routesWithPermissions = [];
if (permissionsArray.includes("show dashboard")) {
  routesWithPermissions.push({
    path: "/",
    sidebarName: "dashboard",
    component: Room,
    icon: <GridView />,
  });
}
// if (permissionsArray.includes("show dashboard")) {
//   routesWithPermissions.push({
//     path: "/overview",
//     sidebarName: "overview",
//     component: Alarm,
//     icon: <GridView />,
//   });
// }
// if (permissionsArray.includes("show dashboard")) {
//   routesWithPermissions.push({
//     path: "/",
//     sidebarName: "dashboard",
//     component: Dashboard,
//     icon: <GridView />,
//   });
// }

// if (permissionsArray.includes("show alarms")) {
//   routesWithPermissions.push({
//     path: "/alerts",
//     sidebarName: "alarms",
//     component: Alarm,
//     icon: <AlarmIcon />,
//   });
// }

if (permissionsArray.includes("show rooms")) {
  routesWithPermissions.push({
    path: "/rooms",
    sidebarName: "rooms",
    component: Alarm,
    icon: <BedOutlined />,
  });
}

// if (permissionsArray.includes("show procedures")) {
//   routesWithPermissions.push({
//     path: "/room-events",
//     sidebarName: "roomEvents",
//     component: Procedure,
//     icon: <BedOutlined />,
//   });
// }

// if (permissionsArray.includes("show templates")) {
//   routesWithPermissions.push({
//     path: "/event-templates",
//     sidebarName: "templates",
//     component: Template,
//     icon: <PermIdentity />,
//   });
// }

// if (
//   permissionsArray.includes("show devices") ||
//   permissionsArray.includes("show inputs") ||
//   permissionsArray.includes("show outputs") ||
//   permissionsArray.includes("show categories")
// ) {
//   let deviceRouteObject = {
//     sidebarName: "devices",
//     component: Device,
//     icon: <Laptop />,
//     iconClosed: <ArrowUpward />,
//     iconOpened: <ArrowDownward />,
//     children: [],
//   };
//   // if (permissionsArray.includes("show devices")) {
//   //   deviceRouteObject.children.push({
//   //     path: "/devices",
//   //     sidebarName: "devicesList",
//   //     components: Roles,
//   //     icon: <Circle sx={{ height: 12, width: 12 }} />,
//   //   });
//   // }
//   if (permissionsArray.includes("show inputs")) {
//     deviceRouteObject.children.push({
//       path: "/input-devices",
//       sidebarName: "inputs",
//       component: Input,
//       icon: <Circle sx={{ height: 12, width: 12 }} />,
//     });
//   }
//   if (permissionsArray.includes("show outputs")) {
//     deviceRouteObject.children.push({
//       path: "/output-devices",
//       sidebarName: "outputs",
//       component: Output,
//       icon: <Circle sx={{ height: 12, width: 12 }} />,
//     });
//   }
//   if (permissionsArray.includes("show categories")) {
//     deviceRouteObject.children.push({
//       path: "/categories",
//       sidebarName: "categories",
//       component: Category,
//       icon: <Circle sx={{ height: 12, width: 12 }} />,
//     });
//   }
//   routesWithPermissions.push(deviceRouteObject);
// }
if (
  permissionsArray.includes("show devices") ||
  permissionsArray.includes("show inputs") ||
  permissionsArray.includes("show categories")
) {
  let deviceRouteObject = {
    sidebarName: "devices",
    component: Device,
    icon: <Laptop />,
    iconClosed: <ArrowUpward />,
    iconOpened: <ArrowDownward />,
    children: [],
  };
  // if (permissionsArray.includes("show devices")) {
  //   deviceRouteObject.children.push({
  //     path: "/devices",
  //     sidebarName: "devicesList",
  //     components: Roles,
  //     icon: <Circle sx={{ height: 12, width: 12 }} />,
  //   });
  // }
  if (permissionsArray.includes("show inputs")) {
    deviceRouteObject.children.push({
      path: "/input-devices",
      sidebarName: "inputs",
      component: Input,
      icon: <Circle sx={{ height: 12, width: 12 }} />,
    });
  }

  if (permissionsArray.includes("show categories")) {
    deviceRouteObject.children.push({
      path: "/categories",
      sidebarName: "categories",
      component: Category,
      icon: <Circle sx={{ height: 12, width: 12 }} />,
    });
  }
  routesWithPermissions.push(deviceRouteObject);
}

if (permissionsArray.includes("show logs")) {
  let logsRouteObject = {
    sidebarName: "reports",
    component: Device,
    icon: <Laptop />,
    iconClosed: <ArrowUpward />,
    iconOpened: <ArrowDownward />,
    children: [],
  };
  // if (permissionsArray.includes("show logs")) {
  //   logsRouteObject.children.push({
  //     path: "/history",
  //     sidebarName: "logs",
  //     component: Log,
  //     icon: <Circle sx={{ height: 12, width: 12 }} />,
  //   });
  // }
  logsRouteObject.children.push({
    path: "/generate-reports",
    sidebarName: "generateReport",
    component: Reports,
    icon: <Circle sx={{ height: 12, width: 12 }} />,
  });
  routesWithPermissions.push(logsRouteObject);
}

if (permissionsArray.includes("show profiles")) {
  routesWithPermissions.push({
    path: "/locations",
    sidebarName: "profiles",
    component: Profile,
    icon: <PermIdentity />,
  });
}

if (permissionsArray.includes("show groups")) {
  routesWithPermissions.push({
    path: "/groups",
    sidebarName: "groups",
    components: Groups,
    icon: <GroupAddOutlinedIcon />,
  });
}
if (permissionsArray.includes("show users")) {
  routesWithPermissions.push({
    path: "/all-users",
    sidebarName: "Users",
    components: AllUsers,
    icon: <PermIdentity />,
  });
}

// if (permissionsArray.includes("show license")) {
//   routesWithPermissions.push({
//     path: "/license",
//     sidebarName: "license",
//     component: License,
//     icon: <CreditCard />,
//   });
// }

routesWithPermissions.push({
  path: "/testing-&-logging",
  sidebarName: "testing & logging",
  component: DemoReady,
  icon: <ReceiptLongIcon />,
});

let navigations;
if (isSuperAdmin === "true") {
  navigations = Routes;
} else {
  navigations = routesWithPermissions;
}

export default navigations;
