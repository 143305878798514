import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axiosConfig";
import { dispatch } from "../store";

// const headers = {
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
//   timezoneutcoffset: new Date().getTimezoneOffset(),
// };
const initialState = {
  roomlogsList: [],
  dashboardlogsList: [],
  generalLogsList: [],
  alarmLogsList: [],
  lockLogsList: [],
  helpLogsList: [],
  locksList: [],
  reportsList: [],
};

const slice = createSlice({
  name: "log",
  initialState,
  reducers: {
    getLogs(state, action) {
      state.roomlogsList = action.payload.data;
    },
    getLogsForDashboard(state, action) {
      state.dashboardlogsList = action.payload.data;
    },
    getGeneralLogs(state, action) {
      state.generalLogsList = action.payload.data;
    },
    getAlarmLogs(state, action) {
      state.alarmLogsList = action.payload.data;
    },
    getLockLogs(state, action) {
      state.lockLogsList = action.payload.data;
    },
    getHelpLogs(state, action) {
      state.helpLogsList = action.payload.data;
    },
    getLocks(state, action) {
      state.locksList = action.payload.data;
    },
    getReports(state, action) {
      state.reportsList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getRoomLogsList(roomId, fromDate, toDate, handleApiRes) {
  return async () => {
    try {
      let response;
      if (roomId != "All") {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/logs?roomId=${roomId}&fromDate=${fromDate}&toDate=${toDate}`
          // { headers }
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/logs?fromDate=${fromDate}&toDate=${toDate}`
          // { headers }
        );
      }
      dispatch(slice.actions.getLogs(response.data));
      if (handleApiRes) {
        handleApiRes();
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error);
      }
    }
  };
}

export function getGeneralLogsList(fromDate, toDate, handleApiRes) {
  return async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/logs/general-logs?fromDate=${fromDate}&toDate=${toDate}`
        // { headers }
      );
      dispatch(slice.actions.getGeneralLogs(response.data));
      if (handleApiRes) {
        handleApiRes();
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error);
      }
    }
  };
}

export function getAlarmLogsList(
  userId,
  roomId,
  fromDate,
  toDate,
  handleApiRes
) {
  return async () => {
    try {
      let response;
      if (userId != "All" && roomId != "All") {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/logs/alarm-received-entries?roomId=${roomId}&receivedByUserId=${userId}&fromDate=${fromDate}&toDate=${toDate}`
          // { headers }
        );
      } else if (userId != "All") {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/logs/alarm-received-entries?receivedByUserId=${userId}&fromDate=${fromDate}&toDate=${toDate}`
          // { headers }
        );
      } else if (roomId != "All") {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/logs/alarm-received-entries?roomId=${roomId}&fromDate=${fromDate}&toDate=${toDate}`
          // { headers }
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/logs/alarm-received-entries?fromDate=${fromDate}&toDate=${toDate}`
          // { headers }
        );
      }
      dispatch(slice.actions.getAlarmLogs(response.data));
      if (handleApiRes) {
        handleApiRes();
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error);
      }
    }
  };
}

export function getLockLogsList(lockId, fromDate, toDate, handleApiRes) {
  return async () => {
    try {
      let response;
      if (lockId != "All") {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/logs/lock-logs?lockId=${lockId}&fromDate=${fromDate}&toDate=${toDate}`
          // { headers }
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/logs/lock-logs?fromDate=${fromDate}&toDate=${toDate}`
          // { headers }
        );
      }
      dispatch(slice.actions.getLockLogs(response.data));
      if (handleApiRes) {
        handleApiRes();
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error);
      }
    }
  };
}

export function getHelpLogsList(roomId, fromDate, toDate, handleApiRes) {
  return async () => {
    try {
      let response;
      if (roomId != "All") {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/logs/help-logs?roomId=${roomId}&fromDate=${fromDate}&toDate=${toDate}`
          // { headers }
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/logs/help-logs?fromDate=${fromDate}&toDate=${toDate}`
          // { headers }
        );
      }
      dispatch(slice.actions.getHelpLogs(response.data));
      if (handleApiRes) {
        handleApiRes();
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error);
      }
    }
  };
}

export function getLocksList(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/deviceConfigurations/locks`
        // { headers }
      );
      dispatch(slice.actions.getLocks(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      console.log(error);
      if (handleApiRes) handleApiRes();
    }
  };
}

export function getLogsListByRoomId(id, handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/logs?roomId=${id}?page=0&limit=500000`
        // { headers }
      );
      dispatch(slice.actions.getLogs(response.data));
      if (handleApiRes) {
        handleApiRes();
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error);
      }
    }
  };
}

export function getReportList(roomId, filter, handleApiRes) {
  return async () => {
    try {
      let period = "";
      filter == 0
        ? (period = "daily")
        : filter == 1
        ? (period = "weekly")
        : filter == 2
        ? (period = "monthly")
        : (period = "");
      let response;
      if (roomId == "All") {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reports?period=${period}`
          // { headers }
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/logs/reports?roomId=${roomId}&period=${period}`
          // { headers }
        );
      }
      dispatch(slice.actions.getReports(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      console.log(error);
      if (handleApiRes) handleApiRes();
    }
  };
}

export function getLogsListForDashboard(fromDate, toDate, handleApiRes) {
  return async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/logs?fromDate=${fromDate}&toDate=${toDate}`
        // { headers }
      );
      dispatch(slice.actions.getLogsForDashboard(response.data));
      if (handleApiRes) {
        handleApiRes();
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error);
      }
    }
  };
}

export function getLogsListForDashboardTimeline(handleApiRes) {
  return async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/dashboard/timeline-data`
        // { headers }
      );
      dispatch(slice.actions.getLogsForDashboard(response.data));
      if (handleApiRes) {
        handleApiRes();
      }
    } catch (error) {
      console.log("error", error);
      if (handleApiRes) {
        handleApiRes();
      }
    }
  };
}

export function deleteGeneralLogs(fromDate, toDate, handleApiRes) {
  return async () => {
    try {
      let response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/logs?fromDate=${fromDate}&toDate=${toDate}`
        // { headers }
      );
      handleApiRes(response.data);
      if (handleApiRes) {
        handleApiRes();
      }
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error);
      }
    }
  };
}
