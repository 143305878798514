import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Container,
  Typography,
  DialogActions,
  DialogContent,
} from "@mui/material";
import {
  groupHeading,
  BootstrapDialog,
  dialogCancelButton,
  BootstrapDialogTitle,
  dialogDeleteButton,
} from "./muiGroupsComponentStyles";
import useLocales from "../../hooks/useLocales";
import { useDispatch } from "../../redux/store";
import { deleteGroup } from "../../redux/slices/GroupSlice";
import ToasterContext from "../../utils/context/toasterContext";
import CircularProgressLoader from "../../utils/CircularProgressLoader";

const DeleteGroupDialog = ({
  groupDetail,
  openDeleteDialog,
  handleCloseDialog,
}) => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const [loader, setLoader] = useState(false);
  const fireToasterContext = useContext(ToasterContext);

  useEffect(() => {
    setLoader(false);
  }, []);

  const handleDeleteGroup = () => {
    setLoader(true);
    dispatch(deleteGroup(groupDetail?.id, handleApiRes));
  };

  const handleApiRes = (data) => {
    setLoader(false);

    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Group deleted successfully"
      );
      handleCloseDialog();
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  return (
    <Container>
      <BootstrapDialog
        fullWidth
        open={openDeleteDialog}
        onClose={handleCloseDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={groupHeading} variant="groupTableHeadingBold">
            {translate("deleteGroup")}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent sx={{ marginTop: "5px" }}>
          <Typography sx={groupHeading} variant="groupTableCellName">
            {translate("areYouSure")} <b>{groupDetail?.name}</b> ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            sx={dialogCancelButton}
            autoFocus
            onClick={handleCloseDialog}
          >
            <Typography sx={groupHeading} variant="groupHeadingBoldButton">
              {translate("cancel")}
            </Typography>
          </Button>
          <Button
            onClick={() => handleDeleteGroup()}
            disableRipple
            sx={
              loader
                ? {
                    background: "#979797",
                    borderRadius: "10px",
                    "&:hover": { backgroundColor: "#979797" },
                  }
                : dialogDeleteButton
            }
          >
            <Typography sx={groupHeading} variant="groupHeadingBoldButton">
              {translate("deleteGroup")}
            </Typography>
            {loader ? <CircularProgressLoader /> : ""}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Container>
  );
};

export default DeleteGroupDialog;
