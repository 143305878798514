import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import AppRouter from "./routes/index";
import socketIO from "socket.io-client";
import "react-toastify/dist/ReactToastify.css";
import ThemeContext from "./utils/themeContext";
import ReplayIcon from "@mui/icons-material/Replay";
import { ToastContainer, toast } from "react-toastify";
import { getAlarmsList } from "./redux/slices/AlarmSlice";
import { useDispatch, useSelector } from "./redux/store";
import { getMyGroupList } from "./redux/slices/GroupSlice";
import ToasterContext from "./utils/context/toasterContext";
import { darkTheme, lightTheme } from "./utils/muiDualTheme";
import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import I18nContext from "./utils/context/I18nContext";
import HourGlassLoader from "./components/Loader/HourGlassLoader";
const baseUrl = process.env.REACT_APP_HOST_API_KEY.split("/api/")[0];

const socket = socketIO.connect(baseUrl);

const App = () => {
  const dispatch = useDispatch();
  const [loader, showLoader] = useState(false);
  const [showToaster, setShowToaster] = useState();
  const themeMode = localStorage.getItem("themeMode");
  let userDetails = JSON.parse(localStorage.getItem("user"));
  const [isDarkTheme, setIsDarkTheme] = useState(themeMode);
  const defaultProfileId = localStorage.getItem("userDefaultId");
  const { isInitialized, translateWithContext } = useContext(I18nContext);
  let { myGroupList } = useSelector((state) => state.groupReducer);

  //Effect to join socket room on each reload
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(getMyGroupList());
    }
    if (localStorage.getItem("token") && defaultProfileId) {
      socket.emit("joinRoom", defaultProfileId, undefined);
    }
    socket.emit("joinUser", userDetails?.id);
  }, []);

  useEffect(() => {
    setIsDarkTheme(themeMode);
  }, []);

  //Effect for joining groups on first render
  useEffect(() => {
    if (myGroupList?.length >= 1) {
      socket.emit("joinGroup", myGroupList);
    } else socket.emit("joinGroup", []);
  }, [myGroupList]);

  //Effects for Alarms with Socket Implementation

  useEffect(() => {
    if (userDetails?.isBusy != true) {
      socket.on("alertNotificationV2", (obj) => {
        let newMessage = translateWithContext(`${obj.key}`);
        fireToasterHandler(true, newMessage);
        dispatch(getAlarmsList(handleAlarmResponse));
      });
      return () => {
        socket.off("alertNotificationV2");
      };
    }
  }, [socket]);

  useEffect(() => {
    socket.on("defaultProfileUpdated", (obj) => {
      localStorage.setItem("userDefaultId", obj?.userDefaultId);
    });
    return () => {
      socket.off("defaultProfileUpdated");
    };
  }, [socket]);

  useEffect(() => {
    socket.on("permissionChanged", (obj) => {
      fireToasterHandler(true, obj?.message);
    });
    return () => {
      socket.off("permissionChanged");
    };
  }, [socket]);

  const setTheme = () => {
    if (themeMode == "true") {
      localStorage.setItem("themeMode", false);
      setIsDarkTheme(false);
    } else {
      localStorage.setItem("themeMode", true);
      setIsDarkTheme(true);
    }
  };

  const toastOption = {
    autoClose: 10000,
    position: "top-right",
    closeOnClick: true,
    hideProgressBar: false,
    pauseOnHover: true,
    progress: undefined,
    draggable: true,
    theme: "colored",
  };
  const toastOptionSlow = {
    autoClose: 20000,
    position: "top-right",
    closeOnClick: true,
    hideProgressBar: false,
    pauseOnHover: true,
    progress: undefined,
    draggable: true,
    theme: "colored",
  };

  useEffect(() => {
    localStorage.removeItem("permissions");
    async function permissionsData() {
      if (localStorage.getItem("token")) {
        try {
          showLoader(true);
          const permissionsResponse = await axios.get(
            process.env.REACT_APP_HOST_API_KEY +
              "v1/permissions/permissions-for-loggedin-user",
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          localStorage.setItem("permissions", permissionsResponse?.data?.data);
          showLoader(false);
        } catch (error) {
          showLoader(false);
          localStorage.removeItem("token");
          localStorage.removeItem("permissions");
          window.location.href = "/login";
        }
      }
    }
    permissionsData();
  }, []);

  const fireToasterHandler = (value, message) => {
    if (value) {
      message?.includes("Permissions")
        ? toast.success(
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div> {message}</div>
              <ReplayIcon
                sx={{ color: "background.bgLayoutA" }}
                onClick={() => window.location.reload()}
              />
            </Box>,
            toastOptionSlow
          )
        : toast.success(<div>{message}</div>, toastOption);
    } else {
      toast.error(message, toastOption);
    }

    setShowToaster(value);
  };

  const handleAlarmResponse = () => {};

  if (!isInitialized) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <HourGlassLoader />
      </Box>
    );
  }
  return (
    <div className="App">
      {loader ? null : (
        <>
          <ThemeProvider
            theme={
              isDarkTheme == true || isDarkTheme == "true"
                ? createTheme(darkTheme)
                : createTheme(lightTheme)
            }
          >
            <CssBaseline />
            <ToastContainer />
            <ThemeContext.Provider
              value={{
                isDarkTheme: isDarkTheme,
                setTheme: setTheme,
              }}
            >
              <ToasterContext.Provider
                value={{
                  isSuccess: showToaster,
                  fireToasterHandler: fireToasterHandler,
                }}
              >
                <AppRouter socket={socket} />
              </ToasterContext.Provider>
            </ThemeContext.Provider>
          </ThemeProvider>
        </>
      )}
    </div>
  );
};

export default App;
