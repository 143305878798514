import React from "react";
import "./locales/i18n";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { store, persistor } from "./redux/store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { I18nProvider } from "./utils/context/I18nContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nProvider>
      <ReduxProvider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </LocalizationProvider>
      </ReduxProvider>
    </I18nProvider>
  </React.StrictMode>
);
