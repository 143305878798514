const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
  lable: {
    color: "red",
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};
export const logsTypographies = {
  "&.MuiTypography-Heading": {
    color: "black",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    // lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-List": {
    display: "flex",
    flexDirection: "column",
    color: "primary.fontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};
export const formControlWidth = {
  width: "100%",
  ".MuiFormLabel-root": {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "15px",
  },
};
export const textFieldLogsFilter = {
  "&.MuiFormControl-root": {
    width: "100%",
    ".MuiFormLabel-root": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "15px",
    },
    ".MuiInputBase-input": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "15px",
      color: "primary.fontColor",
    },
  },
};

export const textFieldSearchLogsFilter = {
  "&.MuiInputBase-root": {
    border: "1px solid rgba(169, 169, 169, 0.6)",
    borderRadius: 2,
    backgroundColor: "background.bgSearchBar",
    width: "100%",
    fontFamily: "Poppins",
  },
};

export const logsSearchFieldIcon = {
  "&.MuiSvgIcon-root": {
    color: "rgba(151, 151, 151, 0.68);",
    paddingLeft: "3px",
  },
};

export const logsSearchFilterButton = {
  "&.MuiButton-root": {
    backgroundColor: "background.bgLayoutA",
    width: "100%",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const logsTableHeadCell = {
  "&.MuiTableCell-root": {
    fontFamily: "Poppins-semibold",
    fontSize: "16px",
  },
};

export const logsTableBodyCell = {
  "&.MuiTableCell-root": {
    fontFamily: "Poppins",
    fontSize: "15px",
    minWidth: 200,
    color: "primary.tableFontColor",
  },
};

export const logTableContainer = {
  "&.MuiTableContainer-root": {
    borderRadius: "10px",
    fontFamily: "Poppins",
    [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
    // minWidth: 550,
  },
};
export const tableLoaderBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
// export const logsTableBodyRow = {
//   "&.MuiTableRow-root": {
//     backgroundColor: index % 2 ? "white" : "F5F5F5",
//   },
// };
