import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  StyledTableRow,
  customStyledTableRow,
  languageHeading,
  tableAvatar,
  tableAvatarCell,
  tableContainer,
  tableHeadColor,
  tableIconBox,
  tableIconButton,
  tableIconButtonWithMargin,
  tableLoaderBox,
  tableSearch,
  tableSearchButton,
  tableSearchIcon,
} from "./muiLanguageComponentStyles";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import useLocales from "../../hooks/useLocales";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import EditLanguageDialog from "./EditLanguageDialog";
import CreateLanguageDialog from "./CreateLanguageDialog";
import DeleteLanguageDialog from "./DeleteLanguageDialog";
import { useDispatch, useSelector } from "../../redux/store";
import CustomPagination from "../../hooks/useTablePagination";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { getLanguageList } from "../../redux/slices/LanguageSlice";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef("");
  const { translate } = useLocales();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredList, setFilteredList] = useState([]);
  const [languageDetail, setLanguageDetail] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  let { languageList } = useSelector((state) => state.languageReducer);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
    dispatch(getLanguageList(handleApiRes));
  }, []);

  useEffect(() => {
    //if search has some value in it
    const searchValue = inputRef?.current?.value;
    if (searchValue?.length >= 1) {
      handleSearch(searchValue);
    } else {
      setFilteredList(languageList);
    }
  }, [languageList]);

  const handleCloseDialog = () => {
    setOpenCreateDialog(false);
    setOpenDeleteDialog(false);
    setOpenEditDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleSearch = (value) => {
    //setting table page number 1
    setPage(0);
    let filterArray = [];
    const toBeSearched = value.toString().toLowerCase();
    languageList.map((item) => {
      const name = item.name.toString().toLowerCase();
      if (name?.includes(toBeSearched)) {
        filterArray.push(item);
      }
    });
    setFilteredList(filterArray);
  };

  const handleApiRes = () => {
    setLoading(false);
  };

  return (
    <>
      <Grid spacing={2} container>
        <Grid item lg={9} md={8} sm={8} xs={12}>
          <InputBase
            type="text"
            inputRef={inputRef}
            onChange={(e) => handleSearch(e.target.value)}
            sx={tableSearch}
            placeholder={translate("searchInputs")}
            inputProps={{ "aria-label": "search" }}
            startAdornment={<SearchIcon sx={tableSearchIcon} />}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={4} xs={12}>
          <Button
            // onClick={() => setOpenCreateDialog(true)}
            disableRipple
            sx={tableSearchButton}
            onClick={() => navigate("/languages/add-language")}
          >
            <Typography
              sx={languageHeading}
              variant="languageHeadingBoldButton"
            >
              {translate("createLanguage")}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper} elevation={0} sx={tableContainer}>
        <Table sx={{ overflowX: "auto" }} stickyHeader>
          <TableHead sx={tableHeadColor}>
            <TableRow>
              <TableCell sx={tableHeadColor}>
                <Typography
                  sx={languageHeading}
                  variant="languageTableHeadingBold"
                >
                  {translate("name")}
                </Typography>
              </TableCell>
              <TableCell sx={tableHeadColor}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <Box style={tableLoaderBox}>
                  <Loader />
                </Box>
              </TableRow>
            ) : languageList?.length < 1 ? (
              <TableRow>
                <Box style={tableLoaderBox}>
                  <Typography
                    sx={languageHeading}
                    variant="languageTableCellName"
                  >
                    {translate("no Language Available")}
                  </Typography>
                </Box>
              </TableRow>
            ) : (
              filteredList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow hover key={row.id} sx={customStyledTableRow}>
                    <TableCell width={"40%"}>
                      <Box sx={tableAvatarCell}>
                        <Avatar sx={tableAvatar}>
                          {row?.name[0].toUpperCase()}
                        </Avatar>
                        <Typography
                          sx={languageHeading}
                          variant="languageTableCellName"
                        >
                          {row?.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    {/* <TableCell align="left">
                      {row?.members?.map((item, idx) => (
                        <Box
                          key={idx}
                          sx={{
                            margin: "2px",
                            color: "white",
                            fontSize: "12px",
                            cursor: "default",
                            padding: "4px 12px",
                            borderRadius: "50px",
                            fontFamily: "Poppins",
                            display: "inline-block",
                            textTransform: "capitalize",
                            backgroundColor: "background.bgTableChip",
                          }}
                        >
                          {`${item?.firstName}`}
                        </Box>
                      ))}
                    </TableCell> */}
                    <TableCell align="right">
                      <Box sx={tableIconBox}>
                        {localStorage
                          ?.getItem("permissions")
                          ?.includes("update input") ? (
                          <IconButton
                            sx={tableIconButton}
                            // onClick={() => {
                            //   setOpenEditDialog(true);
                            //   setLanguageDetail(row);
                            // }}
                            onClick={() =>
                              navigate("/languages/update-language", {
                                state: { selectedLanguage: row },
                              })
                            }
                          >
                            <EditIcon sx={{ color: "white" }} />
                          </IconButton>
                        ) : (
                          ""
                        )}
                        {localStorage
                          ?.getItem("permissions")
                          ?.includes("delete input") ? (
                          <IconButton
                            sx={tableIconButtonWithMargin}
                            onClick={() => {
                              setOpenDeleteDialog(true);
                              setLanguageDetail(row);
                            }}
                          >
                            <DeleteOutlineIcon sx={{ color: "white" }} />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </Box>
                    </TableCell>
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        filteredList={filteredList}
        setRowsPerPage={setRowsPerPage}
      />
      {openCreateDialog && (
        <CreateLanguageDialog
          // usersList={usersList}
          // profilesList={profilesList}
          openCreateDialog={openCreateDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      {openEditDialog && (
        <EditLanguageDialog
          // usersList={usersList}
          languageDetail={languageDetail}
          openEditDialog={openEditDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      {openDeleteDialog && (
        <DeleteLanguageDialog
          // usersList={usersList}
          languageDetail={languageDetail}
          openDeleteDialog={openDeleteDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </>
  );
};

export default Index;
