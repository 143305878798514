import React, { Suspense } from "react";
import HourGlassLoader from "./HourGlassLoader";
import { Box } from "@mui/material";

const LoadingBox = () => (
  <Box height="70vh" display="flex" alignItems="center" justifyContent="center">
    <HourGlassLoader height={40} width={40} />
  </Box>
);

const ComponentLoadable = (Component) => {
  const LoadableComponent = (props) => (
    <Suspense fallback={<LoadingBox />}>
      <Component {...props} />
    </Suspense>
  );

  LoadableComponent.displayName = `Loadable(${
    Component.displayName || Component.name || "Component"
  })`;

  return LoadableComponent;
};

export default ComponentLoadable;
