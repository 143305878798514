import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axiosConfig";
import { dispatch } from "../store";

// const headers = {
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
//   timezoneutcoffset: new Date().getTimezoneOffset(),
// };
const initialState = {
  loaderEvent: false,
  procedureList: [],
  procedureDetailObj: {},
};

const slice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    startLoader(state) {
      state.loaderEvent = true;
    },
    stopLoader(state) {
      state.loaderEvent = false;
    },
    getProcedures(state, action) {
      state.procedureList = action.payload.data;
    },
    getProcedureDetail(state, action) {
      state.procedureDetailObj = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getProcedureList(handleApiRes, profileId = undefined) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/procedureConfigurations?profileId=${profileId}`
        // { headers }
      );

      dispatch(slice.actions.getProcedures(response.data));
      // if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}
export function getProcedureDetailList(id) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/procedureConfigurations/details?id=${id}`
        // { headers }
      );
      dispatch(slice.actions.getProcedureDetail(response.data));
      // if (handleApiRes) handleApiRes();
    } catch (error) {
      // if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}

export function createProcedure(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/procedureConfigurations`,
        data
        // { headers }
      );
      handleApiRes(response);
      // handleResponse(response.data, id);
      // dispatch(slice.actions.getProcedures(response.data));
      dispatch(getProcedureList());
    } catch (error) {
      console.log(error);
      if (error) {
        if (handleApiRes) {
          handleApiRes(error?.response);
        }
      }
    }
  };
}

export function duplicateProcedue(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/procedureConfigurations/duplicate-event`,
        data
        // { headers }
      );
      if (handleApiRes) {
        handleApiRes(response.data, data.procedureId);
      }
      // handleResponse(response.data, id);
      // dispatch(slice.actions.getProcedures(response.data));
      dispatch(getProcedureList());
    } catch (error) {
      console.log(error);
      if (handleApiRes) {
        handleApiRes(error.response.data, data.procedureId);
      }
    }
  };
}

export function deleteProcedure(id, handleApiRes) {
  return async () => {
    try {
      dispatch(slice.actions.startLoader());
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/procedureConfigurations?id=${id}`
        // { headers }
      );
      handleApiRes(response.data);
      // handleResponse(response.data, id);
      // dispatch(slice.actions.getUserProfile(response.data));
      // dispatch(getProcedureList());
      dispatch(slice.actions.stopLoader());
    } catch (error) {
      console.log(error);
      handleApiRes(error);
      dispatch(slice.actions.stopLoader());
    }
  };
}

export function updateProcedure(data, handleApiResponse) {
  return async () => {
    try {
      dispatch(slice.actions.startLoader());
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/procedureConfigurations?id=${data.procedureId}`,
        data
        // { headers }
      );
      handleApiResponse(response.data);
      dispatch(slice.actions.getProcedureDetail(response.data));
      // dispatch(getProcedureList());
      dispatch(slice.actions.stopLoader());
    } catch (error) {
      // if (handleApiResponse) {
      //   handleApiResponse(error?.response);
      // }
      dispatch(slice.actions.stopLoader());
      console.log(error);
    }
  };
}

export function updateAlarmsProcedure(
  data,
  handleUpdateProcedureSettingsResponse
) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/procedureConfigurations/update-activation-settings`,
        { settings: data }
        // { headers }
      );
      handleUpdateProcedureSettingsResponse(response.data);
      // dispatch(slice.actions.getProcedures(response.data));
    } catch (error) {
      console.log(error);
      handleUpdateProcedureSettingsResponse(error.response.data.message);
    }
  };
}

export function uploadProcedureImage(id, data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/procedureConfigurations/upload-procedure-image?id=${id}`,
        data
        // { headers }
      );
      // handleResponse(response.data, id);
      // dispatch(slice.actions.getProcedures(response.data));
      dispatch(getProcedureList());
      if (handleApiRes) handleApiRes(response.data);
    } catch (error) {
      console.log(error);
    }
  };
}
