import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import React, { useState } from "react";
import {
  tableLoaderBox,
  logsTableBodyCell,
  logsTableHeadCell,
} from "./LogsStyle";
import Loader from "../Loader/Loader";
import useLocales from "../../hooks/useLocales";

const LogsTableBody = ({
  logType,
  filteredLogArray,
  page,
  rowsPerPage,
  roomsList,
  loading,
  procedureList,
}) => {
  const { translate } = useLocales();
  const [order, setOrder] = useState("asc");
  const dateTimeReformat = (dateStr) => {
    const [yyyy, mm, dd, hh, mi] = dateStr.split(/[/:\-T]/);
    const newDT = `${dd}-${mm}-${yyyy} | ${hh}:${mi}`;
    return newDT;
  };

  const getRoomName = (roomId) => {
    let roomName;

    roomsList?.map((item) => {
      if (item?.id == roomId) roomName = item?.name;
    });

    return roomName;
  };
  const getEventName = (eventId) => {
    let eventName;

    procedureList?.map((item) => {
      if (item?.id == eventId) eventName = item?.name;
    });

    return eventName;
  };

  return (
    <>
      <Table aria-labelledby="tableTitle">
        <TableHead>
          {logType === 0 && (
            <TableRow>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel>{translate("date")}</TableSortLabel>
              </TableCell>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel
                  direction={order}
                  onClick={() => setOrder(order == "asc" ? "desc" : "asc")}
                >
                  {translate("text")}
                </TableSortLabel>
              </TableCell>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel>{translate("category")}</TableSortLabel>
              </TableCell>
            </TableRow>
          )}
          {logType === 1 && (
            <TableRow>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel>{translate("date")}</TableSortLabel>
              </TableCell>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel
                  direction={order}
                  onClick={() => setOrder(order == "asc" ? "desc" : "asc")}
                >
                  {translate("text")}
                </TableSortLabel>
              </TableCell>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel>{translate("room")}</TableSortLabel>
              </TableCell>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel>{translate("category")}</TableSortLabel>
              </TableCell>
            </TableRow>
          )}
          {logType === 2 && (
            <TableRow>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel>{translate("date")}</TableSortLabel>
              </TableCell>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel
                  direction={order}
                  onClick={() => setOrder(order == "asc" ? "desc" : "asc")}
                >
                  {translate("text")}
                </TableSortLabel>
              </TableCell>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel>{translate("userName")}</TableSortLabel>
              </TableCell>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel>{translate("room")}</TableSortLabel>
              </TableCell>
            </TableRow>
          )}
          {logType === 3 && (
            <TableRow>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel>{translate("date")}</TableSortLabel>
              </TableCell>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel
                  direction={order}
                  onClick={() => setOrder(order == "asc" ? "desc" : "asc")}
                >
                  {translate("text")}
                </TableSortLabel>
              </TableCell>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel>{translate("lock")}</TableSortLabel>
              </TableCell>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel>{translate("room")}</TableSortLabel>
              </TableCell>
            </TableRow>
          )}
          {logType === 4 && (
            <TableRow>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel>{translate("date")}</TableSortLabel>
              </TableCell>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel
                  direction={order}
                  onClick={() => setOrder(order == "asc" ? "desc" : "asc")}
                >
                  {translate("text")}
                </TableSortLabel>
              </TableCell>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel>{translate("room")}</TableSortLabel>
              </TableCell>
              <TableCell sx={logsTableHeadCell}>
                <TableSortLabel>{translate("event")}</TableSortLabel>
              </TableCell>
            </TableRow>
          )}
        </TableHead>

        <TableBody sx={{ minWidth: 750 }}>
          {loading ? (
            <TableRow
              hover
              sx={{
                backgroundColor: "background.bgTableRowEven",
                "&.MuiTableRow-root:hover": {
                  backgroundColor: "background.bgTableRowHover",
                },
              }}
            >
              <TableCell align="center" colSpan={4} sx={logsTableBodyCell}>
                <Box style={tableLoaderBox}>
                  <Loader />
                </Box>
              </TableCell>
            </TableRow>
          ) : filteredLogArray?.length < 1 ? (
            <TableRow
              hover
              sx={{
                backgroundColor: "background.bgTableRowEven",
                "&.MuiTableRow-root:hover": {
                  backgroundColor: "background.bgTableRowHover",
                },
              }}
            >
              <TableCell align="center" colSpan={4} sx={logsTableBodyCell}>
                No Logs Available
              </TableCell>
            </TableRow>
          ) : logType === 0 ? (
            filteredLogArray
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((log, index) => (
                <TableRow
                  hover
                  key={log?.id}
                  sx={{
                    backgroundColor:
                      index % 2
                        ? "background.bgTableEvenRow"
                        : "background.bgTableOddRow",
                    "&.MuiTableRow-root:hover": {
                      backgroundColor: "background.bgTableRowHover",
                    },
                  }}
                >
                  <TableCell sx={logsTableBodyCell}>
                    {dateTimeReformat(log?.createdAt)}
                  </TableCell>
                  <TableCell sx={logsTableBodyCell}>{log?.text}</TableCell>
                  <TableCell sx={logsTableBodyCell}>{log?.category}</TableCell>
                </TableRow>
              ))
          ) : logType === 1 ? (
            filteredLogArray
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((log, index) => (
                <TableRow
                  hover
                  key={log?.id}
                  sx={{
                    backgroundColor:
                      index % 2
                        ? "background.bgTableEvenRow"
                        : "background.bgTableOddRow",
                    "&.MuiTableRow-root:hover": {
                      backgroundColor: "background.bgTableRowHover",
                    },
                  }}
                >
                  <TableCell sx={logsTableBodyCell}>
                    {dateTimeReformat(log?.createdAt)}
                  </TableCell>
                  <TableCell sx={logsTableBodyCell}>{log?.text}</TableCell>
                  <TableCell sx={logsTableBodyCell}>
                    {getRoomName(log?.roomId)}
                  </TableCell>
                  <TableCell sx={logsTableBodyCell}>{log?.category}</TableCell>
                </TableRow>
              ))
          ) : logType === 2 ? (
            filteredLogArray
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((log, index) => (
                <TableRow
                  hover
                  key={log?.id}
                  sx={{
                    backgroundColor:
                      index % 2
                        ? "background.bgTableEvenRow"
                        : "background.bgTableOddRow",
                    "&.MuiTableRow-root:hover": {
                      backgroundColor: "background.bgTableRowHover",
                    },
                  }}
                >
                  <TableCell sx={logsTableBodyCell}>
                    {dateTimeReformat(log?.createdAt)}
                  </TableCell>
                  <TableCell sx={logsTableBodyCell}>{log?.text}</TableCell>
                  <TableCell sx={logsTableBodyCell}>{log?.userName}</TableCell>
                  <TableCell sx={logsTableBodyCell}>{log?.room}</TableCell>
                </TableRow>
              ))
          ) : logType === 3 ? (
            filteredLogArray
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((log, index) => (
                <TableRow
                  hover
                  key={log?.id}
                  sx={{
                    backgroundColor:
                      index % 2
                        ? "background.bgTableEvenRow"
                        : "background.bgTableOddRow",
                    "&.MuiTableRow-root:hover": {
                      backgroundColor: "background.bgTableRowHover",
                    },
                  }}
                >
                  <TableCell sx={logsTableBodyCell}>
                    {dateTimeReformat(log?.createdAt)}
                  </TableCell>
                  <TableCell sx={logsTableBodyCell}>{log?.text}</TableCell>
                  <TableCell sx={logsTableBodyCell}>{log?.lock}</TableCell>
                  <TableCell sx={logsTableBodyCell}>{log?.room}</TableCell>
                </TableRow>
              ))
          ) : logType === 4 ? (
            filteredLogArray
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((log, index) => (
                <TableRow
                  hover
                  key={log?.id}
                  sx={{
                    backgroundColor:
                      index % 2
                        ? "background.bgTableEvenRow"
                        : "background.bgTableOddRow",
                    "&.MuiTableRow-root:hover": {
                      backgroundColor: "background.bgTableRowHover",
                    },
                  }}
                >
                  <TableCell sx={logsTableBodyCell}>
                    {dateTimeReformat(log?.createdAt)}
                  </TableCell>
                  <TableCell sx={logsTableBodyCell}>{log?.text}</TableCell>
                  <TableCell sx={logsTableBodyCell}>
                    {getRoomName(log?.roomId)}
                  </TableCell>
                  <TableCell sx={logsTableBodyCell}>
                    {getEventName(log?.procedureId)}
                  </TableCell>
                </TableRow>
              ))
          ) : null}
        </TableBody>
      </Table>
    </>
  );
};

export default LogsTableBody;
