import React from "react";
import { Hourglass } from "react-loader-spinner";
function HourGlassLoader({ height = 70, width = 70 }) {
  return (
    <Hourglass
      visible={true}
      height={height}
      width={width}
      ariaLabel="hourglass-loading"
      wrapperStyle={{}}
      wrapperClass=""
      colors={["#0054A4", "#EC313B"]}
    />
  );
}

export default HourGlassLoader;
