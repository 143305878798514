import { Dialog, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";

export const procedureHeading = {
  "&.MuiTypography-procedureTableHeadingBold_Button": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-procedureTableHeadingBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "20px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-procedureTableCell": {
    color: "white",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-procedureTableCellName_Index": {
    color: "primary.tableFontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-procedureTableCellName": {
    color: "#6D7B88",
    fontFamily: "Poppins",
    fontSize: "18px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-addDeleteIcons": {
    display: "flex",
    flexDirection: "column",
    color: "primary.fontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  // getContentAnchorEl: null,
  lable: {
    color: "red",
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

export const checkBoxLabel = {
  "&.MuiFormControlLabel-root": {
    ".MuiFormControlLabel-label": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.675px",
      color: "primary.fontColor",
      display: "flex",
    },
  },
};
export const textfieldStyle = {
  input: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  label: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green",
  // },
  "& .MuiOutlinedInput-root": {
    marginBottom: "2px",
    "& fieldset": {
      fontFamily: "Poppins",
      borderColor: "#EDEDED",
      borderRadius: 3,
    },
    "& label": {
      // fontFamily: "Poppins",
      // borderColor: "#EDEDED",
      color: "red",
    },
    "&:hover fieldset": {
      borderColor: "#7D8EA3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7D8EA3",
    },
  },
};
export const textfieldStyleInsideSection = {
  input: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  label: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green",
  // },
  "& .MuiOutlinedInput-root": {
    // marginBottom: "10px",
    "& fieldset": {
      fontFamily: "Poppins",
      borderColor: "#d5d5d5",
      borderRadius: 3,
    },
    "& label": {
      // fontFamily: "Poppins",
      // borderColor: "#EDEDED",
      color: "red",
    },
    "&:hover fieldset": {
      borderColor: "#7D8EA3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7D8EA3",
    },
  },
};
export const textfieldRulesStyle = {
  input: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  label: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green",
  // },
  "& .MuiOutlinedInput-root": {
    marginBottom: "10px",
    "& fieldset": {
      fontFamily: "Poppins",
      borderColor: "grey",
      borderRadius: 3,
    },
    "& label": {
      // fontFamily: "Poppins",
      // borderColor: "#EDEDED",
      color: "red",
    },
    "&:hover fieldset": {
      borderColor: "#7D8EA3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7D8EA3",
    },
  },
};
export const textfield = {
  input: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  label: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green",
  // },
  "& .MuiOutlinedInput-root": {
    // marginBottom: "10px",
    "& fieldset": {
      fontFamily: "Poppins",
      borderColor: "#7D8EA3",
      borderRadius: 3,
    },
    "&label": {
      // fontFamily: "Poppins",
      // borderColor: "#EDEDED",
    },
    "&:hover fieldset": {
      borderColor: "#7D8EA3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7D8EA3",
    },
  },
};

export const topbarHeading = {
  "&.MuiBox-root": {
    display: "flex",
    height: "38px",
    width: "420px",
    borderRadius: "0px",
    [`@media screen and (max-width: 900px)`]: {
      display: { xs: "none", sm: "none" },
    },
  },
  "&.MuiTypography-topHeading": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    marginLeft: "20px",
    fontSize: "25px",
    lineHeight: "37.5px",
    textTransform: "uppercase",
  },
  "&.MuiTypography-topHeadingSmall": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    marginLeft: "20px",
    fontSize: "22px",
    lineHeight: "37.5px",
    textTransform: "uppercase",
  },
  "&.MuiTypography-topHeadingVerySmall": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    marginLeft: "20px",
    fontSize: "16px",
    lineHeight: "37.5px",
    textTransform: "uppercase",
  },
  "&.MuiTypography-busyModeHeading": {
    color: "white",
    fontFamily: "Poppins",
    fontSize: "12px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-busyModeModalHeadingBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "20px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-busyModeModalHeadingBold_Button": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-busyModeModalList": {
    color: "#7D8EA3",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const dropdowns = {
  "&.MuiOutlinedInput-input": {
    borderColor: "white",
  },

  "&.MuiInputBase-formControl": {
    borderColor: "red",
  },
};

export const procedureTypographies = {
  "&.MuiTypography-inputOutputListHeading": {
    color: "black",
    fontSize: "15px",
    // lineHeight: "37.5px",
    fontFamily: "Poppins-SemiBold",
    textTransform: "capitalize",
  },
  "&.MuiTypography-inputOutputList": {
    display: "flex",
    flexDirection: "column",
    color: "primary.fontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};
export const procedureSelects = {
  "&.MuiSelect-select": {
    mb: "10px",
    borderRadius: 3,
    color: "#9B9B9B",
    textAlign: "left",
    ".MuiSvgIcon-root ": {
      fill: "#9B9B9B",
    },
    "&:after": {
      borderBottom: "red",
    },
    "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
      borderBottom: `2px solid green`,
    },
  },
};
export const tableSearch = {
  width: "100%",
  height: "38px",
  border: "1px solid rgba(169, 169, 169, 0.6)",
  borderRadius: "10px",
  backgroundColor: "background.bgSearchBar",
  fontFamily: "Poppins",
};
export const tableSearchIcon = {
  color: "rgba(151, 151, 151, 0.68);",
  paddingLeft: "3px",
};
export const tableSearchButton = {
  backgroundColor: "background.bgLayoutB",
  borderRadius: "10px",
  width: "100%",
  "&:hover": { backgroundColor: "background.bgLayoutB" },
};
export const tableContainer = {
  marginTop: "30px",
  borderRadius: "10px",
  [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
};
export const tableHeadColor = {
  backgroundColor: "background.bgLayoutA",
};
export const tableLoaderBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const tableDataCell = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};
export const tableNameAvatar = {
  backgroundColor: "background.bgLayoutA",
  color: "white",
  marginRight: "10px",
  mr: 1,
};
export const tableIconBox = { display: "flex", justifyContent: "end" };
export const tableIconButton = {
  backgroundColor: "background.bgLayoutA",
  "&:hover": { backgroundColor: "background.bgLayoutA" },
};
export const tableIconButtonWithMargin = {
  marginLeft: "10px",
  backgroundColor: "background.bgLayoutB",
  "&:hover": { backgroundColor: "background.bgLayoutB" },
};
export const formGroupStyle = { display: "flex", flexDirection: "row" };
export const multiSelectStyle = {
  // mb: "10px",
  borderRadius: 3,
  color: "#9B9B9B",
  textAlign: "left",
  ".MuiSvgIcon-root ": {
    fill: "#9B9B9B",
  },
  ":before": { borderBottomColor: "#9B9B9B", opacity: "48%" },
  ":after": { borderBottomColor: "#9B9B9B", opacity: "48%" },
  "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
    borderBottom: `2px solid #9B9B9B`,
    opacity: "48%",
  },
};
export const listBox = {
  mt: 2,
  padding: 1,
  borderRadius: 2,
  backgroundColor: "background.bgTableOddRow",
};
export const listBoxStyle = {
  maxHeight: "200px",
  overflow: "hidden",
  overflowY: "scroll",
  // backgroundColor: "background.bgTableOddRow",
  // borderRadius: 2,
  // padding: 1,
  [`::-webkit-scrollbar`]: {
    width: "0px",
    background: "transparent",
  },
};
export const ruleBoxStyle = {
  backgroundColor: "background.bgTableOddRow",
  borderRadius: 2,
  padding: 1,
  marginBottom: "10px",
  mt: 2,
};
export const listBoxStyleWithMargin = {
  maxHeight: "200px",
  overflow: "hidden",
  overflowY: "scroll",
  backgroundColor: "background.bgTableOddRow",
  borderRadius: 2,
  padding: 1,
  // marginBottom: "10px",
  [`::-webkit-scrollbar`]: {
    width: "0px",
    background: "transparent",
  },
};
export const listBoxStyleWithBoxShadow = {
  backgroundColor: "background.bgTableOddRow",
  borderRadius: 2,
  padding: 1,
  boxShadow: "1px 2px 9px",
};
export const ruleRemoveButton = {
  color: "background.bgLayoutB",
  cursor: "pointer",
  float: "right",
};
export const ruleAddButton = {
  color: "background.bgLayoutA",
  cursor: "pointer",
};
export const cancelButton = {
  background: "black",
  borderRadius: "10px",
  "&:hover": { backgroundColor: "black" },
};

export const iconContainer = {
  mb: 2,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
};

export const selectedIcon = {
  padding: 10,
  maxWidth: "100%",
  height: "200px",
  objectFit: "contain",
};

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, backgroundColor: "background.bgLayoutA" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
