import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { NavLink } from "react-router-dom";
import {
  sidebarDrawer,
  sidebarListItemButton,
  leftbarStyles,
  sidebarListItemButton_Roles,
  sidebarSubMenuListItemIcon,
  sidebarSubMenuTypography,
  sidebarSubMenuListItemIconWithPadding,
} from "../utils/muiComponentStyles";
import useLocales from "../hooks/useLocales";

const SubMenu = ({ sidebarData }) => {
  const { translate } = useLocales();
  const classes = leftbarStyles();

  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <div
      //It was Navlink, if sidebar case issues. Put NavLink back
      // to={sidebarData.path}
      style={{ textDecoration: "none" }}
      className={{ paper: classes.drawerPaper }}
    >
      <ListItem disablePadding>
        <ListItemButton
          onClick={sidebarData.children && showSubnav}
          disableRipple
          to={sidebarData.path}
          component={NavLink}
          sx={
            // subnav
            //   ? sidebarListItemButton
            //   : sidebarData.children
            //   ? sidebarListItemButton_Roles
            //   : sidebarListItemButton
            sidebarData.children
              ? sidebarListItemButton_Roles
              : sidebarListItemButton
          }
        >
          <ListItemIcon sx={sidebarSubMenuListItemIcon}>
            {sidebarData.icon}
          </ListItemIcon>
          <Typography sx={sidebarDrawer} variant="sidebarTypography">
            {translate(sidebarData.sidebarName)}
          </Typography>
          {sidebarData.children && (
            <Typography sx={sidebarSubMenuTypography}>
              {sidebarData.children && subnav
                ? sidebarData.iconClosed
                : sidebarData.children
                ? sidebarData.iconOpened
                : null}
            </Typography>
          )}
        </ListItemButton>
      </ListItem>
      {subnav &&
        sidebarData.children.map((item, index) => {
          return (
            <ListItem key={index} disablePadding>
              <ListItemButton
                // onClick={sidebarData.children && showSubnav}
                disableRipple
                to={item.path}
                component={NavLink}
                sx={{
                  "&.MuiListItemButton-root": {
                    textDecoration: "none",
                    borderRadius: "10px",
                    width: 200,
                    maxHeight: 50,
                    padding: 1,
                    margin: "2px",
                    marginLeft: sidebarData.children ? "20px" : "10px",
                    marginRight: "10px",
                    color: "white",
                    fontFamily: "Poppins",
                  },
                  "&:hover": {
                    backgroundColor: "background.bgSidebarHover",
                    borderRadius: "10px",
                    transition: "background 600ms linear",
                  },
                  "&.active": {
                    fontWeight: 1000,
                    backgroundColor: "background.bgLayoutB",
                    transition: "background 600ms linear",
                    fontFamily: "Poppins-SemiBold",
                  },
                }}
              >
                <ListItemIcon sx={sidebarSubMenuListItemIconWithPadding}>
                  {item.icon}
                </ListItemIcon>
                <Typography sx={sidebarDrawer} variant="sidebarTypography">
                  {translate(item.sidebarName)}
                </Typography>
              </ListItemButton>
            </ListItem>
          );
        })}
    </div>
  );
};

export default SubMenu;
