import React, { useEffect, useState } from "react";
import { dispatch, useSelector } from "../../redux/store";
import { getUsersProfileList } from "../../redux/slices/profileSlice";
// import { getProcedureList } from "../../redux/slices/procedureSlice";
import { getAllProfileRoomsList } from "../../redux/slices/RoomSlice";
import {
  Box,
  Grid,
  Table,
  Button,
  Select,
  TableRow,
  Checkbox,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  ListSubheader,
  InputAdornment,
  TableContainer,
  Toolbar,
  TableSortLabel,
} from "@mui/material";
import Loader from "../Loader/Loader";
import {
  MenuProps,
  listingBox,
  textfieldStyle,
  roleTypography,
  demoReadyTableHead,
  demoReadySubmitButton,
  demoReadyCancelButton,
  demoReadyTableContainer,
  demoReadyTableHeadCell,
  demoReadyListTypography,
  logTableContainer,
  logsTableHeadCell,
  logsTableBodyCell,
  tableLoaderBox,
  // demoReadyDialogButton,
  demoReadyDeleteLogButton,
  demoReadyGridTableContainer,
  // demoReadyTypography,
} from "./DemoReadyStyle";
import useLocales from "../../hooks/useLocales";
import SearchIcon from "@mui/icons-material/Search";
import CustomPagination from "../../hooks/useTablePagination";
import { getDemoReadyList } from "../../redux/slices/DemoReadySlice";
import { getLogsListForDashboard } from "../../redux/slices/logSlice";
import DeleteLogsDialog from "./DeleteLogsDialog";
import { getSensorListForReport } from "../../redux/slices/ReportSlice";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const durationList = [
  { value: 5, title: "5 seconds" },
  { value: 30, title: "30 seconds" },
  { value: 60, title: "1 minute" },
  { value: 120, title: "2 minute" },
  { value: 180, title: "3 minute" },
  { value: 300, title: "5 minute" },
];

function EnhancedTableToolbar({ setOpenDeleteDialog }) {
  const { translate } = useLocales();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        backgroundColor: "background.bgLayoutA",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
          fontFamily: "Poppins-semibold",
          fontSize: "16px",
          color: "white",
        }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {translate("logging")}
      </Typography>
      <Button
        type="submit"
        onClick={() => setOpenDeleteDialog(true)}
        size="large"
        sx={demoReadyDeleteLogButton}
      >
        {translate("deleteLogs")}
      </Button>
    </Toolbar>
  );
}
const Index = () => {
  // const classes = useStyles();
  const { translate } = useLocales();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [loader, setLoader] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [roomSearch, setRoomSearch] = useState("");
  const [sensorEventSearch, setSensorEventSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showTableData, setShowTable] = useState(false);
  const [profileSearch, setProfileSearch] = useState("");
  const [loaderForLogs, setLoaderForLogs] = useState(false);
  // const [procedureSearch, setProcedureSearch] = useState("");
  const [selectedRoomIds, setSelectedRoomIds] = useState([]);
  const [demoReadyObject, setDemoReadyObject] = useState({});
  const [profileRoomsList, setProfileRoomsList] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [demoReadyListArray, setDemoReadyListArray] = useState([]);
  // const [selectedProcedureIds, setSelectedProcedureIds] = useState([]);
  const [selectedSensorEventIds, setSelectedSensorEventIds] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState(
    localStorage.getItem("userDefaultId")
  );

  const { profileUsers } = useSelector((state) => state.profileReducer);
  const { allProfileRoomsList } = useSelector((state) => state.roomReducer);
  // const { procedureList } = useSelector((state) => state.procedureReducer);
  const { dashboardlogsList } = useSelector((state) => state.logReducer);
  const { sensorReportList } = useSelector((state) => state.reportReducer);

  const isAllRoomIds =
    profileRoomsList?.length > 0 &&
    selectedRoomIds?.length === profileRoomsList?.length;

  // const isAllProcedureIds =
  //   procedureList?.length > 0 &&
  //   selectedProcedureIds?.length ===
  //     procedureList?.filter(
  //       (el) => selectedRoomIds?.includes(el.roomId) && el.active
  //     ).length;

  const isAllSensorEventIds =
    sensorReportList?.length > 0 &&
    selectedSensorEventIds?.length === sensorReportList?.length;

  useEffect(() => {
    dispatch(getUsersProfileList());
    dispatch(getAllProfileRoomsList());
    dispatch(getSensorListForReport());
  }, []);

  useEffect(() => {
    handleTableRecordDispatcher();
  }, []);

  // useEffect(() => {
  // dispatch(getProcedureList(handleApiRes, selectedProfileId));
  // }, [selectedProfileId]);

  const getAllChildren = (profileId, profiles = []) => {
    // Fetch the profile by id
    const profile = profileUsers.find((el) => {
      return el.id == profileId;
    });

    if (profile) {
      // Add the profile to the result list
      profiles.push(profile);

      // Fetch child profiles

      const children = profileUsers.filter((el) => {
        return el.parentId == profile.id;
      });

      // Recursively fetch children of each child
      for (const child of children) {
        getAllChildren(child.id, profiles);
      }
    }

    return profiles;
  };

  const getProfileIdWithChildrenIds = (profileId) => {
    // Start the recursion from the given profileId
    const profiles = getAllChildren(profileId);

    let profileIds = profiles.map((el) => {
      return el.id;
    });
    return profileIds;
  };

  const getAllRoomsForProfile = (profileId) => {
    let profileIds = getProfileIdWithChildrenIds(profileId);
    let profileRooms = [];
    allProfileRoomsList?.map((item) => {
      if (profileIds.includes(item?.profileId)) profileRooms.push(item);
    });
    return profileRooms;
  };

  useEffect(() => {
    if (selectedProfileId) {
      let profileRooms = getAllRoomsForProfile(selectedProfileId);
      setProfileRoomsList(profileRooms);
    }
  }, [selectedProfileId, allProfileRoomsList]);

  const handleCloseDialog = () => setOpenDeleteDialog(false);

  const handleSelectProfile = (event) => {
    setSelectedProfileId(event.target.value);
    setSelectedRoomIds([]);
  };

  const handleSelectedRoomChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "allRoomIds") {
      setSelectedRoomIds(
        selectedRoomIds.length === profileRoomsList.length
          ? []
          : profileRoomsList.map((i) => i.id)
      );
      return;
    }
    setSelectedRoomIds(value);
    // setSelectedProcedureIds([]);
  };

  const handleSelectedSensorEventChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "allSensorEventIds") {
      setSelectedSensorEventIds(
        selectedSensorEventIds.length === sensorReportList.length
          ? []
          : sensorReportList.map((i) => i.sensorEventId)
      );
      return;
    }
    setSelectedSensorEventIds(value);
  };

  const handleDemoReadyObject = (event) => {
    const regex = /^[0-9\b]+$/;
    event.preventDefault();
    if (event.target.value === "" || regex.test(event.target.value)) {
      setDemoReadyObject({
        ...demoReadyObject,
        [event.target.name]: event.target.value,
      });
    }
  };

  // const handleSelectedProcedureChange = (event) => {
  //   const value = event.target.value;
  //   if (value[value.length - 1] === "allProcedureIds") {
  //     setSelectedProcedureIds(
  //       selectedProcedureIds.length ===
  //         procedureList.filter(
  //           (el) => selectedRoomIds?.includes(el.roomId) && el.active
  //         )?.length
  //         ? []
  //         : procedureList
  //             .filter((el) => selectedRoomIds?.includes(el.roomId) && el.active)
  //             .map((i) => i.id)
  //     );
  //     return;
  //   }
  //   setSelectedProcedureIds(value);
  // };

  let iteration = 0;
  let initialData = [];

  const handleApiRes = (data) => {
    iteration = iteration + 1;

    if (data?.status) {
      initialData = initialData.concat(data?.data);
      setDemoReadyListArray(initialData);
    }
    if (iteration < demoReadyObject.counter) {
      const timeoutId = setTimeout(
        (handleSubmit) => {
          handleSubmit();
        },
        demoReadyObject.duration * 1000,
        handleSubmit
      );
      // Store the timeout identifier in state
      setTimerId(timeoutId);

      // Clear the timeout when the component unmounts
      // return () => {
      //   clearTimeout(timeoutId);
      // };
    } else {
      setLoader(false);
    }
  };

  const handleClearButton = () => {
    clearTimeout(timerId);
    setTimerId(null);
    setLoader(false);
  };

  const handleSubmit = () => {
    //trun it true if you wanna see live demo table
    setShowTable(false);

    setLoader(true);
    let finalObject = {
      deviceTypeEventIds: selectedSensorEventIds,
      roomIds: selectedRoomIds,
    };
    dispatch(getDemoReadyList(finalObject, handleApiRes));

    //To Call dashboard Api records
    setTimeout(() => {
      handleTableRecordDispatcher();
    }, 2000);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleTableRecordDispatcher = () => {
    setLoaderForLogs(true);
    const today = dateTimeApiformat(new Date().toJSON());
    const temp = new Date(new Date());
    temp.setDate(temp.getDate() - 30);
    const yesterday = dateTimeApiformat(temp.toJSON());
    dispatch(
      getLogsListForDashboard(
        yesterday + " " + new Date().getHours() + ":" + new Date().getMinutes(),
        today + " " + new Date().getHours() + ":" + new Date().getMinutes(),
        handleApiResForAllLogs
      )
    );
  };

  const handleApiResForAllLogs = () => {
    setLoaderForLogs(false);
  };

  const dateTimeApiformat = (dateStr) => {
    const [yyyy, mm, dd] = dateStr.split(/[/:\-T]/);
    const newDT = `${yyyy}-${mm}-${dd}`;
    return newDT;
  };

  const dateTimeReformat = (dateStr) => {
    // Create a Date object from the provided date string
    // const date = new Date(dateStr);

    // // Format the date and time in local time zone
    // const options = {
    //   day: "2-digit",
    //   month: "2-digit",
    //   year: "numeric",
    //   hour: "2-digit",
    //   minute: "2-digit",
    //   second: "2-digit",
    //   hour12: false,
    // };

    // // Format the date-time string using toLocaleString
    // const newDT = date.toLocaleString("en-US", options);
    // return newDT;
    const formattedDate = dayjs(dateStr).utc().format("DD-MM-YYYY | HH:mm:ss");
    return formattedDate;
  };

  const { counter, duration } = demoReadyObject;

  return (
    <>
      <TableContainer sx={demoReadyGridTableContainer}>
        <Table aria-labelledby="tableTitle">
          <TableHead sx={demoReadyTableHead}>
            <TableCell sx={{ ...demoReadyTableHeadCell, py: "20px" }}>
              {translate("testingAlerts")}
            </TableCell>
          </TableHead>
        </Table>
      </TableContainer>
      <Grid mt={2} container spacing={1}>
        {/* select-profile selector */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl sx={textfieldStyle} fullWidth>
            <InputLabel id="profile-label-id">
              {translate("selectLocation")}
            </InputLabel>
            <Select
              id="profile-label"
              disabled={loader}
              label={translate("selectLocation")}
              onChange={handleSelectProfile}
              onClose={() => setProfileSearch("")}
              value={selectedProfileId || ""}
              MenuProps={MenuProps}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  // Autofocus on textfield
                  autoFocus={true}
                  sx={{ ...textfieldStyle, paddingBottom: 0 }}
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ mt: "3px" }} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setProfileSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {profileUsers
                ?.filter((profile) =>
                  profile?.name
                    .toString()
                    .toLowerCase()
                    ?.includes(profileSearch.toLowerCase())
                )
                ?.map((profile) => (
                  <MenuItem key={profile.id} value={profile.id}>
                    <Typography sx={demoReadyListTypography}>
                      {profile.name}
                    </Typography>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Select Room Selector */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl sx={textfieldStyle} fullWidth>
            <InputLabel id="demo-multiple-checkbox-label">
              {translate("rooms")}
            </InputLabel>
            <Select
              disabled={!selectedProfileId || loader}
              label={translate("rooms")}
              sx={{ width: "100%" }}
              required
              id="demo-multiple-checkbox"
              multiple
              MenuProps={MenuProps}
              value={selectedRoomIds || ""}
              onChange={handleSelectedRoomChange}
              onClose={() => setRoomSearch("")}
              renderValue={(selected) =>
                profileRoomsList
                  .filter((room) => selected?.includes(room?.id))
                  .map((item) => item?.name)
                  .join(", ")
              }
            >
              <ListSubheader>
                <TextField
                  autoFocus
                  size="small"
                  sx={{ ...textfieldStyle, paddingBottom: 0 }}
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ mt: "3px" }} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setRoomSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              <MenuItem
                value="allRoomIds"
                sx={{
                  background: isAllRoomIds ? "rgba(25, 118, 210, 0.08)" : "",
                }}
              >
                <Typography sx={demoReadyListTypography}>
                  <Checkbox checked={isAllRoomIds} />
                  {translate("selectAll")}
                </Typography>
              </MenuItem>
              {profileRoomsList
                ?.filter((profile) =>
                  profile?.name
                    .toString()
                    .toLowerCase()
                    ?.includes(roomSearch.toLowerCase())
                )
                ?.map((room) => (
                  <MenuItem key={room.id} value={room.id}>
                    <Checkbox checked={selectedRoomIds.indexOf(room.id) > -1} />
                    <Typography sx={demoReadyListTypography}>
                      {room.name}
                    </Typography>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl sx={textfieldStyle} fullWidth>
            <InputLabel id="demo-multiple-checkbox-label">
              {translate("procedures")}
            </InputLabel>
            <Select
              disabled={!selectedProfileId || loader}
              label={translate("procedures")}
              sx={{ width: "100%" }}
              required
              id="demo-multiple-checkbox"
              multiple
              MenuProps={MenuProps}
              value={selectedSensorEventIds || ""}
              onChange={handleSelectedSensorEventChange}
              onClose={() => setSensorEventSearch("")}
              renderValue={(selected) =>
                sensorReportList
                  .filter((sensor) => selected?.includes(sensor?.sensorEventId))
                  .map((item) => item?.eventName)
              }
            >
              <ListSubheader>
                <TextField
                  autoFocus
                  size="small"
                  sx={{ ...textfieldStyle, paddingBottom: 0 }}
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ mt: "3px" }} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSensorEventSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              <MenuItem
                value="allSensorEventIds"
                sx={{
                  background: isAllSensorEventIds
                    ? "rgba(25, 118, 210, 0.08)"
                    : "",
                }}
              >
                <Typography sx={demoReadyListTypography}>
                  <Checkbox checked={isAllSensorEventIds} />
                  {translate("selectAll")}
                </Typography>
              </MenuItem>
              {sensorReportList
                ?.filter((sensorEvent) =>
                  sensorEvent?.eventName
                    .toString()
                    .toLowerCase()
                    ?.includes(sensorEventSearch.toLowerCase())
                )
                ?.map((roomSensorEvent) => (
                  <MenuItem
                    key={roomSensorEvent.sensorEventId}
                    value={roomSensorEvent.sensorEventId}
                  >
                    <Checkbox
                      checked={
                        selectedSensorEventIds.indexOf(
                          roomSensorEvent.sensorEventId
                        ) > -1
                      }
                    />
                    <Typography sx={demoReadyListTypography}>
                      {roomSensorEvent.eventName}
                    </Typography>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Select Procedure Selector */}
        {/* <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl sx={textfieldStyle} fullWidth>
            <InputLabel id="selected-procedure-ids">
              {translate("procedures")}
            </InputLabel>
            <Select
              disabled={selectedRoomIds.length < 1 || loader}
              label={translate("procedures")}
              sx={{ width: "100%" }}
              required
              labelId="selected-procedure-ids"
              id="selected-procedure"
              multiple
              value={selectedProcedureIds || ""}
              MenuProps={MenuProps}
              onChange={handleSelectedProcedureChange}
              onClose={() => setProfileSearch("")}
              renderValue={(selected) =>
                procedureList
                  .filter((procedure) => selected?.includes(procedure?.id))
                  .map((item) => item?.name)
                  .join(", ")
              }
            >
              <ListSubheader>
                <TextField
                  autoFocus
                  size="small"
                  sx={{ ...textfieldStyle, paddingBottom: 0 }}
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ mt: "3px" }} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setProcedureSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              <MenuItem
                value="allProcedureIds"
                sx={{
                  background: isAllProcedureIds
                    ? "rgba(25, 118, 210, 0.08)"
                    : "",
                }}
              >
                <Typography sx={demoReadyListTypography}>
                  <Checkbox checked={isAllProcedureIds} />
                  {translate("selectAll")}
                </Typography>
              </MenuItem>
              {procedureList
                ?.filter(
                  (el) => selectedRoomIds?.includes(el.roomId) && el.active
                )
                ?.filter((el) =>
                  el?.name
                    .toString()
                    .toLowerCase()
                    ?.includes(procedureSearch.toLowerCase())
                )
                ?.map((procedure) => (
                  <MenuItem key={procedure.id} value={procedure.id}>
                    <Checkbox
                      checked={selectedProcedureIds.indexOf(procedure.id) > -1}
                    />
                    <Typography sx={demoReadyListTypography}>
                      {procedure.roomConfiguration.name +
                        " - " +
                        procedure.name}
                    </Typography>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid> */}

        {/* Duration field */}
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <FormControl sx={textfieldStyle} fullWidth>
            <InputLabel id="select-duration-ids">
              {translate("duration")}
            </InputLabel>
            <Select
              label={translate("duration")}
              sx={{ width: "100%" }}
              disabled={loader}
              required
              labelId="select-duration-ids"
              id="select-duration"
              value={duration || ""}
              name="duration"
              MenuProps={MenuProps}
              onChange={handleDemoReadyObject}
            >
              {durationList.map((duration, idx) => (
                <MenuItem key={idx} value={duration.value}>
                  <Typography sx={demoReadyListTypography}>
                    {duration.title}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* counter field */}
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextField
            sx={textfieldStyle}
            disabled={loader}
            id="counter"
            label={translate("counter")}
            name="counter"
            type="text"
            value={counter || ""}
            onChange={(event) => handleDemoReadyObject(event)}
          />
        </Grid>

        {/* submit button */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box display={"flex"} justifyContent={"flex-end"} mt={"2px"}>
            {!loader && (
              <Button
                type="submit"
                onClick={handleSubmit}
                size="large"
                sx={demoReadySubmitButton}
                disabled={
                  !selectedProfileId ||
                  selectedSensorEventIds.length < 1 ||
                  // !demoReadyObject.counter ||
                  // !demoReadyObject.duration ||
                  loader
                }
              >
                {translate("test")}
              </Button>
            )}
            {loader && (
              <Button
                type="submit"
                onClick={handleClearButton}
                size="large"
                sx={demoReadyCancelButton}
                disabled={!loader}
              >
                {translate("cancel")}
              </Button>
            )}
          </Box>
        </Grid>

        {/* cancel button */}
        {/* <Grid item xs={0} sm={0} md={0} lg={4} />
        <Grid item xs={0} sm={0} md={0} lg={4} /> */}

        {/* delete logs dialog button */}
        {/* <Grid item xs={12} sm={12} md={6} lg={4}>
          <Button
            type="submit"
            onClick={() => setOpenDeleteDialog(true)}
            size="large"
            sx={demoReadyDialogButton}
          >
            {translate("deleteLogs")}
          </Button>
        </Grid> */}
      </Grid>

      {/* selected rooms box */}
      {selectedRoomIds?.length >= 1 && (
        <Box sx={listingBox}>
          <Typography sx={demoReadyListTypography} variant="Heading">
            {translate("selectedRooms")}
          </Typography>
          <br></br>
          <Typography
            sx={roleTypography}
            variant="inputOutputList"
            // key={index}
          >
            {profileRoomsList.filter((i) => selectedRoomIds?.includes(i.id))
              ?.length > 0 &&
              profileRoomsList
                .filter((i) => selectedRoomIds?.includes(i.id))
                ?.map((item) => item?.name)
                ?.join(", ")}
          </Typography>
        </Box>
      )}

      {/* selected events box */}
      {selectedSensorEventIds?.length >= 1 && (
        <Box sx={listingBox}>
          <Typography sx={demoReadyListTypography} variant="Heading">
            {translate("selectedEvents")}
          </Typography>
          <br></br>
          <Typography
            sx={roleTypography}
            variant="inputOutputList"
            // key={index}
          >
            {sensorReportList.filter((i) =>
              selectedSensorEventIds?.includes(i.sensorEventId)
            )?.length > 0 &&
              sensorReportList
                .filter((i) =>
                  selectedSensorEventIds?.includes(i.sensorEventId)
                )
                ?.map((item) => item?.eventName)
                ?.join(", ")}
          </Typography>
        </Box>
      )}
      {showTableData && (
        <TableContainer sx={demoReadyTableContainer}>
          <Table aria-labelledby="tableTitle">
            <TableHead sx={demoReadyTableHead}>
              <TableRow>
                <TableCell sx={demoReadyTableHeadCell}>
                  {translate("text")}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody sx={{ minWidth: 750 }}>
              {demoReadyListArray?.map((log, index) => (
                <TableRow
                  hover
                  key={log?.id}
                  sx={{
                    backgroundColor: log.error
                      ? "red"
                      : index % 2
                      ? "background.bgTableEvenRow"
                      : "background.bgTableOddRow",
                    "&.MuiTableRow-root:hover": {
                      backgroundColor: log.error ? "#e60000" : "#DAEAF9;",
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      "&.MuiTableCell-root": {
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        color: log.error ? "white" : "#6D7B88",
                      },
                    }}
                  >
                    {log?.text} {log.error && `(${log.reason})`}
                  </TableCell>
                </TableRow>
              ))}
              {loader && (
                <TableRow
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    margin: "0 auto",
                  }}
                >
                  <Loader />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Box sx={{ marginTop: "20px", width: "100%" }}>
        <EnhancedTableToolbar setOpenDeleteDialog={setOpenDeleteDialog} />
        <TableContainer sx={logTableContainer}>
          <Table aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                <TableCell sx={logsTableHeadCell}>
                  <TableSortLabel>{translate("date")}</TableSortLabel>
                </TableCell>
                <TableCell sx={logsTableHeadCell}>
                  <TableSortLabel
                    direction={order}
                    onClick={() => setOrder(order == "asc" ? "desc" : "asc")}
                  >
                    {translate("text")}
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={logsTableHeadCell}>
                  <TableSortLabel>{translate("category")}</TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loaderForLogs ? (
                <TableRow
                  hover
                  sx={{
                    backgroundColor: "background.bgTableRowEven",
                    "&.MuiTableRow-root:hover": {
                      backgroundColor: "background.bgTableRowHover",
                    },
                  }}
                >
                  <TableCell align="center" colSpan={4} sx={logsTableBodyCell}>
                    <Box style={tableLoaderBox}>
                      <Loader />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : dashboardlogsList?.length < 1 ? (
                <TableRow
                  hover
                  sx={{
                    backgroundColor: "background.bgTableRowEven",
                    "&.MuiTableRow-root:hover": {
                      backgroundColor: "background.bgTableRowHover",
                    },
                  }}
                >
                  <TableCell align="center" colSpan={4} sx={logsTableBodyCell}>
                    No Logs Available
                  </TableCell>
                </TableRow>
              ) : (
                dashboardlogsList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((log, index) => (
                    <TableRow
                      hover
                      key={log?.id}
                      sx={{
                        backgroundColor:
                          index % 2
                            ? "background.bgTableEvenRow"
                            : "background.bgTableOddRow",
                        "&.MuiTableRow-root:hover": {
                          backgroundColor: "background.bgTableRowHover",
                        },
                      }}
                    >
                      <TableCell sx={logsTableBodyCell}>
                        {dateTimeReformat(log?.createdAt)}
                      </TableCell>
                      <TableCell sx={logsTableBodyCell}>{log?.text}</TableCell>
                      <TableCell sx={logsTableBodyCell}>
                        {log?.category}
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          {/* <LogsTableBody
            logType={logType}
            filteredLogArray={filteredLogArray}
            page={page}
            rowsPerPage={rowsPerPage}
            roomsList={roomsList}
            loading={loading}
            procedureList={procedureList}
          /> */}
        </TableContainer>
        <CustomPagination
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          filteredList={dashboardlogsList}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>
      {openDeleteDialog && (
        <DeleteLogsDialog
          openDeleteDialog={openDeleteDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </>
  );
};

export default Index;
