import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import useLocales from "../../hooks/useLocales";
import {
  MenuProps,
  textfieldStyle,
  topbarHeading,
} from "../../utils/muiComponentStyles";
import { useDispatch } from "../../redux/store";
import { muteRoom } from "../../redux/slices/AlarmSlice";
import ToasterContext from "../../utils/context/toasterContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        background: "#0054A4",
        color: "white",
        fontWeight: "bold",
        fontFamily: "Poppins-semibold",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const muteTimeArray = [
  { time: "15 minutes", value: 15 },
  { time: "30 minutes", value: 30 },
  { time: "1 hour", value: 60 },
  { time: "24 hour", value: 1440 },
  { time: "Forever", value: "forever" },
  { time: "Unmute", value: "unmute" },
];

const MuteRoomDialog = ({ roomDetail, openMuteModal, setOpenMuteModal }) => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const [loader, setLoader] = useState(false);
  const fireToasterContext = useContext(ToasterContext);
  const [muteTimeState, setMuteTimeState] = useState();

  useEffect(() => {
    //Cleaning up states each time dialog opens
    setMuteTimeState("");
    console.log(loader);
  }, [openMuteModal]);

  const handleMuteRoom = () => {
    let data = {
      suppressed: muteTimeState === "unmute" ? false : true,
      supressedForMinutes: muteTimeState,
      suppressedForever: muteTimeState === "forever" ? true : false,
    };

    // console.log("sub", data);

    dispatch(muteRoom(roomDetail?.id, data, handleApiRes));
  };

  const handleChange = (event) => {
    // const re = /^[0-9\b]+$/;
    // if (re.test(event.target.value))
    setMuteTimeState(event.target.value);
  };

  const handleApiRes = (data) => {
    setLoader(false);

    if (data.status) {
      console.log("inside if", data);
      fireToasterContext.fireToasterHandler(
        true,
        data?.message ? data.message : "Room Muted successfully"
      );
      setOpenMuteModal(false);
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
      setOpenMuteModal(false);
    }
  };

  console.log("room details", roomDetail);

  return (
    <Container>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={openMuteModal}
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setOpenMuteModal(false)}
        >
          <Typography sx={topbarHeading} variant="busyModeModalHeadingBold">
            {translate("muteRoom")}
          </Typography>
        </BootstrapDialogTitle>
        <ValidatorForm
          style={{ width: "100%" }}
          onSubmit={handleMuteRoom}
          onError={(errors) => console.log(errors)}
        >
          <DialogContent dividers>
            <FormControl sx={textfieldStyle} fullWidth>
              <InputLabel id="select-busy-mode-time-ids">
                {translate("muteTime")}
              </InputLabel>
              <Select
                label={translate("muteTime")}
                required
                labelId="select-busy-mode-time-ids"
                id="select-busy-mode-time"
                value={muteTimeState || ""}
                name="muteTimeState"
                MenuProps={MenuProps}
                onChange={(event) => handleChange(event)}
              >
                {muteTimeArray.map((time, index) => (
                  <MenuItem key={index} value={time.value}>
                    <Typography sx={topbarHeading} variant="busyModeModalList">
                      {time.time}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              disableRipple
              sx={{
                color: "white",
                borderRadius: "10px",
                background: "black",
                "&:hover": { backgroundColor: "black" },
              }}
              autoFocus
              onClick={() => setOpenMuteModal(false)}
            >
              <Typography
                sx={topbarHeading}
                variant="busyModeModalHeadingBold_Button"
              >
                {translate("cancel")}
              </Typography>
            </Button>
            <Button
              type="submit"
              sx={{
                background: "#0054A4",
                borderRadius: "10px",
                color: "white",
                "&:hover": { backgroundColor: "#0054A4" },
                "&:disabled": {
                  backgroundColor: "#979797",
                },
              }}
            >
              <Typography
                sx={topbarHeading}
                variant="busyModeModalHeadingBold_Button"
              >
                {translate("submit")}
              </Typography>
            </Button>
          </DialogActions>
        </ValidatorForm>
      </BootstrapDialog>
    </Container>
  );
};

export default MuteRoomDialog;
