export const profileMainBox = {
  "&.MuiBox-root": {
    // margin: "10px",
    padding: "15px",
  },
};

export const profileSearchField = {
  "&.MuiInputBase-root": {
    width: "100%",
    height: "38px",
    border: "1px solid rgba(169, 169, 169, 0.6)",
    borderRadius: "10px",
    backgroundColor: "background.bgSearchBar",
    fontFamily: "Poppins",
  },
};

export const profileSearchFieldIcon = {
  "&.MuiSvgIcon-root": {
    color: "rgba(151, 151, 151, 0.68);",
    paddingLeft: "3px",
  },
};

export const profileCreateButton = {
  "&.MuiButton-root": {
    backgroundColor: "background.bgLayoutB",
    borderColor: "background.bgLayoutB",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "15px",
    color: "#FFFFFF",
    // marginRight: "10px",
    width: "100%",
    textTransform: "capitalize",
    "&:hover": {
      background: "background.bgLayoutB",
      borderRadius: "10px",
    },
  },
};

export const profileTableContainer = {
  "&.MuiTableContainer-root": {
    borderRadius: "10px",
    fontFamily: "Poppins",
    marginTop: "30px",
  },
};

export const profileTableHead = {
  "&.MuiTableHead-root": {
    backgroundColor: "background.bgLayoutA",
    border: "#EC313B",
  },
};

export const profileTableHeadCell = {
  "&.MuiTableCell-root": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "15px",
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
};

export const profileTableCell = {
  "&.MuiTableCell-root": {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "primary.tableFontColor",
  },
};

export const profileNameAvatar = {
  "&.MuiAvatar-root": {
    backgroundColor: "background.bgLayoutA",
    color: "white",
    height: "36px",
    width: "36px",
    textTransform: "uppercase",
  },
};

export const profileTypographiesSelect = {
  "&.MuiTypography-inputOutputListHeading": {
    color: "black",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    // lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-inputOutputList": {
    display: "flex",
    flexDirection: "column",
    color: "primary.fontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const createProfileField = {
  width: "100%",
  marginBottom: "10px",
  textTransform: "capitalize",
  "& .MuiFormLabel-root": {
    fontFamily: "Poppins",
  },
  ".MuiInputBase-root": {
    fontFamily: "Poppins",
    fontSize: "15px",
    color: "#7D8EA3",
    borderRadius: "10px",
  },
};

export const editProfileField = {
  "&.MuiFormControl-root": {
    width: "100%",
    marginBottom: "10px",
    "& .MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    ".MuiInputBase-root": {
      fontFamily: "Poppins",
      fontSize: "15px",
      color: "#7D8EA3",
    },
  },
};

export const editProfileTypographiesSelect = {
  "&.MuiTypography-inputOutputListHeading": {
    color: "black",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    // lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-inputOutputList": {
    display: "flex",
    flexDirection: "column",
    color: "#7D8EA3",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-addDeleteIcons": {
    display: "flex",
    flexDirection: "column",
    color: "primary.fontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const createProfileNoteInputTypographies = {
  "&.MuiTypography-Heading": {
    color: "black",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    // lineHeight: "37.5px",
    textTransform: "capitalize",
  },
};

export const editIconButtonsProfile = {
  "&.MuiButtonBase-root": {
    backgroundColor: "background.bgLayoutA",
    color: "white",
    height: "36px",
    width: "36px",
  },
};

export const deleteIconButtonsProfile = {
  "&.MuiButtonBase-root": {
    backgroundColor: "background.bgLayoutB",
    color: "white",
    height: "36px",
    width: "36px",
  },
};

export const ruleRemoveButton = {
  background:
    "radial-gradient(ellipse at center,  white 0%,white 47%,#ff0000 47%,#23bc2b 47%,transparent 48%)",
  color: "background.bgLayoutB",
  cursor: "pointer",
  float: "right",
  ml: 1,
};
export const ruleAddButton = {
  // background:
  //   "radial-gradient(ellipse at center,  white 0%,white 47%,#ff0000 47%,#23bc2b 47%,transparent 48%)",
  // color: "black",
  color: "background.bgLayoutA",
  cursor: "pointer",
  mr: 1,
};

// create profile style
export const profileHeading = {
  "&.MuiTypography-profileTableHeadingBold": {
    color: "white",
    fontFamily: "Poppins-SemiBold",
    fontSize: "20px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-profileTableCell": {
    color: "white",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-profileTableCellName": {
    color: "primary.fontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const checkBoxLabel = {
  "&.MuiFormControlLabel-root": {
    ".MuiFormControlLabel-label": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.675px",
      color: "#7D8EA3",
      display: "flex",
    },
  },
};

export const cancelProfileModalButton = {
  "&.MuiButtonBase-root": {
    borderRadius: "10px",
    backgroundColor: "black",
    fontFamily: "Poppins-semibold",
    fontSize: "15px",
    color: "white",
    textTransform: "capitalize",
  },
};

export const createProfileModalButton = {
  "&.MuiButtonBase-root": {
    backgroundColor: "background.bgLayoutA",
    fontFamily: "Poppins-semibold",
    fontSize: "15px",
    color: "white",
    borderRadius: "10px",
    textTransform: "capitalize",
    "&:hover": {
      background: "background.bgLayoutA",
      borderRadius: "10px",
    },
    "&:disabled": {
      backgroundColor: "#979797;",
    },
  },
};

export const updateProfileModalButton = {
  "&.MuiButtonBase-root": {
    backgroundColor: "background.bgLayoutA",
    fontFamily: "Poppins-semibold",
    fontSize: "15px",
    color: "white",
    borderRadius: "10px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "background.bgLayoutA",
      borderRadius: "10px",
    },
    "&:disabled": {
      backgroundColor: "#979797;",
    },
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  // getContentAnchorEl: null,
  lable: {
    color: "red",
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

export const roleTypography = {
  "&.MuiTypography-inputOutputListHeading": {
    color: "black",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    // lineHeight: "37.5px",
    textTransform: "capitalize",
  },
  "&.MuiTypography-inputOutputList": {
    display: "flex",
    flexDirection: "column",
    color: "primary.fontColor",
    fontFamily: "Poppins",
    fontSize: "15px",
    textTransform: "capitalize",
  },
};

export const cancelModalButtonsProfile = {
  "&.MuiButtonBase-root": {
    backgroundColor: "black",
    borderRadius: "10px",
    color: "white",
    height: "36px",
    fontFamily: "Poppins-semibold",
    textTransform: "capitalize",
  },
};

export const deleteProfileModalDialogTitle = {
  "&.MuiDialogTitle-root": {
    backgroundColor: "#0054A4",
    color: "white",
    fontFamily: "Poppins-semibold",
    fontSize: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

export const deleteModalButtonProfile = {
  "&.MuiButtonBase-root": {
    backgroundColor: "background.bgLayoutB",
    borderRadius: "10px",
    color: "white",
    height: "36px",
    fontFamily: "Poppins-semibold",
    textTransform: "capitalize",
  },
};

export const selectDropdownStyle = {
  borderRadius: 3,
  color: "#9B9B9B",
  textAlign: "left",
  ".MuiSvgIcon-root ": {
    fill: "#9B9B9B",
  },
  ":before": {
    borderBottomColor: "#9B9B9B",
    opacity: "48%",
  },
  ":after": {
    borderBottomColor: "#9B9B9B",
    opacity: "48%",
  },
  "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
    borderBottom: `2px solid #9B9B9B`,
    opacity: "48%",
  },
};

export const textfieldStyle = {
  width: "100%",
  input: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  label: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green",
  // },
  "& .MuiOutlinedInput-root": {
    marginBottom: "10px",
    "& fieldset": {
      fontFamily: "Poppins",
      borderColor: "#EDEDED",
      borderRadius: 3,
    },
    "& label": {
      // fontFamily: "Poppins",
      // borderColor: "#EDEDED",
      color: "red",
    },
    "&:hover fieldset": {
      borderColor: "#7D8EA3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7D8EA3",
    },
  },
};
export const textfieldStyleWithoutMargin = {
  width: "100%",
  input: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  label: {
    color: "primary.fontColor",
    // background: "#FFFFFF",
    fontFamily: "Poppins",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "green",
  // },
  "& .MuiOutlinedInput-root": {
    // marginBottom: "10px",
    "& fieldset": {
      fontFamily: "Poppins",
      borderColor: "#EDEDED",
      borderRadius: 3,
    },
    "& label": {
      // fontFamily: "Poppins",
      // borderColor: "#EDEDED",
      color: "red",
    },
    "&:hover fieldset": {
      borderColor: "#7D8EA3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7D8EA3",
    },
  },
};
