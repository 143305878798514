import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axiosConfig";
import { dispatch } from "../store";

// const headers = {
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
//   timezoneutcoffset: new Date().getTimezoneOffset(),
// };
const initialState = {
  alarmsList: [],
  alarmsProcedureDetailList: [],
  dashboardDataList: [],
};

const slice = createSlice({
  name: "log",
  initialState,
  reducers: {
    getAlarms(state, action) {
      state.alarmsList = action.payload.data;
    },

    getAlarmsProcedureDetail(state, action) {
      state.alarmsProcedureDetailList = action.payload;
    },
    getDashboardData(state, action) {
      state.dashboardDataList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getAlarmsList(handleAlarmResponse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/alarms?fromPortal=${true}`
        // { headers }
      );
      dispatch(slice.actions.getAlarms(response.data));
      if (handleAlarmResponse) {
        handleAlarmResponse();
      }
    } catch (error) {
      if (handleAlarmResponse) {
        handleAlarmResponse();
      }
      console.log(error);
    }
  };
}

export function getAlarmsProcedureList(id, handleGetAlarmsProcedureResponse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/alarms/details?roomId=${id}`
        // { headers }
      );
      dispatch(
        slice.actions.getAlarmsProcedureDetail(
          response.data.data.procedureConfigurations
        )
      );
      if (handleGetAlarmsProcedureResponse)
        handleGetAlarmsProcedureResponse(response.data.data);
    } catch (error) {
      if (handleGetAlarmsProcedureResponse)
        handleGetAlarmsProcedureResponse(error);
    }
  };
}

export function clearAlarmsProcedure(
  roomId,
  alarmId,
  handleClearAlarmProcedureResponse,
  handleGetAlarmsProcedureResponse
) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/alarms/clear?roomId=${roomId}&alarmId=${alarmId}`,
        {}
        // { headers }
      );
      handleClearAlarmProcedureResponse(response.data, roomId);
      dispatch(getAlarmsList());
      dispatch(
        getAlarmsProcedureList(roomId, handleGetAlarmsProcedureResponse)
      );
      // handleGetAlarmsProcedureResponse(response.data.data);
    } catch (error) {
      handleClearAlarmProcedureResponse(error.response.data.message);
      console.log(error);
    }
  };
}

export function createAlarm(data, handleApiResponse) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/alarms/create-alarm-from-portal`,
        data
        // { headers }
      );

      dispatch(getAlarmsList());
      if (handleApiResponse) {
        handleApiResponse({
          status: true,
          message: response?.data.message,
          alarmId: response?.data.data?.id,
          alarmSound: response?.data.data?.alarmSound,
          severity: response?.data.data?.severity,
        });
      }
    } catch (error) {
      if (handleApiResponse) {
        handleApiResponse({
          status: error?.response?.data?.status,
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something went wrong",
        });
      }
    }
  };
}

export function getDashboardDataList(handleAlarmResponse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/dashboard/dashboard`
        // { headers }
      );

      dispatch(slice.actions.getDashboardData(response.data));
      if (handleAlarmResponse) {
        handleAlarmResponse();
      }
    } catch (error) {
      if (handleAlarmResponse) {
        handleAlarmResponse();
      }
      console.log(error);
    }
  };
}

export function muteRoom(id, data, handleResponse) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/roomConfigurations/suppress-unsuppress-room?id=${id}`,
        data
        // { headers }
      );
      handleResponse(response.data);
      dispatch(getAlarmsList());
    } catch (error) {
      console.log(error);
      if (handleResponse) {
        handleResponse(error.response.data);
      }
    }
  };
}

export function createRoomAlarm(data, handleCreateRoomResponse) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/roomConfigurations`,
        data
        // { headers }
      );
      handleCreateRoomResponse(response.data);
      dispatch(getAlarmsList());
    } catch (error) {
      handleCreateRoomResponse(error.response.data.message);
      console.log(error);
    }
  };
}

export function updateRoomAlarm(data, handleUpdateRoomResponse) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/roomConfigurations?id=${data.id}`,
        data
        // { headers }
      );
      handleUpdateRoomResponse(response.data);
      dispatch(getAlarmsList());
    } catch (error) {
      if (handleUpdateRoomResponse) {
        handleUpdateRoomResponse(error.response.data.message);
      }
      console.log(error);
    }
  };
}

export function deleteRoomAlarm(id, handleDeleteRoomResponse) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/roomConfigurations?id=${id}`
        // { headers }
      );
      handleDeleteRoomResponse(response.data);
      dispatch(getAlarmsList());
    } catch (error) {
      if (handleDeleteRoomResponse) {
        handleDeleteRoomResponse(error.response.data);
      }
      console.log(error);
    }
  };
}
