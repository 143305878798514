import {
  Container,
  Typography,
  DialogActions,
  Button,
  DialogContent,
} from "@mui/material";
import React, { useContext, useState } from "react";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  deviceHeading,
  dialogCancelButton,
  dialogDeleteButton,
} from "./muiDeviceComponentStyles";
import { useDispatch } from "../../redux/store";
import useLocales from "../../hooks/useLocales";
import { deleteDevice } from "../../redux/slices/DeviceSlice";
import toasterContext from "../../utils/context/toasterContext";
import CircularProgressLoader from "../../utils/CircularProgressLoader";

const DeleteDeviceDialog = ({
  rowDeviceDetail,
  openDeleteDialog,
  handleCloseDialog,
}) => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const [loader, setLoader] = useState(false);
  const fireToasterContext = useContext(toasterContext);

  const handleDeleteDevice = () => {
    dispatch(deleteDevice(rowDeviceDetail.id, handleApiRes));
  };

  const handleApiRes = (data) => {
    setLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.messsage ? data.messsage : "Device Deleted successfully"
      );
      handleCloseDialog();
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
      handleCloseDialog();
    }
  };
  return (
    <Container>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDeleteDialog}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography sx={deviceHeading} variant="deviceTableHeadingBold">
            {translate("deleteDevice")}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent sx={{ marginTop: "5px" }}>
          <Typography sx={deviceHeading} variant="deviceTableCellName">
            {translate("areYouSure")} <b>{rowDeviceDetail?.name}</b> ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            sx={dialogCancelButton}
            autoFocus
            onClick={handleCloseDialog}
          >
            <Typography sx={deviceHeading} variant="deviceHeadingBoldButton">
              {translate("cancel")}
            </Typography>
          </Button>
          <Button
            onClick={() => handleDeleteDevice()}
            disableRipple
            sx={
              loader
                ? {
                    background: "#EC313B",
                    borderRadius: "10px",
                    "&:hover": { backgroundColor: "#979797" },
                  }
                : dialogDeleteButton
            }
          >
            <Typography sx={deviceHeading} variant="deviceHeadingBoldButton">
              {translate("deleteDevice")}
            </Typography>
            {loader ? <CircularProgressLoader /> : ""}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Container>
  );
};

export default DeleteDeviceDialog;
