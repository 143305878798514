import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Menu,
  Select,
  AppBar,
  Switch,
  Avatar,
  Toolbar,
  Tooltip,
  useTheme,
  MenuItem,
  Typography,
  IconButton,
  FormControl,
  useMediaQuery,
  styled,
} from "@mui/material";
import {
  appbar,
  topbarLogo,
  topbarHeading,
  topbarMainBox,
  topbarBackButton,
  topbarSecondaryBox,
  topbarSecondaryBoxSelect,
  topbarSecondaryBoxFormControl,
  topbarSecondaryBoxTooltipAvatar,
  topbarSecondaryBoxTooltipIconButton,
  themeIconButton,
  miniScrollBarStyles,
} from "../utils/muiComponentStyles";
import logo from "../assets/logo.png";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Logout from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarResponsive from "./SidebarResponsive";
import { useDispatch, useSelector } from "../redux/store";
import {
  getUsersProfileList,
  setUserProfileDefault,
} from "../redux/slices/profileSlice";
import BusyModeModal from "./BusyModeModal";
import toasterContext from "../utils/context/toasterContext";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
// localization
import LanguagePopover from "./LanguagePopover";
import useLocales from "../hooks/useLocales";
import ThemeContext from "../utils/themeContext";
// import useApiDispatcher from "../utils/useApiDispatcher";

const BusySwitch = styled(Switch)(() => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#03DE73",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#03DE73",
  },
}));

const Topbar = ({ socket }) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const dispatch = useDispatch();
  let loggedInUser = JSON.parse(localStorage.getItem("user"));
  const [spacing, setSpacing] = useState({});
  const themeContext = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [openBusyModeModal, setOpenBusyModeModal] = useState(false);
  const [isBusy, setIsBusy] = useState(
    JSON.parse(localStorage.getItem("user"))?.isBusy
  );
  const location = useLocation();
  // const path = location.pathname.split("/")[1].split("-").join("");
  // let path = location.pathname.split("/").pop().replace(/-/g, " ");

  const pathname = location.pathname.split("/");
  let path = pathname.pop().replace(/-/g, " ");

  // If the path is a numeric ID and the previous part of the pathname is 'edit-room', set path to 'edit room'
  if (!isNaN(path) && pathname[pathname.length - 1] === "edit-room") {
    path = "edit room";
  }

  const fireToasterContext = useContext(toasterContext);

  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState(
    localStorage.getItem("userDefaultId")
  );

  const open = Boolean(anchorEl);

  const handleDropDownChange = (event) => {
    setUserProfile(event.target.value);
    dispatch(setUserProfileDefault(event.target.value, handleResponse));
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userDefaultId");
    localStorage.removeItem("permissions");
    localStorage.removeItem("isSuperAdmin");
    localStorage.removeItem("user");
    navigate("/login");
  };

  useEffect(() => {
    dispatch(getUsersProfileList());
  }, []);

  const { profileUsers } = useSelector((state) => state.profileReducer);

  useEffect(() => {
    if (profileUsers?.length >= 1) handleProfileName();
  }, [profileUsers]);

  const handleResponse = (data, id) => {
    if (data?.status) {
      localStorage.setItem("userDefaultId", id);
      setUserProfile(id);
      window.location.reload();

      // let temp = useApiDispatcher(dispatch);
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data.response.data.message
          ? data.response.data.message
          : "Something went wrong"
      );
    }
  };

  useEffect(() => {
    socket.on("managedBusyMode", (data) => {
      if (
        data.userId * 1 == JSON.parse(localStorage.getItem("user")).id * 1 &&
        data.isBusy != JSON.parse(localStorage.getItem("user")).isBusy
      ) {
        setIsBusy(data.isBusy);
        let userDetails = JSON.parse(localStorage.getItem("user"));
        userDetails.isBusy = data.isBusy;
        localStorage.setItem("user", JSON.stringify(userDetails));
      }
    });
    socket.on("defaultProfileUpdated", (obj) => {
      localStorage.setItem("userDefaultId", obj?.userDefaultId);
      setUserProfile(obj?.userDefaultId);
      window.location.reload();
    });
    return () => {
      socket.off("managedBusyMode");
      socket.off("defaultProfileUpdated");
    };
  }, [socket]);

  const handleOpenBusyMode = () => {
    if (isBusy) {
      socket.emit("busyMode", {
        userId: JSON.parse(localStorage.getItem("user")).id,
        isBusy: false,
        busyForMinutes: null,
      });
      setIsBusy(false);
      let userDetails = JSON.parse(localStorage.getItem("user"));
      userDetails.isBusy = false;
      localStorage.setItem("user", JSON.stringify(userDetails));
      window.location.reload();
    } else {
      setOpenBusyModeModal(!openBusyModeModal);
    }
  };

  const handleChangeBusyMode = (timeInMinutes) => {
    let userDetails = JSON.parse(localStorage.getItem("user"));
    setOpenBusyModeModal(!openBusyModeModal);
    if (timeInMinutes) {
      socket.emit("busyMode", {
        userId: JSON.parse(localStorage.getItem("user")).id,
        isBusy: true,
        busyForMinutes: timeInMinutes,
      });
      setIsBusy(true);

      userDetails.isBusy = true;
      localStorage.setItem("user", JSON.stringify(userDetails));
      window.location.reload();
    } else {
      userDetails.isBusy = false;
      localStorage.setItem("user", JSON.stringify(userDetails));
    }
  };

  const handleChangeTheme = () => {
    themeContext.setTheme("sending from Demo");
  };

  const handleProfileName = () => {
    function countHierarchyLevel(data, parentId = null, level = 0) {
      const children = data.filter((obj) => obj.id === parentId);
      let count = 0;

      for (const child of children) {
        count = Math.max(count, (data, child.profileId, level + 1));
      }

      return Math.max(count, level);
    }

    // Count the hierarchy level for each object
    const hierarchyLevels = {};

    for (const item of profileUsers) {
      hierarchyLevels[item.id] = countHierarchyLevel(
        profileUsers,
        item.profileId
      );
    }

    // setting the hierarchy levels
    setSpacing(hierarchyLevels);
  };

  return (
    <AppBar elevation={0} sx={appbar} component="nav">
      <Toolbar>
        {isMatch && <SidebarResponsive />}
        <Box component="div" sx={topbarLogo} onClick={() => navigate("/")}>
          <img
            alt="Logo"
            src={logo}
            style={{
              display: "block",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
        <Box sx={topbarMainBox}>
          <Box sx={topbarHeading}>
            {path === "" ? (
              <Typography sx={topbarHeading} variant="topHeading">
                {translate("dashboard")}
              </Typography>
            ) : (
              <IconButton onClick={() => navigate(-1)} sx={topbarBackButton}>
                {<ArrowBackIcon />}
              </IconButton>
            )}
            {path?.length >= 18 ? (
              <Typography sx={topbarHeading} variant="topHeadingVerySmall">
                {translate(path)}
              </Typography>
            ) : path?.length >= 15 ? (
              <Typography sx={topbarHeading} variant="topHeadingSmall">
                {translate(path)}
              </Typography>
            ) : path === "room events" ? (
              <Typography sx={topbarHeading} variant="topHeading">
                {translate("roomEvents")}
              </Typography>
            ) : (
              <Typography sx={topbarHeading} variant="topHeading">
                {translate(path)}
              </Typography>
            )}
          </Box>
          <Box />
          <Box sx={topbarSecondaryBox}>
            {/* <Switch
              checked={themeContext.isDarkTheme}
              onClick={() => handleChangeTheme()}
            /> */}
            <IconButton
              sx={themeIconButton}
              onClick={() => handleChangeTheme()}
            >
              {themeContext.isDarkTheme == "false" ||
              themeContext.isDarkTheme == !true ? (
                <Brightness7Icon sx={{ color: "white" }} />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>
            <FormControl variant="standard" sx={topbarSecondaryBoxFormControl}>
              <Select
                SelectDisplayProps={{
                  style: {
                    // paddingTop: 8,
                    // paddingBottom: 8,
                  },
                }}
                id="simple-select-standard"
                sx={topbarSecondaryBoxSelect}
                value={userProfile}
                renderValue={(value) => (
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textTransform: "capitalize",
                    }}
                  >
                    {profileUsers.find((obj) => obj.id == value)?.name}
                  </Typography>
                )}
                onChange={handleDropDownChange}
                label="Age"
                variant="standard"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      ".MuiList-root": {
                        // bgcolor: "pink",
                        padding: 0,
                        maxHeight: 300,
                      },
                      ...miniScrollBarStyles,
                    },
                  },
                }}
              >
                {profileUsers.map((item) => {
                  const level = spacing[item.id];
                  // const indentation = level == 0 ? 10 : level * 20; // You can adjust the indentation spacing here
                  let tempStr = "";
                  for (let i = 0; i < level; i++) tempStr = "-" + tempStr;
                  return (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      sx={{
                        paddingLeft: `${15}px`,
                        borderBottom: "2px solid #F5F5F5",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          textTransform: "capitalize",
                        }}
                      >
                        {level == 0
                          ? `${item.name}`
                          : `${tempStr} ${item.name}`}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              <BusySwitch
                checked={isBusy}
                onChange={handleOpenBusyMode}
                name="isBusy"
              />
              <Typography sx={topbarHeading} variant="busyModeHeading">
                {translate("busyMode")}
              </Typography>
            </Box>

            <Box component="div">
              <LanguagePopover />
            </Box>

            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={topbarSecondaryBoxTooltipIconButton}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={topbarSecondaryBoxTooltipAvatar}>
                  {`${loggedInUser?.firstName?.slice(0, 1)}`}
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            disableScrollLock={true}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {translate("logout")}
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
      {openBusyModeModal && (
        <BusyModeModal
          openBusyModeModal={openBusyModeModal}
          handleChangeBusyMode={handleChangeBusyMode}
          setOpenBusyModeModal={setOpenBusyModeModal}
        />
      )}
    </AppBar>
  );
};

export default Topbar;
