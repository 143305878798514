import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axiosConfig";
import { dispatch } from "../store";

// const headers = {
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
//   timezoneutcoffset: new Date().getTimezoneOffset(),
// };
const initialState = {
  templateList: [],
  templateDetail: [],
};

const slice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    getTemplates(state, action) {
      state.templateList = action.payload.data;
    },
    getTemplateDetail(state, action) {
      state.templateDetail = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getTemplateList(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/templateConfigurations`
        // { headers }
      );
      dispatch(slice.actions.getTemplates(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}
export function getTemplateDetail(id, handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/templateConfigurations/details?id=${id}`
        // { headers }
      );
      dispatch(slice.actions.getTemplateDetail(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}
export function clearTemplateDetail(handleApiRes) {
  dispatch(slice.actions.getTemplateDetail([]));
  handleApiRes();
}
export function createTemplate(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/templateConfigurations`,
        data
        // { headers }
      );
      if (handleApiRes) handleApiRes(response.data);
      dispatch(getTemplateList());
    } catch (error) {
      if (handleApiRes) handleApiRes(error.response.data.message);
      console.log(error);
    }
  };
}
export function deleteTemplate(id, handleApiRes) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/templateConfigurations?id=${id}`
        // { headers }
      );
      handleApiRes(response.data);
      dispatch(getTemplateList());
    } catch (error) {
      console.log(error);
      handleApiRes(error.response.data.message);
    }
  };
}
export function updateTemplate(id, data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/templateConfigurations?id=${id}`,
        data
        // { headers }
      );
      handleApiRes(response.data);
      dispatch(getTemplateList());
    } catch (error) {
      handleApiRes(error);
      console.log(error.response.data.message);
    }
  };
}
