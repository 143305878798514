import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Select,
  Toolbar,
  MenuItem,
  TextField,
  InputBase,
  InputLabel,
  Typography,
  FormControl,
  TableContainer,
} from "@mui/material";
import {
  MenuProps,
  formControlWidth,
  logsTypographies,
  logTableContainer,
  logsSearchFieldIcon,
  textFieldLogsFilter,
  logsSearchFilterButton,
  textFieldSearchLogsFilter,
} from "./LogsStyle";
import useLocales from "../../hooks/useLocales";
import {
  getLocksList,
  getLockLogsList,
  getRoomLogsList,
  getAlarmLogsList,
  getGeneralLogsList,
  getHelpLogsList,
} from "../../redux/slices/logSlice";
import LogsTableBody from "./LogsTableBody";
import { Search } from "@mui/icons-material";
import { getRoomsList } from "../../redux/slices/RoomSlice";
import { useDispatch, useSelector } from "../../redux/store";
import CustomPagination from "../../hooks/useTablePagination";
import { getUsersList } from "../../redux/slices/UserSlice";
import { getProcedureList } from "../../redux/slices/procedureSlice";

function EnhancedTableToolbar() {
  const { translate } = useLocales();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        backgroundColor: "background.bgLayoutA",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
          fontFamily: "Poppins-semibold",
          fontSize: "20px",
          color: "white",
        }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {translate("result")}
      </Typography>
    </Toolbar>
  );
}

const Logs = () => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [logType, setLogType] = useState(0);
  const [roomId, setRoomId] = useState("All");
  const [userId, setUserId] = useState("All");
  const [lockId, setLockId] = useState("All");
  // const [helpId, setHelpId] = useState("All");
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [roomListArray, setRoomListArray] = useState([]);
  const [filteredLogArray, setFilteredLogArray] = useState([]);
  const [dateTimeObj, setDateTimeObj] = useState({});

  const { roomlogsList } = useSelector((state) => state.logReducer);
  const { generalLogsList } = useSelector((state) => state.logReducer);
  const { roomsList } = useSelector((state) => state.roomReducer);
  const { usersList } = useSelector((state) => state.userReducer);
  const { locksList } = useSelector((state) => state.logReducer);
  const { lockLogsList } = useSelector((state) => state.logReducer);
  const { helpLogsList } = useSelector((state) => state.logReducer);
  const { alarmLogsList } = useSelector((state) => state.logReducer);
  const { procedureList } = useSelector((state) => state.procedureReducer);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);

    const today = dateTimeApiformat(new Date().toJSON());
    const temp = new Date(new Date());
    temp.setDate(temp.getDate() - 60);
    const yesterday = dateTimeApiformat(temp.toJSON());

    //getting initial data for all log categories
    dispatch(
      getRoomLogsList(
        "All",
        yesterday + " " + new Date().getHours() + ":" + new Date().getMinutes(),
        today + " " + new Date().getHours() + ":" + new Date().getMinutes(),
        handleApiResponse
      )
    );
    dispatch(
      getGeneralLogsList(
        yesterday + " " + new Date().getHours() + ":" + new Date().getMinutes(),
        today + " " + new Date().getHours() + ":" + new Date().getMinutes(),
        handleApiResponse
      )
    );
    dispatch(
      getLockLogsList(
        "All",
        yesterday + " " + new Date().getHours() + ":" + new Date().getMinutes(),
        today + " " + new Date().getHours() + ":" + new Date().getMinutes(),
        handleApiResponse
      )
    );
    dispatch(
      getAlarmLogsList(
        "All",
        "All",
        yesterday + " " + new Date().getHours() + ":" + new Date().getMinutes(),
        today + " " + new Date().getHours() + ":" + new Date().getMinutes(),
        handleApiResponse
      )
    );
    dispatch(
      getHelpLogsList(
        "All",
        yesterday + " " + new Date().getHours() + ":" + new Date().getMinutes(),
        today + " " + new Date().getHours() + ":" + new Date().getMinutes(),
        handleApiResponse
      )
    );

    //setting default time for filters
    const time = new Date().getHours() + ":" + new Date().getMinutes();
    setDateTimeObj({
      startDate: yesterday,
      startTime: time,
      endDate: today,
      endTime: time,
    });

    dispatch(getRoomsList());
    dispatch(getUsersList());
    dispatch(getLocksList());
    dispatch(getProcedureList(handleApiRes, undefined));
  }, []);

  const handleApiRes = () => {};

  //Effect to set data in states when received by api
  useEffect(() => {
    // setFilteredLogArray(roomlogsList);
    setRoomListArray(roomsList);
  }, [roomlogsList, roomsList]);

  useEffect(() => {
    if (logType == 0) setFilteredLogArray(generalLogsList);
    if (logType == 1) setFilteredLogArray(roomlogsList);
    if (logType == 2) setFilteredLogArray(alarmLogsList);
    if (logType == 3) setFilteredLogArray(lockLogsList);
    if (logType == 4) setFilteredLogArray(helpLogsList);
  }, [
    logType,
    generalLogsList,
    roomlogsList,
    alarmLogsList,
    lockLogsList,
    helpLogsList,
  ]);

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  const dateTimeApiformat = (dateStr) => {
    const [yyyy, mm, dd] = dateStr.split(/[/:\-T]/);
    const newDT = `${yyyy}-${mm}-${dd}`;
    return newDT;
  };

  const handleSearch = (value) => {
    setPage(0);
    setSearch(value);
    let filterArray = [];
    const toBeSearched = value.toString().toLowerCase();
    let tempArray =
      logType == 0
        ? Array.from(generalLogsList)
        : logType == 1
        ? Array.from(roomlogsList)
        : logType == 2
        ? Array.from(alarmLogsList)
        : logType == 3
        ? Array.from(lockLogsList)
        : logType == 4
        ? Array.from(helpLogsList)
        : [];
    tempArray.map((item) => {
      const name = item?.text.toString().toLowerCase();
      if (name.includes(toBeSearched)) {
        filterArray.push(item);
      }
    });
    setFilteredLogArray(filterArray);
  };

  const handleDateTimeData = (event) => {
    let tempdateTimeObj = { ...dateTimeObj };
    tempdateTimeObj[event.target.name] = event.target.value;
    setDateTimeObj(tempdateTimeObj);
  };

  const handleFilterSearchSubmit = () => {
    setPage(0);
    setSearch("");
    setLoading(true);
    const fromDate = dateTimeObj.startDate + " " + dateTimeObj.startTime;
    const toDate = dateTimeObj.endDate + " " + dateTimeObj.endTime;
    if (logType == 0)
      dispatch(getGeneralLogsList(fromDate, toDate, handleApiResponse));
    if (logType == 1)
      dispatch(getRoomLogsList(roomId, fromDate, toDate, handleApiResponse));
    if (logType == 2)
      dispatch(
        getAlarmLogsList(userId, roomId, fromDate, toDate, handleApiResponse)
      );
    if (logType == 3)
      dispatch(getLockLogsList(lockId, fromDate, toDate, handleApiResponse));
    if (logType == 4)
      dispatch(getHelpLogsList(roomId, fromDate, toDate, handleApiResponse));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleLogTypeChange = (e) => {
    setPage(0);
    setSearch("");
    setLogType(e.target.value);
  };

  const handleApiResponse = () => {
    setLoading(false);
  };

  return (
    <>
      <Box>
        <InputBase
          value={search || ""}
          onChange={(event) => handleSearch(event.target.value)}
          sx={textFieldSearchLogsFilter}
          placeholder={translate("searchLog")}
          inputProps={{ "aria-label": "search" }}
          startAdornment={<Search sx={logsSearchFieldIcon} />}
        />
        <Grid
          container
          spacing={1}
          sx={{ display: "flex", alignItems: "center", marginTop: "0px" }}
        >
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <TextField
              sx={textFieldLogsFilter}
              id="startDate"
              label={translate("startDate")}
              variant="standard"
              name="startDate"
              type="text"
              value={dateTimeObj.startDate || ""}
              onChange={handleDateTimeData}
              onFocus={() => {
                document.getElementById("startDate").type = "date";
              }}
              onBlur={() => {
                document.getElementById("startDate").type = "text";
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <TextField
              sx={textFieldLogsFilter}
              id="startTime"
              name="startTime"
              variant="standard"
              label={translate("startTime")}
              value={dateTimeObj.startTime || ""}
              onChange={handleDateTimeData}
              onFocus={() => {
                document.getElementById("startTime").type = "time";
              }}
              onBlur={() => {
                document.getElementById("startTime").type = "text";
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <TextField
              sx={textFieldLogsFilter}
              id="endDate"
              name="endDate"
              variant="standard"
              label={translate("endDate")}
              value={dateTimeObj.endDate || ""}
              onChange={handleDateTimeData}
              onFocus={() => {
                document.getElementById("endDate").type = "date";
              }}
              onBlur={() => {
                document.getElementById("endDate").type = "text";
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <TextField
              sx={textFieldLogsFilter}
              id="endTime"
              name="endTime"
              variant="standard"
              label={translate("endTime")}
              value={dateTimeObj.endTime || ""}
              onChange={handleDateTimeData}
              onFocus={() => {
                document.getElementById("endTime").type = "time";
              }}
              onBlur={() => {
                document.getElementById("endTime").type = "text";
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <FormControl sx={formControlWidth} variant="standard">
                  <InputLabel id="log-type-select">Log Type</InputLabel>
                  <Select
                    labelId="log-type-select"
                    name="log-type"
                    value={logType || 0}
                    onChange={(e) => handleLogTypeChange(e)}
                    MenuProps={MenuProps}
                  >
                    {[
                      translate("generalLogs"),
                      translate("roomLogs"),
                      translate("alertReceivedLogs"),
                      translate("lockLogs"),
                      translate("helpLogs"),
                    ].map((option, index) => (
                      <MenuItem key={index} value={index}>
                        <Typography sx={logsTypographies} variant="List">
                          {option}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {logType == 1 && (
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <InputLabel id="mutiple-select-label">
                      {translate("room")}
                    </InputLabel>
                    <Select
                      labelId="mutiple-select-label"
                      name="roomTypeId"
                      // sx={dropdownSelect}
                      value={roomId || ""}
                      onChange={(e) => setRoomId(e.target.value)}
                      MenuProps={MenuProps}
                    >
                      <MenuItem key={0} value={"All"}>
                        <Typography sx={logsTypographies} variant="List">
                          All
                        </Typography>
                      </MenuItem>
                      {roomListArray.map((option) => (
                        <MenuItem key={option?.id} value={option?.id}>
                          <Typography sx={logsTypographies} variant="List">
                            {option?.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {logType == 2 && (
                <>
                  <Grid item xs={6} sm={6} md={4} lg={4}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <InputLabel id="mutiple-select-label">
                        {translate("alerts")}
                      </InputLabel>
                      <Select
                        labelId="mutiple-select-label"
                        name="userId"
                        // sx={dropdownSelect}
                        value={userId || ""}
                        onChange={(e) => setUserId(e.target.value)}
                        MenuProps={MenuProps}
                      >
                        <MenuItem key={0} value={"All"}>
                          <Typography sx={logsTypographies} variant="List">
                            All
                          </Typography>
                        </MenuItem>
                        {usersList.map((option) => (
                          <MenuItem key={option?.id} value={option?.id}>
                            <Typography sx={logsTypographies} variant="List">
                              {`${option?.firstName} ${option?.lastName}`}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={4}>
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <InputLabel id="mutiple-select-label">
                        {translate("room")}
                      </InputLabel>
                      <Select
                        labelId="mutiple-select-label"
                        name="roomTypeId"
                        value={roomId || ""}
                        onChange={(e) => setRoomId(e.target.value)}
                        MenuProps={MenuProps}
                      >
                        <MenuItem key={0} value={"All"}>
                          <Typography sx={logsTypographies} variant="List">
                            All
                          </Typography>
                        </MenuItem>
                        {roomListArray.map((option) => (
                          <MenuItem key={option?.id} value={option?.id}>
                            <Typography sx={logsTypographies} variant="List">
                              {option?.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
              {logType == 3 && (
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <InputLabel id="mutiple-select-label">
                      {translate("lock")}
                    </InputLabel>
                    <Select
                      labelId="mutiple-select-label"
                      name="lockId"
                      // sx={dropdownSelect}
                      value={lockId || ""}
                      onChange={(e) => setLockId(e.target.value)}
                      MenuProps={MenuProps}
                    >
                      <MenuItem key={0} value={"All"}>
                        <Typography sx={logsTypographies} variant="List">
                          All
                        </Typography>
                      </MenuItem>
                      {locksList.map((option) => (
                        <MenuItem key={option?.id} value={option?.id}>
                          <Typography sx={logsTypographies} variant="List">
                            {option?.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {logType == 4 && (
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <InputLabel id="mutiple-select-label">
                      {translate("room")}
                    </InputLabel>
                    <Select
                      labelId="mutiple-select-label"
                      name="roomTypeId"
                      // sx={dropdownSelect}
                      value={roomId || ""}
                      onChange={(e) => setRoomId(e.target.value)}
                      MenuProps={MenuProps}
                    >
                      <MenuItem key={0} value={"All"}>
                        <Typography sx={logsTypographies} variant="List">
                          All
                        </Typography>
                      </MenuItem>
                      {roomListArray.map((option) => (
                        <MenuItem key={option?.id} value={option?.id}>
                          <Typography sx={logsTypographies} variant="List">
                            {option?.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            sm={12}
            md={12}
            lg={3}
          >
            <Button
              type="submit"
              variant="contained"
              sx={logsSearchFilterButton}
              onClick={handleFilterSearchSubmit}
            >
              {translate("search")}
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: "20px", width: "100%" }}>
          <EnhancedTableToolbar />
          <TableContainer sx={logTableContainer}>
            <LogsTableBody
              logType={logType}
              filteredLogArray={filteredLogArray}
              page={page}
              rowsPerPage={rowsPerPage}
              roomsList={roomsList}
              loading={loading}
              procedureList={procedureList}
            />
          </TableContainer>
          <CustomPagination
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            filteredList={filteredLogArray}
            setRowsPerPage={setRowsPerPage}
          />
        </Box>
      </Box>
    </>
  );
};

export default Logs;
