import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axiosConfig";
import { dispatch } from "../store";

// const headers = {
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
//   timezoneutcoffset: new Date().getTimezoneOffset(),
// };
const initialState = {
  categoryList: [],
};

const slice = createSlice({
  name: "categoryList",
  initialState,
  reducers: {
    getCategories(state, action) {
      state.categoryList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getCategoryList(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/categories`
        // { headers }
      );
      dispatch(slice.actions.getCategories(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}
export function createCategory(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/categories`,
        data
        // { headers }
      );
      console.log(response.data);
      if (handleApiRes) handleApiRes(response.data);
      dispatch(getCategoryList());
    } catch (error) {
      if (handleApiRes) handleApiRes(error.response.data.message);
      console.log(error);
    }
  };
}
export function updateCategory(id, data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/categories?id=${id}`,
        data
        // { headers }
      );
      handleApiRes(response.data);
      // handleResponse(response.data, id);
      // dispatch(slice.actions.getUserProfile(response.data));
      dispatch(getCategoryList());
    } catch (error) {
      console.log(error);
      handleApiRes(error.response.data.message);
    }
  };
}
