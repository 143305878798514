import {
  Box,
  Table,
  Avatar,
  Collapse,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
import React, { useState } from "react";
import {
  tableAvatar,
  reportHeading,
  tableLoaderBox,
  tableAvatarCell,
  logsTableBodyCell,
  logsTableHeaderCell,
} from "./muiReportComponentStyles";
import Loader from "../Loader/Loader";
import useLocales from "../../hooks/useLocales";
import { KeyboardArrowUp } from "@mui/icons-material";
import AccessibleIcon from "@mui/icons-material/Accessible";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { tableContainer } from "./muiReportComponentStyles";
import dayjs from "dayjs";

const ReportsRows = ({
  rowData,
  rowHeader,
  setAlarmDetail,
  setRowId,
  loading,
  rowId,
  procedureId,
  setSelectedDateForWeek,
}) => {
  const { translate } = useLocales();
  const [openCollapsable, setOpenCollapsable] = useState(true);

  const handleRowClick = (id) => {
    if (rowId == id) setRowId(null);
    else setRowId(rowData?.severity);

    setOpenCollapsable((prev) => !prev);
  };

  const handleCellClick = (alarms, idx) => {
    //temporary disable condition here//

    let data = { alarms, idx };
    if (alarms?.length >= 1) {
      setAlarmDetail({ alarms, idx });
      const alarmDate = data.alarms[0].createdAt;
      const parsedDate = alarmDate; // Parse with UTC to avoid time zone issues

      // Set the state with the correct date
      setSelectedDateForWeek(parsedDate);
    }
  };

  const getRowColor = () => {
    let color =
      rowData?.severity == 3
        ? "#fba452"
        : rowData?.severity == 2
        ? "#fa7d09"
        : rowData?.severity == 1
        ? "#EC313B"
        : "#EC313B";
    return color;
  };
  const getRowName = () => {
    let color =
      rowData?.severity == 3
        ? translate("low")
        : rowData?.severity == 2
        ? translate("medium")
        : rowData?.severity == 1
        ? translate("high")
        : "Unknow";
    return color;
  };
  const getRowIcon = () => {
    let color =
      rowData?.severity == 3 ? (
        <AccessibleIcon />
      ) : rowData?.severity == 2 ? (
        <WarningAmberIcon />
      ) : rowData?.severity == 1 ? (
        <AccessAlarmIcon />
      ) : (
        <AccessibleIcon />
      );
    return color;
  };

  const reformatedDate = (dateString) => {
    let date = new Date(dateString);
    let formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return dayjs(formattedDate).format("DD-MM-YYYY");
  };
  return (
    <>
      <Box
        sx={{
          background: getRowColor(),
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
        }}
        onClick={() => handleRowClick(rowData?.severity)}
      >
        <Box sx={tableAvatarCell}>
          <Avatar
            sx={{ ...tableAvatar, bgcolor: "white", color: getRowColor() }}
          >
            {getRowIcon()}
          </Avatar>
          <Typography sx={reportHeading} variant="reportTableCellName">
            {getRowName()}
          </Typography>
        </Box>
        <Box
          align="right"
          sx={{
            color: "white",
            display: "flex",
            alignItems: "center",
          }}
        >
          {openCollapsable ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
        </Box>
      </Box>
      <Box sx={{ background: "#FFFFFF" }}>
        <Collapse
          // in={rowId == rowData?.severity}
          in={openCollapsable}
          timeout="auto"
          unmountOnExit
        >
          <TableContainer sx={{ ...tableContainer, mt: 0 }}>
            <Table size="medium">
              <TableHead>
                {loading ? (
                  <TableRow
                  // hover
                  >
                    <TableCell align="center" colSpan={rowHeader?.length + 1}>
                      <Box style={tableLoaderBox}>
                        <Loader />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : !rowData?.procedures?.length >= 1 ? (
                  <TableRow
                    sx={{
                      backgroundColor: "background.bgTableOddRow",
                    }}
                  >
                    <TableCell colSpan={rowHeader?.length + 1} align="center">
                      <Typography sx={reportHeading} variant="reportTableCell">
                        No Logs Available
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow
                    sx={{
                      backgroundColor: "background.bgTableOddRow",
                    }}
                  >
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        backgroundColor: "inherit",
                      }}
                    ></TableCell>
                    {rowHeader?.map((obj, idx) => (
                      <TableCell
                        align={rowHeader?.length > 8 ? "left" : "center"}
                        key={idx}
                        sx={{ ...logsTableHeaderCell, px: 0 }}
                      >
                        {rowHeader?.length > 8 ? (
                          <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                            &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {obj?.key}
                          </div>
                        ) : (
                          <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                            {reformatedDate(obj?.key)}
                          </div>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {!loading
                  ? rowData?.procedures
                      .filter((data) =>
                        procedureId ? data.id === procedureId : data
                      )
                      .map((item, idx) => (
                        <TableRow
                          key={idx}
                          sx={{
                            backgroundColor: "background.bgTableEvenRow",
                          }}
                        >
                          <TableCell
                            sx={{
                              ...tableAvatarCell,
                              position: "sticky",
                              left: 0,
                              zIndex: 1,
                              backgroundColor: "inherit", // Ensures the sticky column matches the row color
                            }}
                          >
                            <Box
                              sx={{
                                height: "39px",
                                width: "39px",
                                minWidth: "39px",
                                display: "flex",
                                borderRadius: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: getRowColor(),
                              }}
                            >
                              <Box
                                sx={{
                                  height: "24px",
                                  width: "24px",
                                  borderRadius: "25px",
                                }}
                              >
                                <img
                                  src={item?.iconUrl}
                                  crossOrigin="anonymous"
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "contain",
                                  }}
                                />
                              </Box>
                            </Box>
                            <Typography
                              sx={reportHeading}
                              variant="reportTableCell"
                              noWrap
                            >
                              {item?.roomName}
                            </Typography>
                          </TableCell>
                          {item?.alarms?.map((obj, index1) => (
                            <TableCell
                              align={rowHeader?.length > 8 ? "right" : "center"}
                              key={index1}
                              sx={logsTableBodyCell}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleCellClick(obj?.alarms, index1)
                              }
                            >
                              {obj?.alarms?.length >= 1 ? (
                                <span style={{ color: getRowColor() }}>
                                  {obj?.alarms?.length}
                                </span>
                              ) : (
                                obj?.alarms?.length
                              )}
                            </TableCell>
                          ))}
                          <TableCell></TableCell>
                        </TableRow>
                      ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </Box>
    </>
  );
};

export default ReportsRows;
