import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axiosConfig";
import { dispatch } from "../store";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  timezoneutcoffset: new Date().getTimezoneOffset(),
};
const initialState = {
  inputList: [],
  roomCreationInputList: [],
};

const slice = createSlice({
  name: "inputs",
  initialState,
  reducers: {
    getInputs(state, action) {
      state.inputList = action.payload.data;
    },
    getRoomCreationInputs(state, action) {
      state.roomCreationInputList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getInputList(handleApiRes) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}v1/inputConfigurations`
        // { headers }
      );
      dispatch(slice.actions.getInputs(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      // console.log(error);
    }
  };
}

export function getRoomCreationInputList(roomId = null, handleApiRes) {
  return async () => {
    try {
      let response;
      if (roomId != null) {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/inputConfigurations/input-configuration-for-room-creation-updation/${roomId}`
          // { headers }
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_HOST_API_KEY}v1/inputConfigurations/input-configuration-for-room-creation-updation/${roomId}`,
          { headers }
        );
      }
      dispatch(slice.actions.getRoomCreationInputs(response.data));
      if (handleApiRes) handleApiRes();
    } catch (error) {
      if (handleApiRes) handleApiRes();
      console.log(error);
    }
  };
}

export function createInput(data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_API_KEY}v1/inputConfigurations`,
        data
        // { headers }
      );
      if (handleApiRes) handleApiRes(response.data);
      dispatch(getInputList());
    } catch (error) {
      if (handleApiRes) handleApiRes(error.response.data.message);
      console.log(error);
    }
  };
}

export function deleteInput(id, handleApiRes) {
  return async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API_KEY}v1/inputConfigurations?id=${id}`
        // { headers }
      );
      handleApiRes(response.data);
      // handleResponse(response.data, id);
      // dispatch(slice.actions.getUserProfile(response.data));
      dispatch(getInputList());
    } catch (error) {
      console.log(error);
      handleApiRes(error.response.data.message);
    }
  };
}

export function updateInput(id, data, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/inputConfigurations?id=${id}`,
        data
        // { headers }
      );
      handleApiRes(response.data);
      dispatch(getInputList());
    } catch (error) {
      handleApiRes(error);
      console.log(error.response.data.message);
    }
  };
}

export function updateInputStatus(id, status, handleApiRes) {
  return async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_HOST_API_KEY}v1/inputConfigurations/update-status?id=${id}&active=${status}`,
        {}
        // { headers }
      );
      handleApiRes(response.data);
      dispatch(getInputList());
    } catch (error) {
      handleApiRes(error);
      console.log(error.response.data.message);
    }
  };
}
